import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ArticleBlocksDynamicZone: [
      "ComponentSharedMedia",
      "ComponentSharedQuote",
      "ComponentSharedRichText",
      "ComponentSharedSlider",
      "ComponentSharedTileGrid",
      "Error",
    ],
    Event: ["ERC721Transfer", "ERC1155Transfer"],
    GenericMorph: [
      "Article",
      "Author",
      "Category",
      "Chain",
      "ComponentSharedArticleThumbnail",
      "ComponentSharedFaqItem",
      "ComponentSharedLinkButtonWithDescription",
      "ComponentSharedMedia",
      "ComponentSharedMenuItem",
      "ComponentSharedMenuItemPrimitive",
      "ComponentSharedMetaSocial",
      "ComponentSharedQuote",
      "ComponentSharedRelatedArticles",
      "ComponentSharedRewardGroups",
      "ComponentSharedRichText",
      "ComponentSharedSeo",
      "ComponentSharedSlider",
      "ComponentSharedSocialNetwork",
      "ComponentSharedTileGrid",
      "EcosystemPartner",
      "EcosystemPartnerCategory",
      "Game",
      "GameCategory",
      "GameDevice",
      "GameGenre",
      "GameStatus",
      "Global",
      "I18NLocale",
      "Menu",
      "Task",
      "TradeCompetition",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
    ],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ArticleBlocksDynamicZoneInput: any;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: "Account";
  ERC721operatorOperator: Array<Erc721Operator>;
  ERC721operatorOwner: Array<Erc721Operator>;
  ERC721tokens: Array<Erc721Token>;
  ERC721transferFromEvent: Array<Erc721Transfer>;
  ERC721transferToEvent: Array<Erc721Transfer>;
  ERC1155balances: Array<Erc1155Balance>;
  ERC1155operatorOperator: Array<Erc1155Operator>;
  ERC1155operatorOwner: Array<Erc1155Operator>;
  ERC1155transferFromEvent: Array<Erc1155Transfer>;
  ERC1155transferOperatorEvent: Array<Erc1155Transfer>;
  ERC1155transferToEvent: Array<Erc1155Transfer>;
  asERC721?: Maybe<Erc721Contract>;
  asERC1155?: Maybe<Erc1155Contract>;
  events: Array<Event>;
  id: Scalars["ID"];
};

export type AccountErc721operatorOperatorArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type AccountErc721operatorOwnerArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type AccountErc721tokensArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type AccountErc721transferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type AccountErc721transferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type AccountErc1155balancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Balance_Filter>;
};

export type AccountErc1155operatorOperatorArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Operator_Filter>;
};

export type AccountErc1155operatorOwnerArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Operator_Filter>;
};

export type AccountErc1155transferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type AccountErc1155transferOperatorEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type AccountErc1155transferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type AccountEventsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type Account_Filter = {
  ERC721operatorOperator_?: InputMaybe<Erc721Operator_Filter>;
  ERC721operatorOwner_?: InputMaybe<Erc721Operator_Filter>;
  ERC721tokens_?: InputMaybe<Erc721Token_Filter>;
  ERC721transferFromEvent_?: InputMaybe<Erc721Transfer_Filter>;
  ERC721transferToEvent_?: InputMaybe<Erc721Transfer_Filter>;
  ERC1155balances_?: InputMaybe<Erc1155Balance_Filter>;
  ERC1155operatorOperator_?: InputMaybe<Erc1155Operator_Filter>;
  ERC1155operatorOwner_?: InputMaybe<Erc1155Operator_Filter>;
  ERC1155transferFromEvent_?: InputMaybe<Erc1155Transfer_Filter>;
  ERC1155transferOperatorEvent_?: InputMaybe<Erc1155Transfer_Filter>;
  ERC1155transferToEvent_?: InputMaybe<Erc1155Transfer_Filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
  asERC721?: InputMaybe<Scalars["String"]>;
  asERC721_?: InputMaybe<Erc721Contract_Filter>;
  asERC721_contains?: InputMaybe<Scalars["String"]>;
  asERC721_contains_nocase?: InputMaybe<Scalars["String"]>;
  asERC721_ends_with?: InputMaybe<Scalars["String"]>;
  asERC721_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC721_gt?: InputMaybe<Scalars["String"]>;
  asERC721_gte?: InputMaybe<Scalars["String"]>;
  asERC721_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC721_lt?: InputMaybe<Scalars["String"]>;
  asERC721_lte?: InputMaybe<Scalars["String"]>;
  asERC721_not?: InputMaybe<Scalars["String"]>;
  asERC721_not_contains?: InputMaybe<Scalars["String"]>;
  asERC721_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  asERC721_not_ends_with?: InputMaybe<Scalars["String"]>;
  asERC721_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC721_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC721_not_starts_with?: InputMaybe<Scalars["String"]>;
  asERC721_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC721_starts_with?: InputMaybe<Scalars["String"]>;
  asERC721_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155?: InputMaybe<Scalars["String"]>;
  asERC1155_?: InputMaybe<Erc1155Contract_Filter>;
  asERC1155_contains?: InputMaybe<Scalars["String"]>;
  asERC1155_contains_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155_ends_with?: InputMaybe<Scalars["String"]>;
  asERC1155_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155_gt?: InputMaybe<Scalars["String"]>;
  asERC1155_gte?: InputMaybe<Scalars["String"]>;
  asERC1155_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC1155_lt?: InputMaybe<Scalars["String"]>;
  asERC1155_lte?: InputMaybe<Scalars["String"]>;
  asERC1155_not?: InputMaybe<Scalars["String"]>;
  asERC1155_not_contains?: InputMaybe<Scalars["String"]>;
  asERC1155_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155_not_ends_with?: InputMaybe<Scalars["String"]>;
  asERC1155_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC1155_not_starts_with?: InputMaybe<Scalars["String"]>;
  asERC1155_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asERC1155_starts_with?: InputMaybe<Scalars["String"]>;
  asERC1155_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  events_?: InputMaybe<Event_Filter>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Account_Filter>>>;
};

export type AdminOperationResultDto = {
  __typename?: "AdminOperationResultDto";
  message: Scalars["String"];
  operation: Scalars["String"];
  result: Scalars["Boolean"];
};

export type Article = {
  __typename?: "Article";
  author?: Maybe<AuthorEntityResponse>;
  blocks?: Maybe<Array<Maybe<ArticleBlocksDynamicZone>>>;
  categories?: Maybe<CategoryRelationResponseCollection>;
  cover?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  heroTiles?: Maybe<Array<Maybe<ComponentSharedTileGrid>>>;
  learnMore?: Maybe<ComponentSharedLinkButtonWithDescription>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  relatedArticles?: Maybe<ComponentSharedRelatedArticles>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars["String"]>;
  startButton?: Maybe<ComponentSharedLinkButtonWithDescription>;
  thumbnail?: Maybe<ComponentSharedArticleThumbnail>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  videoCover?: Maybe<UploadFileEntityResponse>;
};

export type ArticleCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ArticleHeroTilesArgs = {
  filters?: InputMaybe<ComponentSharedTileGridFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ArticleBlocksDynamicZone =
  | ComponentSharedMedia
  | ComponentSharedQuote
  | ComponentSharedRichText
  | ComponentSharedSlider
  | ComponentSharedTileGrid
  | Error;

export type ArticleEntity = {
  __typename?: "ArticleEntity";
  attributes?: Maybe<Article>;
  id?: Maybe<Scalars["ID"]>;
};

export type ArticleEntityResponse = {
  __typename?: "ArticleEntityResponse";
  data?: Maybe<ArticleEntity>;
};

export type ArticleEntityResponseCollection = {
  __typename?: "ArticleEntityResponseCollection";
  data: Array<ArticleEntity>;
  meta: ResponseCollectionMeta;
};

export type ArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  author?: InputMaybe<AuthorFiltersInput>;
  categories?: InputMaybe<CategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  heroTiles?: InputMaybe<ComponentSharedTileGridFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  learnMore?: InputMaybe<ComponentSharedLinkButtonWithDescriptionFiltersInput>;
  not?: InputMaybe<ArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  relatedArticles?: InputMaybe<ComponentSharedRelatedArticlesFiltersInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  startButton?: InputMaybe<ComponentSharedLinkButtonWithDescriptionFiltersInput>;
  thumbnail?: InputMaybe<ComponentSharedArticleThumbnailFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ArticleInput = {
  author?: InputMaybe<Scalars["ID"]>;
  blocks?: InputMaybe<Array<Scalars["ArticleBlocksDynamicZoneInput"]>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  cover?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  heroTiles?: InputMaybe<Array<InputMaybe<ComponentSharedTileGridInput>>>;
  learnMore?: InputMaybe<ComponentSharedLinkButtonWithDescriptionInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  relatedArticles?: InputMaybe<ComponentSharedRelatedArticlesInput>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  startButton?: InputMaybe<ComponentSharedLinkButtonWithDescriptionInput>;
  thumbnail?: InputMaybe<ComponentSharedArticleThumbnailInput>;
  title?: InputMaybe<Scalars["String"]>;
  videoCover?: InputMaybe<Scalars["ID"]>;
};

export type ArticleRelationResponseCollection = {
  __typename?: "ArticleRelationResponseCollection";
  data: Array<ArticleEntity>;
};

export type AssetPrice = {
  __typename?: "AssetPrice";
  chainId: Scalars["Int"];
  marketplace: Scalars["String"];
  paymentToken: Scalars["String"];
  value: Scalars["String"];
};

export type Author = {
  __typename?: "Author";
  articles?: Maybe<ArticleRelationResponseCollection>;
  avatar?: Maybe<UploadFileEntityResponse>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AuthorArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type AuthorEntity = {
  __typename?: "AuthorEntity";
  attributes?: Maybe<Author>;
  id?: Maybe<Scalars["ID"]>;
};

export type AuthorEntityResponse = {
  __typename?: "AuthorEntityResponse";
  data?: Maybe<AuthorEntity>;
};

export type AuthorEntityResponseCollection = {
  __typename?: "AuthorEntityResponseCollection";
  data: Array<AuthorEntity>;
  meta: ResponseCollectionMeta;
};

export type AuthorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AuthorFiltersInput>>>;
  articles?: InputMaybe<ArticleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<AuthorFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AuthorFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AuthorInput = {
  articles?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  avatar?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type BlockChangedFilter = {
  number_gte: Scalars["Int"];
};

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]>;
  number?: InputMaybe<Scalars["Int"]>;
  number_gte?: InputMaybe<Scalars["Int"]>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  contains?: InputMaybe<Scalars["Boolean"]>;
  containsi?: InputMaybe<Scalars["Boolean"]>;
  endsWith?: InputMaybe<Scalars["Boolean"]>;
  eq?: InputMaybe<Scalars["Boolean"]>;
  eqi?: InputMaybe<Scalars["Boolean"]>;
  gt?: InputMaybe<Scalars["Boolean"]>;
  gte?: InputMaybe<Scalars["Boolean"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  lt?: InputMaybe<Scalars["Boolean"]>;
  lte?: InputMaybe<Scalars["Boolean"]>;
  ne?: InputMaybe<Scalars["Boolean"]>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars["Boolean"]>;
  notContainsi?: InputMaybe<Scalars["Boolean"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  startsWith?: InputMaybe<Scalars["Boolean"]>;
};

export type Category = {
  __typename?: "Category";
  articles?: Maybe<ArticleRelationResponseCollection>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CategoryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CategoryEntity = {
  __typename?: "CategoryEntity";
  attributes?: Maybe<Category>;
  id?: Maybe<Scalars["ID"]>;
};

export type CategoryEntityResponse = {
  __typename?: "CategoryEntityResponse";
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: "CategoryEntityResponseCollection";
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  articles?: InputMaybe<ArticleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  articles?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type CategoryRelationResponseCollection = {
  __typename?: "CategoryRelationResponseCollection";
  data: Array<CategoryEntity>;
};

export type Chain = {
  __typename?: "Chain";
  chainId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<GameRelationResponseCollection>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ChainGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ChainEntity = {
  __typename?: "ChainEntity";
  attributes?: Maybe<Chain>;
  id?: Maybe<Scalars["ID"]>;
};

export type ChainEntityResponse = {
  __typename?: "ChainEntityResponse";
  data?: Maybe<ChainEntity>;
};

export type ChainEntityResponseCollection = {
  __typename?: "ChainEntityResponseCollection";
  data: Array<ChainEntity>;
  meta: ResponseCollectionMeta;
};

export type ChainFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ChainFiltersInput>>>;
  chainId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  games?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ChainFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ChainFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export enum ChainId {
  Avalanche = "AVALANCHE",
  Bsc = "BSC",
  Ethereum = "ETHEREUM",
  Fantom = "FANTOM",
  Kovan = "KOVAN",
  Matic = "MATIC",
  Mumbai = "MUMBAI",
  PromTestnet = "PROM_TESTNET",
}

export type ChainInput = {
  chainId?: InputMaybe<Scalars["Int"]>;
  games?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type ChainRelationResponseCollection = {
  __typename?: "ChainRelationResponseCollection";
  data: Array<ChainEntity>;
};

export type CoinRateDto = {
  __typename?: "CoinRateDto";
  contract?: Maybe<Scalars["String"]>;
  paymentTokenName: Scalars["String"];
  platformId?: Maybe<Scalars["String"]>;
  updateTime: Scalars["DateTime"];
  usdRate: Scalars["String"];
};

export type CompetitionResultDto = {
  __typename?: "CompetitionResultDto";
  competitionId?: Maybe<Scalars["String"]>;
  endTimestamp: Scalars["DateTime"];
  provider: NftProvider;
  requestedUser?: Maybe<CompetitionUserResultDto>;
  startTimestamp: Scalars["DateTime"];
  topUsers: Array<CompetitionUserResultDto>;
};

export type CompetitionUserResultDto = {
  __typename?: "CompetitionUserResultDto";
  place: Scalars["Float"];
  userAddress: Scalars["String"];
  volumeUsd?: Maybe<Scalars["Float"]>;
};

export type ComponentSharedArticleThumbnail = {
  __typename?: "ComponentSharedArticleThumbnail";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  media?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedArticleThumbnailFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedArticleThumbnailFiltersInput>>
  >;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedArticleThumbnailFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedArticleThumbnailFiltersInput>>
  >;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedArticleThumbnailInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedFaqItem = {
  __typename?: "ComponentSharedFaqItem";
  answer?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedFaqItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedFaqItemFiltersInput>>>;
  answer?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedFaqItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedFaqItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedFaqItemInput = {
  answer?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedLinkButtonWithDescription = {
  __typename?: "ComponentSharedLinkButtonWithDescription";
  buttonTitle?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  href?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ComponentSharedLinkButtonWithDescriptionFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedLinkButtonWithDescriptionFiltersInput>>
  >;
  buttonTitle?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  href?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedLinkButtonWithDescriptionFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedLinkButtonWithDescriptionFiltersInput>>
  >;
};

export type ComponentSharedLinkButtonWithDescriptionInput = {
  buttonTitle?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  href?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type ComponentSharedMedia = {
  __typename?: "ComponentSharedMedia";
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars["ID"];
};

export type ComponentSharedMenuItem = {
  __typename?: "ComponentSharedMenuItem";
  article?: Maybe<ArticleEntityResponse>;
  id: Scalars["ID"];
  items?: Maybe<Array<Maybe<ComponentSharedMenuItemPrimitive>>>;
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedMenuItemItemsArgs = {
  filters?: InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentSharedMenuItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMenuItemFiltersInput>>>;
  article?: InputMaybe<ArticleFiltersInput>;
  items?: InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>;
  not?: InputMaybe<ComponentSharedMenuItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMenuItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMenuItemInput = {
  article?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  items?: InputMaybe<Array<InputMaybe<ComponentSharedMenuItemPrimitiveInput>>>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedMenuItemPrimitive = {
  __typename?: "ComponentSharedMenuItemPrimitive";
  article?: Maybe<ArticleEntityResponse>;
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedMenuItemPrimitiveFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>>
  >;
  article?: InputMaybe<ArticleFiltersInput>;
  not?: InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>>
  >;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMenuItemPrimitiveInput = {
  article?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedMetaSocial = {
  __typename?: "ComponentSharedMetaSocial";
  description: Scalars["String"];
  id: Scalars["ID"];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
  title: Scalars["String"];
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<Scalars["ID"]>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedQuote = {
  __typename?: "ComponentSharedQuote";
  body?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedRelatedArticles = {
  __typename?: "ComponentSharedRelatedArticles";
  articles?: Maybe<Array<Maybe<ComponentSharedMenuItemPrimitive>>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ComponentSharedRelatedArticlesArticlesArgs = {
  filters?: InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentSharedRelatedArticlesFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentSharedRelatedArticlesFiltersInput>>
  >;
  articles?: InputMaybe<ComponentSharedMenuItemPrimitiveFiltersInput>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedRelatedArticlesFiltersInput>;
  or?: InputMaybe<
    Array<InputMaybe<ComponentSharedRelatedArticlesFiltersInput>>
  >;
  title?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedRelatedArticlesInput = {
  articles?: InputMaybe<
    Array<InputMaybe<ComponentSharedMenuItemPrimitiveInput>>
  >;
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedRewardGroups = {
  __typename?: "ComponentSharedRewardGroups";
  id: Scalars["ID"];
  maxPlace?: Maybe<Scalars["Int"]>;
  minPlace?: Maybe<Scalars["Int"]>;
  rewardToken?: Maybe<Scalars["String"]>;
  rewardValue?: Maybe<Scalars["String"]>;
};

export type ComponentSharedRewardGroupsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedRewardGroupsFiltersInput>>>;
  maxPlace?: InputMaybe<IntFilterInput>;
  minPlace?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentSharedRewardGroupsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedRewardGroupsFiltersInput>>>;
  rewardToken?: InputMaybe<StringFilterInput>;
  rewardValue?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedRewardGroupsInput = {
  id?: InputMaybe<Scalars["ID"]>;
  maxPlace?: InputMaybe<Scalars["Int"]>;
  minPlace?: InputMaybe<Scalars["Int"]>;
  rewardToken?: InputMaybe<Scalars["String"]>;
  rewardValue?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedRichText = {
  __typename?: "ComponentSharedRichText";
  body?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ComponentSharedSeo = {
  __typename?: "ComponentSharedSeo";
  canonicalURL?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  keywords?: Maybe<Scalars["String"]>;
  metaDescription: Scalars["String"];
  metaImage: UploadFileEntityResponse;
  metaRobots?: Maybe<Scalars["String"]>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle: Scalars["String"];
  metaViewport?: Maybe<Scalars["String"]>;
  structuredData?: Maybe<Scalars["JSON"]>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  metaViewport?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: InputMaybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  keywords?: InputMaybe<Scalars["String"]>;
  metaDescription?: InputMaybe<Scalars["String"]>;
  metaImage?: InputMaybe<Scalars["ID"]>;
  metaRobots?: InputMaybe<Scalars["String"]>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars["String"]>;
  metaViewport?: InputMaybe<Scalars["String"]>;
  structuredData?: InputMaybe<Scalars["JSON"]>;
};

export type ComponentSharedSlider = {
  __typename?: "ComponentSharedSlider";
  files?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars["ID"];
};

export type ComponentSharedSliderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ComponentSharedSocialNetwork = {
  __typename?: "ComponentSharedSocialNetwork";
  id: Scalars["ID"];
  type?: Maybe<Enum_Componentsharedsocialnetwork_Type>;
  url: Scalars["String"];
};

export type ComponentSharedSocialNetworkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSocialNetworkFiltersInput>>>;
  not?: InputMaybe<ComponentSharedSocialNetworkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSocialNetworkFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedSocialNetworkInput = {
  id?: InputMaybe<Scalars["ID"]>;
  type?: InputMaybe<Enum_Componentsharedsocialnetwork_Type>;
  url?: InputMaybe<Scalars["String"]>;
};

export type ComponentSharedTileGrid = {
  __typename?: "ComponentSharedTileGrid";
  description?: Maybe<Scalars["String"]>;
  direction?: Maybe<Enum_Componentsharedtilegrid_Direction>;
  id: Scalars["ID"];
  media?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars["String"]>;
};

export type ComponentSharedTileGridFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedTileGridFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  direction?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedTileGridFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedTileGridFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedTileGridInput = {
  description?: InputMaybe<Scalars["String"]>;
  direction?: InputMaybe<Enum_Componentsharedtilegrid_Direction>;
  id?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export enum DataType {
  Array = "ARRAY",
  Boolean = "BOOLEAN",
  Float = "FLOAT",
  Integer = "INTEGER",
  String = "STRING",
}

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  contains?: InputMaybe<Scalars["Date"]>;
  containsi?: InputMaybe<Scalars["Date"]>;
  endsWith?: InputMaybe<Scalars["Date"]>;
  eq?: InputMaybe<Scalars["Date"]>;
  eqi?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  ne?: InputMaybe<Scalars["Date"]>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars["Date"]>;
  notContainsi?: InputMaybe<Scalars["Date"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  startsWith?: InputMaybe<Scalars["Date"]>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  contains?: InputMaybe<Scalars["DateTime"]>;
  containsi?: InputMaybe<Scalars["DateTime"]>;
  endsWith?: InputMaybe<Scalars["DateTime"]>;
  eq?: InputMaybe<Scalars["DateTime"]>;
  eqi?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  ne?: InputMaybe<Scalars["DateTime"]>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars["DateTime"]>;
  notContainsi?: InputMaybe<Scalars["DateTime"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  startsWith?: InputMaybe<Scalars["DateTime"]>;
};

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = "Facebook",
  Twitter = "Twitter",
}

export enum Enum_Componentsharedsocialnetwork_Type {
  Announcements = "announcements",
  Discord = "discord",
  Telegram = "telegram",
  Twitter = "twitter",
  Website = "website",
}

export enum Enum_Componentsharedtilegrid_Direction {
  Default = "default",
  Reverse = "reverse",
}

export type Erc721Contract = {
  __typename?: "ERC721Contract";
  asAccount: Account;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  operators: Array<Erc721Operator>;
  supportsMetadata?: Maybe<Scalars["Boolean"]>;
  symbol?: Maybe<Scalars["String"]>;
  tokens: Array<Erc721Token>;
  transfers: Array<Erc721Transfer>;
};

export type Erc721ContractOperatorsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type Erc721ContractTokensArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type Erc721ContractTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Contract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc721Contract_Filter>>>;
  asAccount?: InputMaybe<Scalars["String"]>;
  asAccount_?: InputMaybe<Account_Filter>;
  asAccount_contains?: InputMaybe<Scalars["String"]>;
  asAccount_contains_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_gt?: InputMaybe<Scalars["String"]>;
  asAccount_gte?: InputMaybe<Scalars["String"]>;
  asAccount_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_lt?: InputMaybe<Scalars["String"]>;
  asAccount_lte?: InputMaybe<Scalars["String"]>;
  asAccount_not?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_not_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_contains_nocase?: InputMaybe<Scalars["String"]>;
  name_ends_with?: InputMaybe<Scalars["String"]>;
  name_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  name_gt?: InputMaybe<Scalars["String"]>;
  name_gte?: InputMaybe<Scalars["String"]>;
  name_in?: InputMaybe<Array<Scalars["String"]>>;
  name_lt?: InputMaybe<Scalars["String"]>;
  name_lte?: InputMaybe<Scalars["String"]>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  name_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<Scalars["String"]>>;
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  name_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  name_starts_with?: InputMaybe<Scalars["String"]>;
  name_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  operators_?: InputMaybe<Erc721Operator_Filter>;
  or?: InputMaybe<Array<InputMaybe<Erc721Contract_Filter>>>;
  supportsMetadata?: InputMaybe<Scalars["Boolean"]>;
  supportsMetadata_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  supportsMetadata_not?: InputMaybe<Scalars["Boolean"]>;
  supportsMetadata_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  symbol?: InputMaybe<Scalars["String"]>;
  symbol_contains?: InputMaybe<Scalars["String"]>;
  symbol_contains_nocase?: InputMaybe<Scalars["String"]>;
  symbol_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  symbol_gt?: InputMaybe<Scalars["String"]>;
  symbol_gte?: InputMaybe<Scalars["String"]>;
  symbol_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_lt?: InputMaybe<Scalars["String"]>;
  symbol_lte?: InputMaybe<Scalars["String"]>;
  symbol_not?: InputMaybe<Scalars["String"]>;
  symbol_not_contains?: InputMaybe<Scalars["String"]>;
  symbol_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  symbol_not_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  symbol_not_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_not_starts_with?: InputMaybe<Scalars["String"]>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  symbol_starts_with?: InputMaybe<Scalars["String"]>;
  symbol_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  tokens_?: InputMaybe<Erc721Token_Filter>;
  transfers_?: InputMaybe<Erc721Transfer_Filter>;
};

export enum Erc721Contract_OrderBy {
  AsAccount = "asAccount",
  AsAccountId = "asAccount__id",
  Id = "id",
  Name = "name",
  Operators = "operators",
  SupportsMetadata = "supportsMetadata",
  Symbol = "symbol",
  Tokens = "tokens",
  Transfers = "transfers",
}

export type Erc721Operator = {
  __typename?: "ERC721Operator";
  approved: Scalars["Boolean"];
  contract: Erc721Contract;
  id: Scalars["ID"];
  operator: Account;
  owner: Account;
};

export type Erc721Operator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc721Operator_Filter>>>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  approved_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  approved_not?: InputMaybe<Scalars["Boolean"]>;
  approved_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc721Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  operator?: InputMaybe<Scalars["String"]>;
  operator_?: InputMaybe<Account_Filter>;
  operator_contains?: InputMaybe<Scalars["String"]>;
  operator_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_ends_with?: InputMaybe<Scalars["String"]>;
  operator_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_gt?: InputMaybe<Scalars["String"]>;
  operator_gte?: InputMaybe<Scalars["String"]>;
  operator_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_lt?: InputMaybe<Scalars["String"]>;
  operator_lte?: InputMaybe<Scalars["String"]>;
  operator_not?: InputMaybe<Scalars["String"]>;
  operator_not_contains?: InputMaybe<Scalars["String"]>;
  operator_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_not_starts_with?: InputMaybe<Scalars["String"]>;
  operator_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_starts_with?: InputMaybe<Scalars["String"]>;
  operator_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<InputMaybe<Erc721Operator_Filter>>>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Operator_OrderBy {
  Approved = "approved",
  Contract = "contract",
  ContractId = "contract__id",
  ContractName = "contract__name",
  ContractSupportsMetadata = "contract__supportsMetadata",
  ContractSymbol = "contract__symbol",
  Id = "id",
  Operator = "operator",
  OperatorId = "operator__id",
  Owner = "owner",
  OwnerId = "owner__id",
}

export type Erc721Token = {
  __typename?: "ERC721Token";
  approval: Account;
  contract: Erc721Contract;
  id: Scalars["ID"];
  identifier: Scalars["BigInt"];
  mintBlock: Scalars["BigInt"];
  nftById?: Maybe<NftDto>;
  owner: Account;
  transfers: Array<Erc721Transfer>;
  uri?: Maybe<Scalars["String"]>;
};

export type Erc721TokenTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc721Token_Filter>>>;
  approval?: InputMaybe<Scalars["String"]>;
  approval_?: InputMaybe<Account_Filter>;
  approval_contains?: InputMaybe<Scalars["String"]>;
  approval_contains_nocase?: InputMaybe<Scalars["String"]>;
  approval_ends_with?: InputMaybe<Scalars["String"]>;
  approval_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  approval_gt?: InputMaybe<Scalars["String"]>;
  approval_gte?: InputMaybe<Scalars["String"]>;
  approval_in?: InputMaybe<Array<Scalars["String"]>>;
  approval_lt?: InputMaybe<Scalars["String"]>;
  approval_lte?: InputMaybe<Scalars["String"]>;
  approval_not?: InputMaybe<Scalars["String"]>;
  approval_not_contains?: InputMaybe<Scalars["String"]>;
  approval_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  approval_not_ends_with?: InputMaybe<Scalars["String"]>;
  approval_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  approval_not_in?: InputMaybe<Array<Scalars["String"]>>;
  approval_not_starts_with?: InputMaybe<Scalars["String"]>;
  approval_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  approval_starts_with?: InputMaybe<Scalars["String"]>;
  approval_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc721Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["BigInt"]>;
  identifier_gt?: InputMaybe<Scalars["BigInt"]>;
  identifier_gte?: InputMaybe<Scalars["BigInt"]>;
  identifier_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  identifier_lt?: InputMaybe<Scalars["BigInt"]>;
  identifier_lte?: InputMaybe<Scalars["BigInt"]>;
  identifier_not?: InputMaybe<Scalars["BigInt"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  mintBlock?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_gt?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_gte?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  mintBlock_lt?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_lte?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_not?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  or?: InputMaybe<Array<InputMaybe<Erc721Token_Filter>>>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transfers_?: InputMaybe<Erc721Transfer_Filter>;
  uri?: InputMaybe<Scalars["String"]>;
  uri_contains?: InputMaybe<Scalars["String"]>;
  uri_contains_nocase?: InputMaybe<Scalars["String"]>;
  uri_ends_with?: InputMaybe<Scalars["String"]>;
  uri_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_gt?: InputMaybe<Scalars["String"]>;
  uri_gte?: InputMaybe<Scalars["String"]>;
  uri_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_lt?: InputMaybe<Scalars["String"]>;
  uri_lte?: InputMaybe<Scalars["String"]>;
  uri_not?: InputMaybe<Scalars["String"]>;
  uri_not_contains?: InputMaybe<Scalars["String"]>;
  uri_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  uri_not_ends_with?: InputMaybe<Scalars["String"]>;
  uri_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_not_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_not_starts_with?: InputMaybe<Scalars["String"]>;
  uri_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_starts_with?: InputMaybe<Scalars["String"]>;
  uri_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Token_OrderBy {
  Approval = "approval",
  ApprovalId = "approval__id",
  Contract = "contract",
  ContractId = "contract__id",
  ContractName = "contract__name",
  ContractSupportsMetadata = "contract__supportsMetadata",
  ContractSymbol = "contract__symbol",
  Id = "id",
  Identifier = "identifier",
  MintBlock = "mintBlock",
  Owner = "owner",
  OwnerId = "owner__id",
  Transfers = "transfers",
  Uri = "uri",
}

export type Erc721Transfer = Event & {
  __typename?: "ERC721Transfer";
  contract: Erc721Contract;
  emitter: Account;
  from: Account;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  to: Account;
  token: Erc721Token;
  transaction: Transaction;
};

export type Erc721Transfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc721Transfer_Filter>>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc721Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_?: InputMaybe<Account_Filter>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  from_?: InputMaybe<Account_Filter>;
  from_contains?: InputMaybe<Scalars["String"]>;
  from_contains_nocase?: InputMaybe<Scalars["String"]>;
  from_ends_with?: InputMaybe<Scalars["String"]>;
  from_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  from_gt?: InputMaybe<Scalars["String"]>;
  from_gte?: InputMaybe<Scalars["String"]>;
  from_in?: InputMaybe<Array<Scalars["String"]>>;
  from_lt?: InputMaybe<Scalars["String"]>;
  from_lte?: InputMaybe<Scalars["String"]>;
  from_not?: InputMaybe<Scalars["String"]>;
  from_not_contains?: InputMaybe<Scalars["String"]>;
  from_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  from_not_ends_with?: InputMaybe<Scalars["String"]>;
  from_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  from_not_in?: InputMaybe<Array<Scalars["String"]>>;
  from_not_starts_with?: InputMaybe<Scalars["String"]>;
  from_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  from_starts_with?: InputMaybe<Scalars["String"]>;
  from_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Erc721Transfer_Filter>>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["String"]>;
  to_?: InputMaybe<Account_Filter>;
  to_contains?: InputMaybe<Scalars["String"]>;
  to_contains_nocase?: InputMaybe<Scalars["String"]>;
  to_ends_with?: InputMaybe<Scalars["String"]>;
  to_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  to_gt?: InputMaybe<Scalars["String"]>;
  to_gte?: InputMaybe<Scalars["String"]>;
  to_in?: InputMaybe<Array<Scalars["String"]>>;
  to_lt?: InputMaybe<Scalars["String"]>;
  to_lte?: InputMaybe<Scalars["String"]>;
  to_not?: InputMaybe<Scalars["String"]>;
  to_not_contains?: InputMaybe<Scalars["String"]>;
  to_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  to_not_ends_with?: InputMaybe<Scalars["String"]>;
  to_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  to_not_in?: InputMaybe<Array<Scalars["String"]>>;
  to_not_starts_with?: InputMaybe<Scalars["String"]>;
  to_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  to_starts_with?: InputMaybe<Scalars["String"]>;
  to_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  token_?: InputMaybe<Erc721Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_?: InputMaybe<Transaction_Filter>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Transfer_OrderBy {
  Contract = "contract",
  ContractId = "contract__id",
  ContractName = "contract__name",
  ContractSupportsMetadata = "contract__supportsMetadata",
  ContractSymbol = "contract__symbol",
  Emitter = "emitter",
  EmitterId = "emitter__id",
  From = "from",
  FromId = "from__id",
  Id = "id",
  Timestamp = "timestamp",
  To = "to",
  ToId = "to__id",
  Token = "token",
  TokenId = "token__id",
  TokenIdentifier = "token__identifier",
  TokenMintBlock = "token__mintBlock",
  TokenUri = "token__uri",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

export type Erc1155Balance = {
  __typename?: "ERC1155Balance";
  account?: Maybe<Account>;
  contract: Erc1155Contract;
  id: Scalars["ID"];
  token: Erc1155Token;
  transferFromEvent: Array<Erc1155Transfer>;
  transferToEvent: Array<Erc1155Transfer>;
  value: Scalars["BigDecimal"];
  valueExact: Scalars["BigInt"];
};

export type Erc1155BalanceTransferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type Erc1155BalanceTransferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type Erc1155Balance_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  account?: InputMaybe<Scalars["String"]>;
  account_?: InputMaybe<Account_Filter>;
  account_contains?: InputMaybe<Scalars["String"]>;
  account_contains_nocase?: InputMaybe<Scalars["String"]>;
  account_ends_with?: InputMaybe<Scalars["String"]>;
  account_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  account_gt?: InputMaybe<Scalars["String"]>;
  account_gte?: InputMaybe<Scalars["String"]>;
  account_in?: InputMaybe<Array<Scalars["String"]>>;
  account_lt?: InputMaybe<Scalars["String"]>;
  account_lte?: InputMaybe<Scalars["String"]>;
  account_not?: InputMaybe<Scalars["String"]>;
  account_not_contains?: InputMaybe<Scalars["String"]>;
  account_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  account_not_ends_with?: InputMaybe<Scalars["String"]>;
  account_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  account_not_in?: InputMaybe<Array<Scalars["String"]>>;
  account_not_starts_with?: InputMaybe<Scalars["String"]>;
  account_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  account_starts_with?: InputMaybe<Scalars["String"]>;
  account_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  and?: InputMaybe<Array<InputMaybe<Erc1155Balance_Filter>>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc1155Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Erc1155Balance_Filter>>>;
  token?: InputMaybe<Scalars["String"]>;
  token_?: InputMaybe<Erc1155Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transferFromEvent_?: InputMaybe<Erc1155Transfer_Filter>;
  transferToEvent_?: InputMaybe<Erc1155Transfer_Filter>;
  value?: InputMaybe<Scalars["BigDecimal"]>;
  valueExact?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  valueExact_lt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_lte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  value_gt?: InputMaybe<Scalars["BigDecimal"]>;
  value_gte?: InputMaybe<Scalars["BigDecimal"]>;
  value_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
  value_lt?: InputMaybe<Scalars["BigDecimal"]>;
  value_lte?: InputMaybe<Scalars["BigDecimal"]>;
  value_not?: InputMaybe<Scalars["BigDecimal"]>;
  value_not_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
};

export enum Erc1155Balance_OrderBy {
  Account = "account",
  AccountId = "account__id",
  Contract = "contract",
  ContractId = "contract__id",
  Id = "id",
  Token = "token",
  TokenId = "token__id",
  TokenIdentifier = "token__identifier",
  TokenMintBlock = "token__mintBlock",
  TokenUri = "token__uri",
  TransferFromEvent = "transferFromEvent",
  TransferToEvent = "transferToEvent",
  Value = "value",
  ValueExact = "valueExact",
}

export type Erc1155Contract = {
  __typename?: "ERC1155Contract";
  asAccount: Account;
  balances: Array<Erc1155Balance>;
  id: Scalars["ID"];
  operators: Array<Erc1155Operator>;
  tokens: Array<Erc1155Token>;
  transfers: Array<Erc1155Transfer>;
};

export type Erc1155ContractBalancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Balance_Filter>;
};

export type Erc1155ContractOperatorsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Operator_Filter>;
};

export type Erc1155ContractTokensArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Token_Filter>;
};

export type Erc1155ContractTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type Erc1155Contract_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc1155Contract_Filter>>>;
  asAccount?: InputMaybe<Scalars["String"]>;
  asAccount_?: InputMaybe<Account_Filter>;
  asAccount_contains?: InputMaybe<Scalars["String"]>;
  asAccount_contains_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_gt?: InputMaybe<Scalars["String"]>;
  asAccount_gte?: InputMaybe<Scalars["String"]>;
  asAccount_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_lt?: InputMaybe<Scalars["String"]>;
  asAccount_lte?: InputMaybe<Scalars["String"]>;
  asAccount_not?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_not_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  balances_?: InputMaybe<Erc1155Balance_Filter>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  operators_?: InputMaybe<Erc1155Operator_Filter>;
  or?: InputMaybe<Array<InputMaybe<Erc1155Contract_Filter>>>;
  tokens_?: InputMaybe<Erc1155Token_Filter>;
  transfers_?: InputMaybe<Erc1155Transfer_Filter>;
};

export enum Erc1155Contract_OrderBy {
  AsAccount = "asAccount",
  AsAccountId = "asAccount__id",
  Balances = "balances",
  Id = "id",
  Operators = "operators",
  Tokens = "tokens",
  Transfers = "transfers",
}

export type Erc1155Operator = {
  __typename?: "ERC1155Operator";
  approved: Scalars["Boolean"];
  contract: Erc1155Contract;
  id: Scalars["ID"];
  operator: Account;
  owner: Account;
};

export type Erc1155Operator_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc1155Operator_Filter>>>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  approved_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  approved_not?: InputMaybe<Scalars["Boolean"]>;
  approved_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc1155Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  operator?: InputMaybe<Scalars["String"]>;
  operator_?: InputMaybe<Account_Filter>;
  operator_contains?: InputMaybe<Scalars["String"]>;
  operator_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_ends_with?: InputMaybe<Scalars["String"]>;
  operator_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_gt?: InputMaybe<Scalars["String"]>;
  operator_gte?: InputMaybe<Scalars["String"]>;
  operator_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_lt?: InputMaybe<Scalars["String"]>;
  operator_lte?: InputMaybe<Scalars["String"]>;
  operator_not?: InputMaybe<Scalars["String"]>;
  operator_not_contains?: InputMaybe<Scalars["String"]>;
  operator_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_not_starts_with?: InputMaybe<Scalars["String"]>;
  operator_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_starts_with?: InputMaybe<Scalars["String"]>;
  operator_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<InputMaybe<Erc1155Operator_Filter>>>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_?: InputMaybe<Account_Filter>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Erc1155Operator_OrderBy {
  Approved = "approved",
  Contract = "contract",
  ContractId = "contract__id",
  Id = "id",
  Operator = "operator",
  OperatorId = "operator__id",
  Owner = "owner",
  OwnerId = "owner__id",
}

export type Erc1155Token = {
  __typename?: "ERC1155Token";
  balances: Array<Erc1155Balance>;
  contract: Erc1155Contract;
  id: Scalars["ID"];
  identifier: Scalars["BigInt"];
  mintBlock: Scalars["BigInt"];
  nftById?: Maybe<NftDto>;
  totalSupply: Erc1155Balance;
  transfers: Array<Erc1155Transfer>;
  uri?: Maybe<Scalars["String"]>;
};

export type Erc1155TokenBalancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Balance_Filter>;
};

export type Erc1155TokenTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type Erc1155Token_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc1155Token_Filter>>>;
  balances_?: InputMaybe<Erc1155Balance_Filter>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc1155Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["BigInt"]>;
  identifier_gt?: InputMaybe<Scalars["BigInt"]>;
  identifier_gte?: InputMaybe<Scalars["BigInt"]>;
  identifier_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  identifier_lt?: InputMaybe<Scalars["BigInt"]>;
  identifier_lte?: InputMaybe<Scalars["BigInt"]>;
  identifier_not?: InputMaybe<Scalars["BigInt"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  mintBlock?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_gt?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_gte?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  mintBlock_lt?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_lte?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_not?: InputMaybe<Scalars["BigInt"]>;
  mintBlock_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  or?: InputMaybe<Array<InputMaybe<Erc1155Token_Filter>>>;
  totalSupply?: InputMaybe<Scalars["String"]>;
  totalSupply_?: InputMaybe<Erc1155Balance_Filter>;
  totalSupply_contains?: InputMaybe<Scalars["String"]>;
  totalSupply_contains_nocase?: InputMaybe<Scalars["String"]>;
  totalSupply_ends_with?: InputMaybe<Scalars["String"]>;
  totalSupply_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  totalSupply_gt?: InputMaybe<Scalars["String"]>;
  totalSupply_gte?: InputMaybe<Scalars["String"]>;
  totalSupply_in?: InputMaybe<Array<Scalars["String"]>>;
  totalSupply_lt?: InputMaybe<Scalars["String"]>;
  totalSupply_lte?: InputMaybe<Scalars["String"]>;
  totalSupply_not?: InputMaybe<Scalars["String"]>;
  totalSupply_not_contains?: InputMaybe<Scalars["String"]>;
  totalSupply_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  totalSupply_not_ends_with?: InputMaybe<Scalars["String"]>;
  totalSupply_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  totalSupply_not_in?: InputMaybe<Array<Scalars["String"]>>;
  totalSupply_not_starts_with?: InputMaybe<Scalars["String"]>;
  totalSupply_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  totalSupply_starts_with?: InputMaybe<Scalars["String"]>;
  totalSupply_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transfers_?: InputMaybe<Erc1155Transfer_Filter>;
  uri?: InputMaybe<Scalars["String"]>;
  uri_contains?: InputMaybe<Scalars["String"]>;
  uri_contains_nocase?: InputMaybe<Scalars["String"]>;
  uri_ends_with?: InputMaybe<Scalars["String"]>;
  uri_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_gt?: InputMaybe<Scalars["String"]>;
  uri_gte?: InputMaybe<Scalars["String"]>;
  uri_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_lt?: InputMaybe<Scalars["String"]>;
  uri_lte?: InputMaybe<Scalars["String"]>;
  uri_not?: InputMaybe<Scalars["String"]>;
  uri_not_contains?: InputMaybe<Scalars["String"]>;
  uri_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  uri_not_ends_with?: InputMaybe<Scalars["String"]>;
  uri_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_not_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_not_starts_with?: InputMaybe<Scalars["String"]>;
  uri_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  uri_starts_with?: InputMaybe<Scalars["String"]>;
  uri_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Erc1155Token_OrderBy {
  Balances = "balances",
  Contract = "contract",
  ContractId = "contract__id",
  Id = "id",
  Identifier = "identifier",
  MintBlock = "mintBlock",
  TotalSupply = "totalSupply",
  TotalSupplyId = "totalSupply__id",
  TotalSupplyValue = "totalSupply__value",
  TotalSupplyValueExact = "totalSupply__valueExact",
  Transfers = "transfers",
  Uri = "uri",
}

export type Erc1155Transfer = Event & {
  __typename?: "ERC1155Transfer";
  contract: Erc1155Contract;
  emitter: Account;
  from?: Maybe<Account>;
  fromBalance?: Maybe<Erc1155Balance>;
  id: Scalars["ID"];
  operator: Account;
  timestamp: Scalars["BigInt"];
  to?: Maybe<Account>;
  toBalance?: Maybe<Erc1155Balance>;
  token: Erc1155Token;
  transaction: Transaction;
  value: Scalars["BigDecimal"];
  valueExact: Scalars["BigInt"];
};

export type Erc1155Transfer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Erc1155Transfer_Filter>>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_?: InputMaybe<Erc1155Contract_Filter>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_?: InputMaybe<Account_Filter>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  fromBalance?: InputMaybe<Scalars["String"]>;
  fromBalance_?: InputMaybe<Erc1155Balance_Filter>;
  fromBalance_contains?: InputMaybe<Scalars["String"]>;
  fromBalance_contains_nocase?: InputMaybe<Scalars["String"]>;
  fromBalance_ends_with?: InputMaybe<Scalars["String"]>;
  fromBalance_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  fromBalance_gt?: InputMaybe<Scalars["String"]>;
  fromBalance_gte?: InputMaybe<Scalars["String"]>;
  fromBalance_in?: InputMaybe<Array<Scalars["String"]>>;
  fromBalance_lt?: InputMaybe<Scalars["String"]>;
  fromBalance_lte?: InputMaybe<Scalars["String"]>;
  fromBalance_not?: InputMaybe<Scalars["String"]>;
  fromBalance_not_contains?: InputMaybe<Scalars["String"]>;
  fromBalance_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  fromBalance_not_ends_with?: InputMaybe<Scalars["String"]>;
  fromBalance_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  fromBalance_not_in?: InputMaybe<Array<Scalars["String"]>>;
  fromBalance_not_starts_with?: InputMaybe<Scalars["String"]>;
  fromBalance_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  fromBalance_starts_with?: InputMaybe<Scalars["String"]>;
  fromBalance_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  from_?: InputMaybe<Account_Filter>;
  from_contains?: InputMaybe<Scalars["String"]>;
  from_contains_nocase?: InputMaybe<Scalars["String"]>;
  from_ends_with?: InputMaybe<Scalars["String"]>;
  from_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  from_gt?: InputMaybe<Scalars["String"]>;
  from_gte?: InputMaybe<Scalars["String"]>;
  from_in?: InputMaybe<Array<Scalars["String"]>>;
  from_lt?: InputMaybe<Scalars["String"]>;
  from_lte?: InputMaybe<Scalars["String"]>;
  from_not?: InputMaybe<Scalars["String"]>;
  from_not_contains?: InputMaybe<Scalars["String"]>;
  from_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  from_not_ends_with?: InputMaybe<Scalars["String"]>;
  from_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  from_not_in?: InputMaybe<Array<Scalars["String"]>>;
  from_not_starts_with?: InputMaybe<Scalars["String"]>;
  from_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  from_starts_with?: InputMaybe<Scalars["String"]>;
  from_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  operator?: InputMaybe<Scalars["String"]>;
  operator_?: InputMaybe<Account_Filter>;
  operator_contains?: InputMaybe<Scalars["String"]>;
  operator_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_ends_with?: InputMaybe<Scalars["String"]>;
  operator_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_gt?: InputMaybe<Scalars["String"]>;
  operator_gte?: InputMaybe<Scalars["String"]>;
  operator_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_lt?: InputMaybe<Scalars["String"]>;
  operator_lte?: InputMaybe<Scalars["String"]>;
  operator_not?: InputMaybe<Scalars["String"]>;
  operator_not_contains?: InputMaybe<Scalars["String"]>;
  operator_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_not_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_not_starts_with?: InputMaybe<Scalars["String"]>;
  operator_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  operator_starts_with?: InputMaybe<Scalars["String"]>;
  operator_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<InputMaybe<Erc1155Transfer_Filter>>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["String"]>;
  toBalance?: InputMaybe<Scalars["String"]>;
  toBalance_?: InputMaybe<Erc1155Balance_Filter>;
  toBalance_contains?: InputMaybe<Scalars["String"]>;
  toBalance_contains_nocase?: InputMaybe<Scalars["String"]>;
  toBalance_ends_with?: InputMaybe<Scalars["String"]>;
  toBalance_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  toBalance_gt?: InputMaybe<Scalars["String"]>;
  toBalance_gte?: InputMaybe<Scalars["String"]>;
  toBalance_in?: InputMaybe<Array<Scalars["String"]>>;
  toBalance_lt?: InputMaybe<Scalars["String"]>;
  toBalance_lte?: InputMaybe<Scalars["String"]>;
  toBalance_not?: InputMaybe<Scalars["String"]>;
  toBalance_not_contains?: InputMaybe<Scalars["String"]>;
  toBalance_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  toBalance_not_ends_with?: InputMaybe<Scalars["String"]>;
  toBalance_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  toBalance_not_in?: InputMaybe<Array<Scalars["String"]>>;
  toBalance_not_starts_with?: InputMaybe<Scalars["String"]>;
  toBalance_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  toBalance_starts_with?: InputMaybe<Scalars["String"]>;
  toBalance_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  to_?: InputMaybe<Account_Filter>;
  to_contains?: InputMaybe<Scalars["String"]>;
  to_contains_nocase?: InputMaybe<Scalars["String"]>;
  to_ends_with?: InputMaybe<Scalars["String"]>;
  to_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  to_gt?: InputMaybe<Scalars["String"]>;
  to_gte?: InputMaybe<Scalars["String"]>;
  to_in?: InputMaybe<Array<Scalars["String"]>>;
  to_lt?: InputMaybe<Scalars["String"]>;
  to_lte?: InputMaybe<Scalars["String"]>;
  to_not?: InputMaybe<Scalars["String"]>;
  to_not_contains?: InputMaybe<Scalars["String"]>;
  to_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  to_not_ends_with?: InputMaybe<Scalars["String"]>;
  to_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  to_not_in?: InputMaybe<Array<Scalars["String"]>>;
  to_not_starts_with?: InputMaybe<Scalars["String"]>;
  to_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  to_starts_with?: InputMaybe<Scalars["String"]>;
  to_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  token_?: InputMaybe<Erc1155Token_Filter>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_?: InputMaybe<Transaction_Filter>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["BigDecimal"]>;
  valueExact?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  valueExact_lt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_lte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  value_gt?: InputMaybe<Scalars["BigDecimal"]>;
  value_gte?: InputMaybe<Scalars["BigDecimal"]>;
  value_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
  value_lt?: InputMaybe<Scalars["BigDecimal"]>;
  value_lte?: InputMaybe<Scalars["BigDecimal"]>;
  value_not?: InputMaybe<Scalars["BigDecimal"]>;
  value_not_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
};

export enum Erc1155Transfer_OrderBy {
  Contract = "contract",
  ContractId = "contract__id",
  Emitter = "emitter",
  EmitterId = "emitter__id",
  From = "from",
  FromBalance = "fromBalance",
  FromBalanceId = "fromBalance__id",
  FromBalanceValue = "fromBalance__value",
  FromBalanceValueExact = "fromBalance__valueExact",
  FromId = "from__id",
  Id = "id",
  Operator = "operator",
  OperatorId = "operator__id",
  Timestamp = "timestamp",
  To = "to",
  ToBalance = "toBalance",
  ToBalanceId = "toBalance__id",
  ToBalanceValue = "toBalance__value",
  ToBalanceValueExact = "toBalance__valueExact",
  ToId = "to__id",
  Token = "token",
  TokenId = "token__id",
  TokenIdentifier = "token__identifier",
  TokenMintBlock = "token__mintBlock",
  TokenUri = "token__uri",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
  Value = "value",
  ValueExact = "valueExact",
}

export type EcosystemPartner = {
  __typename?: "EcosystemPartner";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  ecosystem_partner_categories?: Maybe<EcosystemPartnerCategoryRelationResponseCollection>;
  landing_logo?: Maybe<UploadFileRelationResponseCollection>;
  logo?: Maybe<UploadFileRelationResponseCollection>;
  name?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  url?: Maybe<Scalars["String"]>;
};

export type EcosystemPartnerEcosystem_Partner_CategoriesArgs = {
  filters?: InputMaybe<EcosystemPartnerCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type EcosystemPartnerLanding_LogoArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type EcosystemPartnerLogoArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type EcosystemPartnerCategory = {
  __typename?: "EcosystemPartnerCategory";
  Name?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  ecosystem_partner?: Maybe<EcosystemPartnerEntityResponse>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EcosystemPartnerCategoryEntity = {
  __typename?: "EcosystemPartnerCategoryEntity";
  attributes?: Maybe<EcosystemPartnerCategory>;
  id?: Maybe<Scalars["ID"]>;
};

export type EcosystemPartnerCategoryEntityResponse = {
  __typename?: "EcosystemPartnerCategoryEntityResponse";
  data?: Maybe<EcosystemPartnerCategoryEntity>;
};

export type EcosystemPartnerCategoryEntityResponseCollection = {
  __typename?: "EcosystemPartnerCategoryEntityResponseCollection";
  data: Array<EcosystemPartnerCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type EcosystemPartnerCategoryFiltersInput = {
  Name?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EcosystemPartnerCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ecosystem_partner?: InputMaybe<EcosystemPartnerFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<EcosystemPartnerCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EcosystemPartnerCategoryFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EcosystemPartnerCategoryInput = {
  Name?: InputMaybe<Scalars["String"]>;
  ecosystem_partner?: InputMaybe<Scalars["ID"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type EcosystemPartnerCategoryRelationResponseCollection = {
  __typename?: "EcosystemPartnerCategoryRelationResponseCollection";
  data: Array<EcosystemPartnerCategoryEntity>;
};

export type EcosystemPartnerEntity = {
  __typename?: "EcosystemPartnerEntity";
  attributes?: Maybe<EcosystemPartner>;
  id?: Maybe<Scalars["ID"]>;
};

export type EcosystemPartnerEntityResponse = {
  __typename?: "EcosystemPartnerEntityResponse";
  data?: Maybe<EcosystemPartnerEntity>;
};

export type EcosystemPartnerEntityResponseCollection = {
  __typename?: "EcosystemPartnerEntityResponseCollection";
  data: Array<EcosystemPartnerEntity>;
  meta: ResponseCollectionMeta;
};

export type EcosystemPartnerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EcosystemPartnerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  ecosystem_partner_categories?: InputMaybe<EcosystemPartnerCategoryFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EcosystemPartnerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EcosystemPartnerFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type EcosystemPartnerInput = {
  description?: InputMaybe<Scalars["String"]>;
  ecosystem_partner_categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  landing_logo?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  logo?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type Error = {
  __typename?: "Error";
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type Event = {
  emitter: Account;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  transaction: Transaction;
};

export type Event_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Event_Filter>>>;
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_?: InputMaybe<Account_Filter>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Event_Filter>>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_?: InputMaybe<Transaction_Filter>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum Event_OrderBy {
  Emitter = "emitter",
  EmitterId = "emitter__id",
  Id = "id",
  Timestamp = "timestamp",
  Transaction = "transaction",
  TransactionBlockNumber = "transaction__blockNumber",
  TransactionId = "transaction__id",
  TransactionTimestamp = "transaction__timestamp",
}

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars["String"]>;
  caption?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  contains?: InputMaybe<Scalars["Float"]>;
  containsi?: InputMaybe<Scalars["Float"]>;
  endsWith?: InputMaybe<Scalars["Float"]>;
  eq?: InputMaybe<Scalars["Float"]>;
  eqi?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  ne?: InputMaybe<Scalars["Float"]>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars["Float"]>;
  notContainsi?: InputMaybe<Scalars["Float"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  startsWith?: InputMaybe<Scalars["Float"]>;
};

export type Game = {
  __typename?: "Game";
  background?: Maybe<UploadFileEntityResponse>;
  categories?: Maybe<GameCategoryRelationResponseCollection>;
  chains?: Maybe<ChainRelationResponseCollection>;
  comingSoon?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  devices?: Maybe<GameDeviceRelationResponseCollection>;
  faq?: Maybe<Array<Maybe<ComponentSharedFaqItem>>>;
  fullDescription?: Maybe<Scalars["String"]>;
  genres?: Maybe<GameGenreRelationResponseCollection>;
  icon?: Maybe<UploadFileEntityResponse>;
  investment?: Maybe<Scalars["Float"]>;
  links?: Maybe<Array<Maybe<ComponentSharedSocialNetwork>>>;
  priority?: Maybe<Scalars["Int"]>;
  provider?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  releaseDate?: Maybe<Scalars["Date"]>;
  requirements?: Maybe<Scalars["String"]>;
  seo?: Maybe<ComponentSharedSeo>;
  slug: Scalars["String"];
  statuses?: Maybe<GameStatusRelationResponseCollection>;
  thumbnail?: Maybe<UploadFileEntityResponse>;
  title: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GameCategoriesArgs = {
  filters?: InputMaybe<GameCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameChainsArgs = {
  filters?: InputMaybe<ChainFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameDevicesArgs = {
  filters?: InputMaybe<GameDeviceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameFaqArgs = {
  filters?: InputMaybe<ComponentSharedFaqItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameGenresArgs = {
  filters?: InputMaybe<GameGenreFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameLinksArgs = {
  filters?: InputMaybe<ComponentSharedSocialNetworkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameStatusesArgs = {
  filters?: InputMaybe<GameStatusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameCategory = {
  __typename?: "GameCategory";
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<GameRelationResponseCollection>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GameCategoryGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameCategoryEntity = {
  __typename?: "GameCategoryEntity";
  attributes?: Maybe<GameCategory>;
  id?: Maybe<Scalars["ID"]>;
};

export type GameCategoryEntityResponse = {
  __typename?: "GameCategoryEntityResponse";
  data?: Maybe<GameCategoryEntity>;
};

export type GameCategoryEntityResponseCollection = {
  __typename?: "GameCategoryEntityResponseCollection";
  data: Array<GameCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type GameCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GameCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  games?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GameCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GameCategoryFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GameCategoryInput = {
  games?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type GameCategoryRelationResponseCollection = {
  __typename?: "GameCategoryRelationResponseCollection";
  data: Array<GameCategoryEntity>;
};

export type GameDevice = {
  __typename?: "GameDevice";
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<GameRelationResponseCollection>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GameDeviceGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameDeviceEntity = {
  __typename?: "GameDeviceEntity";
  attributes?: Maybe<GameDevice>;
  id?: Maybe<Scalars["ID"]>;
};

export type GameDeviceEntityResponse = {
  __typename?: "GameDeviceEntityResponse";
  data?: Maybe<GameDeviceEntity>;
};

export type GameDeviceEntityResponseCollection = {
  __typename?: "GameDeviceEntityResponseCollection";
  data: Array<GameDeviceEntity>;
  meta: ResponseCollectionMeta;
};

export type GameDeviceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GameDeviceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  games?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GameDeviceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GameDeviceFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GameDeviceInput = {
  games?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type GameDeviceRelationResponseCollection = {
  __typename?: "GameDeviceRelationResponseCollection";
  data: Array<GameDeviceEntity>;
};

export type GameEntity = {
  __typename?: "GameEntity";
  attributes?: Maybe<Game>;
  id?: Maybe<Scalars["ID"]>;
};

export type GameEntityResponse = {
  __typename?: "GameEntityResponse";
  data?: Maybe<GameEntity>;
};

export type GameEntityResponseCollection = {
  __typename?: "GameEntityResponseCollection";
  data: Array<GameEntity>;
  meta: ResponseCollectionMeta;
};

export type GameFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GameFiltersInput>>>;
  categories?: InputMaybe<GameCategoryFiltersInput>;
  chains?: InputMaybe<ChainFiltersInput>;
  comingSoon?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  devices?: InputMaybe<GameDeviceFiltersInput>;
  faq?: InputMaybe<ComponentSharedFaqItemFiltersInput>;
  fullDescription?: InputMaybe<StringFilterInput>;
  genres?: InputMaybe<GameGenreFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  investment?: InputMaybe<FloatFilterInput>;
  links?: InputMaybe<ComponentSharedSocialNetworkFiltersInput>;
  not?: InputMaybe<GameFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GameFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  releaseDate?: InputMaybe<DateFilterInput>;
  requirements?: InputMaybe<StringFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  statuses?: InputMaybe<GameStatusFiltersInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GameGenre = {
  __typename?: "GameGenre";
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<GameRelationResponseCollection>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GameGenreGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameGenreEntity = {
  __typename?: "GameGenreEntity";
  attributes?: Maybe<GameGenre>;
  id?: Maybe<Scalars["ID"]>;
};

export type GameGenreEntityResponse = {
  __typename?: "GameGenreEntityResponse";
  data?: Maybe<GameGenreEntity>;
};

export type GameGenreEntityResponseCollection = {
  __typename?: "GameGenreEntityResponseCollection";
  data: Array<GameGenreEntity>;
  meta: ResponseCollectionMeta;
};

export type GameGenreFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GameGenreFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  games?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GameGenreFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GameGenreFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GameGenreInput = {
  games?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type GameGenreRelationResponseCollection = {
  __typename?: "GameGenreRelationResponseCollection";
  data: Array<GameGenreEntity>;
};

export type GameInput = {
  background?: InputMaybe<Scalars["ID"]>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  chains?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  comingSoon?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  devices?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  faq?: InputMaybe<Array<InputMaybe<ComponentSharedFaqItemInput>>>;
  fullDescription?: InputMaybe<Scalars["String"]>;
  genres?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  icon?: InputMaybe<Scalars["ID"]>;
  investment?: InputMaybe<Scalars["Float"]>;
  links?: InputMaybe<Array<InputMaybe<ComponentSharedSocialNetworkInput>>>;
  priority?: InputMaybe<Scalars["Int"]>;
  provider?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  releaseDate?: InputMaybe<Scalars["Date"]>;
  requirements?: InputMaybe<Scalars["String"]>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  thumbnail?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type GameRelationResponseCollection = {
  __typename?: "GameRelationResponseCollection";
  data: Array<GameEntity>;
};

export type GameStatus = {
  __typename?: "GameStatus";
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<GameRelationResponseCollection>;
  name: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GameStatusGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GameStatusEntity = {
  __typename?: "GameStatusEntity";
  attributes?: Maybe<GameStatus>;
  id?: Maybe<Scalars["ID"]>;
};

export type GameStatusEntityResponse = {
  __typename?: "GameStatusEntityResponse";
  data?: Maybe<GameStatusEntity>;
};

export type GameStatusEntityResponseCollection = {
  __typename?: "GameStatusEntityResponseCollection";
  data: Array<GameStatusEntity>;
  meta: ResponseCollectionMeta;
};

export type GameStatusFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GameStatusFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  games?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GameStatusFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GameStatusFiltersInput>>>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GameStatusInput = {
  games?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type GameStatusRelationResponseCollection = {
  __typename?: "GameStatusRelationResponseCollection";
  data: Array<GameStatusEntity>;
};

export type GenericMorph =
  | Article
  | Author
  | Category
  | Chain
  | ComponentSharedArticleThumbnail
  | ComponentSharedFaqItem
  | ComponentSharedLinkButtonWithDescription
  | ComponentSharedMedia
  | ComponentSharedMenuItem
  | ComponentSharedMenuItemPrimitive
  | ComponentSharedMetaSocial
  | ComponentSharedQuote
  | ComponentSharedRelatedArticles
  | ComponentSharedRewardGroups
  | ComponentSharedRichText
  | ComponentSharedSeo
  | ComponentSharedSlider
  | ComponentSharedSocialNetwork
  | ComponentSharedTileGrid
  | EcosystemPartner
  | EcosystemPartnerCategory
  | Game
  | GameCategory
  | GameDevice
  | GameGenre
  | GameStatus
  | Global
  | I18NLocale
  | Menu
  | Task
  | TradeCompetition
  | UploadFile
  | UploadFolder
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser;

export type Global = {
  __typename?: "Global";
  createdAt?: Maybe<Scalars["DateTime"]>;
  games?: Maybe<ComponentSharedSeo>;
  nfts?: Maybe<ComponentSharedSeo>;
  seo?: Maybe<ComponentSharedSeo>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type GlobalEntity = {
  __typename?: "GlobalEntity";
  attributes?: Maybe<Global>;
  id?: Maybe<Scalars["ID"]>;
};

export type GlobalEntityResponse = {
  __typename?: "GlobalEntityResponse";
  data?: Maybe<GlobalEntity>;
};

export type GlobalInput = {
  games?: InputMaybe<ComponentSharedSeoInput>;
  nfts?: InputMaybe<ComponentSharedSeoInput>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type I18NLocale = {
  __typename?: "I18NLocale";
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type I18NLocaleEntity = {
  __typename?: "I18NLocaleEntity";
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars["ID"]>;
};

export type I18NLocaleEntityResponse = {
  __typename?: "I18NLocaleEntityResponse";
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: "I18NLocaleEntityResponseCollection";
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  contains?: InputMaybe<Scalars["ID"]>;
  containsi?: InputMaybe<Scalars["ID"]>;
  endsWith?: InputMaybe<Scalars["ID"]>;
  eq?: InputMaybe<Scalars["ID"]>;
  eqi?: InputMaybe<Scalars["ID"]>;
  gt?: InputMaybe<Scalars["ID"]>;
  gte?: InputMaybe<Scalars["ID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  lt?: InputMaybe<Scalars["ID"]>;
  lte?: InputMaybe<Scalars["ID"]>;
  ne?: InputMaybe<Scalars["ID"]>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars["ID"]>;
  notContainsi?: InputMaybe<Scalars["ID"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  startsWith?: InputMaybe<Scalars["ID"]>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contains?: InputMaybe<Scalars["Int"]>;
  containsi?: InputMaybe<Scalars["Int"]>;
  endsWith?: InputMaybe<Scalars["Int"]>;
  eq?: InputMaybe<Scalars["Int"]>;
  eqi?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  ne?: InputMaybe<Scalars["Int"]>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars["Int"]>;
  notContainsi?: InputMaybe<Scalars["Int"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  startsWith?: InputMaybe<Scalars["Int"]>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  contains?: InputMaybe<Scalars["JSON"]>;
  containsi?: InputMaybe<Scalars["JSON"]>;
  endsWith?: InputMaybe<Scalars["JSON"]>;
  eq?: InputMaybe<Scalars["JSON"]>;
  eqi?: InputMaybe<Scalars["JSON"]>;
  gt?: InputMaybe<Scalars["JSON"]>;
  gte?: InputMaybe<Scalars["JSON"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  lt?: InputMaybe<Scalars["JSON"]>;
  lte?: InputMaybe<Scalars["JSON"]>;
  ne?: InputMaybe<Scalars["JSON"]>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars["JSON"]>;
  notContainsi?: InputMaybe<Scalars["JSON"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  startsWith?: InputMaybe<Scalars["JSON"]>;
};

export enum ListingStatus {
  Active = "Active",
  Banned = "Banned",
  Borrowed = "Borrowed",
  Cancelled = "Cancelled",
  Returned = "Returned",
  Sold = "Sold",
  Stale = "Stale",
}

export type MaxSyncBlock = {
  __typename?: "MaxSyncBlock";
  chainId: Scalars["Float"];
  maxSyncBlock: Scalars["Float"];
};

export type Menu = {
  __typename?: "Menu";
  createdAt?: Maybe<Scalars["DateTime"]>;
  items?: Maybe<Array<Maybe<ComponentSharedMenuItem>>>;
  name?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type MenuItemsArgs = {
  filters?: InputMaybe<ComponentSharedMenuItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type MenuEntity = {
  __typename?: "MenuEntity";
  attributes?: Maybe<Menu>;
  id?: Maybe<Scalars["ID"]>;
};

export type MenuEntityResponse = {
  __typename?: "MenuEntityResponse";
  data?: Maybe<MenuEntity>;
};

export type MenuEntityResponseCollection = {
  __typename?: "MenuEntityResponseCollection";
  data: Array<MenuEntity>;
  meta: ResponseCollectionMeta;
};

export type MenuFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MenuFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  items?: InputMaybe<ComponentSharedMenuItemFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<MenuFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MenuFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MenuInput = {
  items?: InputMaybe<Array<InputMaybe<ComponentSharedMenuItemInput>>>;
  name?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type MetadataPropertyDto = {
  __typename?: "MetadataPropertyDto";
  display_type: Scalars["String"];
  name: Scalars["String"];
  trait_type: Scalars["String"];
  type: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createArticle?: Maybe<ArticleEntityResponse>;
  createAuthor?: Maybe<AuthorEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  createChain?: Maybe<ChainEntityResponse>;
  createEcosystemPartner?: Maybe<EcosystemPartnerEntityResponse>;
  createEcosystemPartnerCategory?: Maybe<EcosystemPartnerCategoryEntityResponse>;
  createGame?: Maybe<GameEntityResponse>;
  createGameCategory?: Maybe<GameCategoryEntityResponse>;
  createGameDevice?: Maybe<GameDeviceEntityResponse>;
  createGameGenre?: Maybe<GameGenreEntityResponse>;
  createGameStatus?: Maybe<GameStatusEntityResponse>;
  createMenu?: Maybe<MenuEntityResponse>;
  createTask?: Maybe<TaskEntityResponse>;
  createTradeCompetition?: Maybe<TradeCompetitionEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteArticle?: Maybe<ArticleEntityResponse>;
  deleteAuthor?: Maybe<AuthorEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  deleteChain?: Maybe<ChainEntityResponse>;
  deleteEcosystemPartner?: Maybe<EcosystemPartnerEntityResponse>;
  deleteEcosystemPartnerCategory?: Maybe<EcosystemPartnerCategoryEntityResponse>;
  deleteGame?: Maybe<GameEntityResponse>;
  deleteGameCategory?: Maybe<GameCategoryEntityResponse>;
  deleteGameDevice?: Maybe<GameDeviceEntityResponse>;
  deleteGameGenre?: Maybe<GameGenreEntityResponse>;
  deleteGameStatus?: Maybe<GameStatusEntityResponse>;
  deleteGlobal?: Maybe<GlobalEntityResponse>;
  deleteMenu?: Maybe<MenuEntityResponse>;
  deleteTask?: Maybe<TaskEntityResponse>;
  deleteTradeCompetition?: Maybe<TradeCompetitionEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  refreshSingleNft: Scalars["DateTime"];
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  reindex: AdminOperationResultDto;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateArticle?: Maybe<ArticleEntityResponse>;
  updateAuthor?: Maybe<AuthorEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  updateChain?: Maybe<ChainEntityResponse>;
  updateEcosystemPartner?: Maybe<EcosystemPartnerEntityResponse>;
  updateEcosystemPartnerCategory?: Maybe<EcosystemPartnerCategoryEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGame?: Maybe<GameEntityResponse>;
  updateGameCategory?: Maybe<GameCategoryEntityResponse>;
  updateGameDevice?: Maybe<GameDeviceEntityResponse>;
  updateGameGenre?: Maybe<GameGenreEntityResponse>;
  updateGameStatus?: Maybe<GameStatusEntityResponse>;
  updateGlobal?: Maybe<GlobalEntityResponse>;
  updateListing: RentListing;
  updateMenu?: Maybe<MenuEntityResponse>;
  updateTask?: Maybe<TaskEntityResponse>;
  updateTradeCompetition?: Maybe<TradeCompetitionEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationCreateArticleArgs = {
  data: ArticleInput;
};

export type MutationCreateAuthorArgs = {
  data: AuthorInput;
};

export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};

export type MutationCreateChainArgs = {
  data: ChainInput;
};

export type MutationCreateEcosystemPartnerArgs = {
  data: EcosystemPartnerInput;
};

export type MutationCreateEcosystemPartnerCategoryArgs = {
  data: EcosystemPartnerCategoryInput;
};

export type MutationCreateGameArgs = {
  data: GameInput;
};

export type MutationCreateGameCategoryArgs = {
  data: GameCategoryInput;
};

export type MutationCreateGameDeviceArgs = {
  data: GameDeviceInput;
};

export type MutationCreateGameGenreArgs = {
  data: GameGenreInput;
};

export type MutationCreateGameStatusArgs = {
  data: GameStatusInput;
};

export type MutationCreateMenuArgs = {
  data: MenuInput;
};

export type MutationCreateTaskArgs = {
  data: TaskInput;
};

export type MutationCreateTradeCompetitionArgs = {
  data: TradeCompetitionInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteArticleArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAuthorArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteChainArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteEcosystemPartnerArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteEcosystemPartnerCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGameArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGameCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGameDeviceArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGameGenreArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGameStatusArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteMenuArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTaskArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTradeCompetitionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars["ID"];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars["String"];
};

export type MutationForgotPasswordArgs = {
  email: Scalars["String"];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars["String"]>;
  files: Array<InputMaybe<Scalars["Upload"]>>;
  ref?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["ID"]>;
};

export type MutationRefreshSingleNftArgs = {
  chainId?: InputMaybe<ChainId>;
  nftId: Scalars["String"];
  provider?: InputMaybe<NftProvider>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationReindexArgs = {
  adminAuthToken: Scalars["String"];
};

export type MutationRemoveFileArgs = {
  id: Scalars["ID"];
};

export type MutationResetPasswordArgs = {
  code: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationUpdateArticleArgs = {
  data: ArticleInput;
  id: Scalars["ID"];
};

export type MutationUpdateAuthorArgs = {
  data: AuthorInput;
  id: Scalars["ID"];
};

export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  id: Scalars["ID"];
};

export type MutationUpdateChainArgs = {
  data: ChainInput;
  id: Scalars["ID"];
};

export type MutationUpdateEcosystemPartnerArgs = {
  data: EcosystemPartnerInput;
  id: Scalars["ID"];
};

export type MutationUpdateEcosystemPartnerCategoryArgs = {
  data: EcosystemPartnerCategoryInput;
  id: Scalars["ID"];
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars["ID"];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateGameArgs = {
  data: GameInput;
  id: Scalars["ID"];
};

export type MutationUpdateGameCategoryArgs = {
  data: GameCategoryInput;
  id: Scalars["ID"];
};

export type MutationUpdateGameDeviceArgs = {
  data: GameDeviceInput;
  id: Scalars["ID"];
};

export type MutationUpdateGameGenreArgs = {
  data: GameGenreInput;
  id: Scalars["ID"];
};

export type MutationUpdateGameStatusArgs = {
  data: GameStatusInput;
  id: Scalars["ID"];
};

export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
};

export type MutationUpdateListingArgs = {
  autoReturnNft: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationUpdateMenuArgs = {
  data: MenuInput;
  id: Scalars["ID"];
};

export type MutationUpdateTaskArgs = {
  data: TaskInput;
  id: Scalars["ID"];
};

export type MutationUpdateTradeCompetitionArgs = {
  data: TradeCompetitionInput;
  id: Scalars["ID"];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars["ID"];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars["ID"];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars["ID"];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars["ID"];
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars["String"]>;
  refId?: InputMaybe<Scalars["ID"]>;
};

export type NftItem = {
  __typename?: "NFTItem";
  id: Scalars["ID"];
  listing: TradeBundleListing;
  nftAddress: Scalars["Bytes"];
  quantity: Scalars["BigInt"];
  tokenId: Scalars["BigInt"];
};

export type NftItem_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftItem_Filter>>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  listing?: InputMaybe<Scalars["String"]>;
  listing_?: InputMaybe<TradeBundleListing_Filter>;
  listing_contains?: InputMaybe<Scalars["String"]>;
  listing_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_ends_with?: InputMaybe<Scalars["String"]>;
  listing_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_gt?: InputMaybe<Scalars["String"]>;
  listing_gte?: InputMaybe<Scalars["String"]>;
  listing_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_lt?: InputMaybe<Scalars["String"]>;
  listing_lte?: InputMaybe<Scalars["String"]>;
  listing_not?: InputMaybe<Scalars["String"]>;
  listing_not_contains?: InputMaybe<Scalars["String"]>;
  listing_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_not_starts_with?: InputMaybe<Scalars["String"]>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_starts_with?: InputMaybe<Scalars["String"]>;
  listing_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  nftAddress?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  nftAddress_lt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_lte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  or?: InputMaybe<Array<InputMaybe<NftItem_Filter>>>;
  quantity?: InputMaybe<Scalars["BigInt"]>;
  quantity_gt?: InputMaybe<Scalars["BigInt"]>;
  quantity_gte?: InputMaybe<Scalars["BigInt"]>;
  quantity_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  quantity_lt?: InputMaybe<Scalars["BigInt"]>;
  quantity_lte?: InputMaybe<Scalars["BigInt"]>;
  quantity_not?: InputMaybe<Scalars["BigInt"]>;
  quantity_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenId?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenId_lt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_lte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export enum NftItem_OrderBy {
  Id = "id",
  Listing = "listing",
  ListingCreatedAt = "listing__createdAt",
  ListingEndTime = "listing__endTime",
  ListingId = "listing__id",
  ListingOwner = "listing__owner",
  ListingPaymentToken = "listing__paymentToken",
  ListingPrice = "listing__price",
  ListingPublished = "listing__published",
  ListingStartingTime = "listing__startingTime",
  ListingStatus = "listing__status",
  ListingUpdatedAt = "listing__updatedAt",
  NftAddress = "nftAddress",
  Quantity = "quantity",
  TokenId = "tokenId",
}

export type NftAggregationDto = {
  __typename?: "NftAggregationDto";
  providers?: Maybe<Array<ProviderAggregationDto>>;
};

export type NftDto = {
  __typename?: "NftDto";
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
  id: Scalars["String"];
  image?: Maybe<Scalars["String"]>;
  lastModifiedDate: Scalars["DateTime"];
  metadata?: Maybe<Array<MetadataPropertyDto>>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  properties?: Maybe<Array<Maybe<PropertyDto>>>;
  protocol?: Maybe<Scalars["String"]>;
  provider: Scalars["String"];
  rentListing?: Maybe<RentListingDto>;
  searchId: Scalars["String"];
  tokenId: Scalars["String"];
  tradeListing?: Maybe<Array<TradeListingDto>>;
  waitingManualSync?: Maybe<Scalars["Boolean"]>;
};

export enum NftField {
  ChainId = "chainId",
  ContractAddress = "contractAddress",
  DocId = "docId",
  Id = "id",
  Image = "image",
  Metadata = "metadata",
  Name = "name",
  NftId = "nft_id",
  Protocol = "protocol",
  Provider = "provider",
  RentBlock = "rentBlock",
  RentBorrower = "rentBorrower",
  RentDuration = "rentDuration",
  RentFinalPaymentValue = "rentFinalPaymentValue",
  RentFinalPaymentValueUsd = "rentFinalPaymentValueUsd",
  RentListingCreatedAt = "rentListingCreatedAt",
  RentListingId = "rentListingId",
  RentListingOwner = "rentListingOwner",
  RentListingUpdatedAt = "rentListingUpdatedAt",
  RentNftAddress = "rentNftAddress",
  RentPaymentToken = "rentPaymentToken",
  RentPaymentTokenName = "rentPaymentTokenName",
  RentPricePerSecond = "rentPricePerSecond",
  RentPricePerSecondUsd = "rentPricePerSecondUsd",
  RentPublished = "rentPublished",
  RentPurchaser = "rentPurchaser",
  RentStatus = "rentStatus",
  TokenId = "tokenId",
  TradeBatchCount = "tradeBatchCount",
  TradeBatchId = "tradeBatchId",
  TradeChainId = "tradeChainId",
  TradeContract = "tradeContract",
  TradeCreateDateTime = "tradeCreateDateTime",
  TradeCreatedAt = "tradeCreatedAt",
  TradeEndTime = "tradeEndTime",
  TradeId = "tradeId",
  TradeLastChangedDateTime = "tradeLastChangedDateTime",
  TradeNftAddress = "tradeNftAddress",
  TradeNonce = "tradeNonce",
  TradeOwner = "tradeOwner",
  TradePaymentToken = "tradePaymentToken",
  TradePaymentTokenName = "tradePaymentTokenName",
  TradePricePerItem = "tradePricePerItem",
  TradePricePerItemUsd = "tradePricePerItemUsd",
  TradeProvider = "tradeProvider",
  TradePublished = "tradePublished",
  TradeQuantity = "tradeQuantity",
  TradeStartingTime = "tradeStartingTime",
  TradeStatus = "tradeStatus",
  TradeSyncBlock = "tradeSyncBlock",
  TradeTokenId = "tradeTokenId",
  TradeUpdatedAt = "tradeUpdatedAt",
  WaitingManualSync = "waitingManualSync",
}

export type NftFilterItem = {
  field: NftField;
  name?: InputMaybe<Scalars["String"]>;
  operator: Operator;
  type?: InputMaybe<DataType>;
  value?: InputMaybe<Array<Scalars["String"]>>;
};

export enum NftProvider {
  Aavegotchi = "AAVEGOTCHI",
  Bullieverse = "BULLIEVERSE",
  ChumbiValley = "CHUMBI_VALLEY",
  CosTest = "COS_TEST",
  Cryptoblades = "CRYPTOBLADES",
  ElpisBattle = "ELPIS_BATTLE",
  EonRift = "EON_RIFT",
  LastResort = "LAST_RESORT",
  LifeBeyond = "LIFE_BEYOND",
  LifeBeyondAgentZeroJacket = "LIFE_BEYOND_AGENT_ZERO_JACKET",
  MagicCraft = "MAGIC_CRAFT",
  MetaShooterNft = "META_SHOOTER_NFT",
  MetaSoccer = "META_SOCCER",
  ObsWorld = "OBS_WORLD",
  ParticleInk = "PARTICLE_INK",
  Poglin = "POGLIN",
  PromTestGame = "PROM_TEST_GAME",
  Pxquest = "PXQUEST",
  RealFevr = "REAL_FEVR",
  RookiesClaim = "ROOKIES_CLAIM",
  Seeker = "SEEKER",
  Sugartown = "SUGARTOWN",
  SwordAndMagicWorld = "SWORD_AND_MAGIC_WORLD",
  Tankwarszone = "TANKWARSZONE",
  TearingSpaces = "TEARING_SPACES",
  TinyWorld = "TINY_WORLD",
  TrueGems = "TRUE_GEMS",
  Vanguard = "VANGUARD",
  Xociety = "XOCIETY",
}

export type NftResponseDto = {
  __typename?: "NftResponseDto";
  aggregations?: Maybe<NftAggregationDto>;
  nft?: Maybe<Array<NftDto>>;
  outdatedRequestId?: Maybe<Scalars["Boolean"]>;
  total?: Maybe<Scalars["Float"]>;
};

export type Nft_Filter = {
  andFilters?: InputMaybe<Array<NftFilterItem>>;
  orFilters?: InputMaybe<Array<NftFilterItem>>;
};

export enum Operator {
  Contains = "CONTAINS",
  Eq = "EQ",
  Exists = "EXISTS",
  Gt = "GT",
  Gte = "GTE",
  In = "IN",
  Like = "LIKE",
  Lt = "LT",
  Lte = "LTE",
  Not = "NOT",
  NotContains = "NOT_CONTAINS",
  NotIn = "NOT_IN",
}

export enum OrderBy {
  ContractAddress = "ContractAddress",
  CreateDateTime = "CreateDateTime",
  LastChangedDateTime = "LastChangedDateTime",
  Name = "Name",
  NftId = "NftId",
  Protocol = "Protocol",
  Provider = "Provider",
  RentListingCreatedAt = "RentListingCreatedAt",
  RentListingDuration = "RentListingDuration",
  RentListingFinalPaymentValue = "RentListingFinalPaymentValue",
  RentListingFinalPaymentValueUsd = "RentListingFinalPaymentValueUsd",
  RentListingId = "RentListingId",
  RentListingListingOwner = "RentListingListingOwner",
  RentListingNftAddress = "RentListingNftAddress",
  RentListingPaymentToken = "RentListingPaymentToken",
  RentListingPricePerSecond = "RentListingPricePerSecond",
  RentListingPricePerSecondUsd = "RentListingPricePerSecondUsd",
  RentListingStatus = "RentListingStatus",
  RentListingUpdatedAt = "RentListingUpdatedAt",
  TokenId = "TokenId",
  TradeListingCreatedAt = "TradeListingCreatedAt",
  TradeListingDuration = "TradeListingDuration",
  TradeListingEndTime = "TradeListingEndTime",
  TradeListingId = "TradeListingId",
  TradeListingListingOwner = "TradeListingListingOwner",
  TradeListingNftAddress = "TradeListingNftAddress",
  TradeListingNonce = "TradeListingNonce",
  TradeListingPaymentToken = "TradeListingPaymentToken",
  TradeListingPricePerItem = "TradeListingPricePerItem",
  TradeListingPricePerItemUsd = "TradeListingPricePerItemUsd",
  TradeListingQuantity = "TradeListingQuantity",
  TradeListingStartingTime = "TradeListingStartingTime",
  TradeListingStatus = "TradeListingStatus",
  TradeListingUpdatedAt = "TradeListingUpdatedAt",
}

export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Pagination = {
  __typename?: "Pagination";
  page: Scalars["Int"];
  pageCount: Scalars["Int"];
  pageSize: Scalars["Int"];
  total: Scalars["Int"];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["Int"]>;
};

export type ProcessStatusDto = {
  __typename?: "ProcessStatusDto";
  endTime?: Maybe<Scalars["DateTime"]>;
  exception?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  processName: Scalars["String"];
  reindexCount?: Maybe<Scalars["Float"]>;
  startTime: Scalars["DateTime"];
  status: Scalars["String"];
  totalCount?: Maybe<Scalars["Float"]>;
};

export type PropertyDto = {
  __typename?: "PropertyDto";
  chainId: ChainId;
  contract?: Maybe<Scalars["String"]>;
  dataType?: Maybe<DataType>;
  id?: Maybe<Scalars["String"]>;
  maxValue?: Maybe<Scalars["String"]>;
  minValue?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  provider: NftProvider;
  values?: Maybe<Array<PropertyValueDto>>;
};

export type PropertyValueDto = {
  __typename?: "PropertyValueDto";
  id: Scalars["String"];
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInt?: Maybe<Scalars["Float"]>;
  valueString: Scalars["String"];
};

export type ProviderAggregationDto = {
  __typename?: "ProviderAggregationDto";
  count?: Maybe<Scalars["Float"]>;
  provider?: Maybe<Scalars["String"]>;
};

export enum PublicationState {
  Live = "LIVE",
  Preview = "PREVIEW",
}

export type Query = {
  __typename?: "Query";
  /** Access to subgraph metadata */
  _meta_1?: Maybe<_Meta_>;
  /** Access to subgraph metadata */
  _meta_56?: Maybe<_Meta_>;
  article?: Maybe<ArticleEntityResponse>;
  articles?: Maybe<ArticleEntityResponseCollection>;
  author?: Maybe<AuthorEntityResponse>;
  authors?: Maybe<AuthorEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  chain?: Maybe<ChainEntityResponse>;
  chains?: Maybe<ChainEntityResponseCollection>;
  coinRates: Array<CoinRateDto>;
  ecosystemPartner?: Maybe<EcosystemPartnerEntityResponse>;
  ecosystemPartnerCategories?: Maybe<EcosystemPartnerCategoryEntityResponseCollection>;
  ecosystemPartnerCategory?: Maybe<EcosystemPartnerCategoryEntityResponse>;
  ecosystemPartners?: Maybe<EcosystemPartnerEntityResponseCollection>;
  erc721Contract_1?: Maybe<Erc721Contract>;
  erc721Contract_56?: Maybe<Erc721Contract>;
  erc721Contracts_1: Array<Erc721Contract>;
  erc721Contracts_56: Array<Erc721Contract>;
  erc721Operator_1?: Maybe<Erc721Operator>;
  erc721Operator_56?: Maybe<Erc721Operator>;
  erc721Operators_1: Array<Erc721Operator>;
  erc721Operators_56: Array<Erc721Operator>;
  erc721Token_1?: Maybe<Erc721Token>;
  erc721Token_56?: Maybe<Erc721Token>;
  erc721Tokens_1: Array<Erc721Token>;
  erc721Tokens_56: Array<Erc721Token>;
  erc721Transfer_1?: Maybe<Erc721Transfer>;
  erc721Transfer_56?: Maybe<Erc721Transfer>;
  erc721Transfers_1: Array<Erc721Transfer>;
  erc721Transfers_56: Array<Erc721Transfer>;
  erc1155Balance_56?: Maybe<Erc1155Balance>;
  erc1155Balances_56: Array<Erc1155Balance>;
  erc1155Contract_56?: Maybe<Erc1155Contract>;
  erc1155Contracts_56: Array<Erc1155Contract>;
  erc1155Operator_56?: Maybe<Erc1155Operator>;
  erc1155Operators_56: Array<Erc1155Operator>;
  erc1155Token_56?: Maybe<Erc1155Token>;
  erc1155Tokens_56: Array<Erc1155Token>;
  erc1155Transfer_56?: Maybe<Erc1155Transfer>;
  erc1155Transfers_56: Array<Erc1155Transfer>;
  game?: Maybe<GameEntityResponse>;
  gameCategories?: Maybe<GameCategoryEntityResponseCollection>;
  gameCategory?: Maybe<GameCategoryEntityResponse>;
  gameDevice?: Maybe<GameDeviceEntityResponse>;
  gameDevices?: Maybe<GameDeviceEntityResponseCollection>;
  gameGenre?: Maybe<GameGenreEntityResponse>;
  gameGenres?: Maybe<GameGenreEntityResponseCollection>;
  gameStatus?: Maybe<GameStatusEntityResponse>;
  gameStatuses?: Maybe<GameStatusEntityResponseCollection>;
  games?: Maybe<GameEntityResponseCollection>;
  global?: Maybe<GlobalEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  listing?: Maybe<RentListing>;
  maxSyncBlocks: Array<MaxSyncBlock>;
  me?: Maybe<UsersPermissionsMe>;
  menu?: Maybe<MenuEntityResponse>;
  menus?: Maybe<MenuEntityResponseCollection>;
  nft?: Maybe<NftDto>;
  nftById?: Maybe<NftDto>;
  nftByUniqueId?: Maybe<NftDto>;
  nftFiltered: NftResponseDto;
  processStatus: ProcessStatusDto;
  properties?: Maybe<Array<PropertyDto>>;
  propertyValues?: Maybe<Array<PropertyValueDto>>;
  propertyValuesByName?: Maybe<Array<PropertyValueDto>>;
  propertyValuesFiltered?: Maybe<Array<PropertyValueDto>>;
  rentTransaction_1?: Maybe<RentTransaction>;
  rentTransaction_56?: Maybe<RentTransaction>;
  rentTransactions_1: Array<RentTransaction>;
  rentTransactions_56: Array<RentTransaction>;
  task?: Maybe<TaskEntityResponse>;
  tasks?: Maybe<TaskEntityResponseCollection>;
  tradeBundleMaxSyncBlocks: Array<MaxSyncBlock>;
  tradeBundleOffer_1?: Maybe<TradeBundleOffer>;
  tradeBundleOffer_56?: Maybe<TradeBundleOffer>;
  tradeBundleOffers_1: Array<TradeBundleOffer>;
  tradeBundleOffers_56: Array<TradeBundleOffer>;
  tradeBundleTransaction_1?: Maybe<TradeBundleTransaction>;
  tradeBundleTransaction_56?: Maybe<TradeBundleTransaction>;
  tradeBundleTransactions_1: Array<TradeBundleTransaction>;
  tradeBundleTransactions_56: Array<TradeBundleTransaction>;
  tradeCompetition?: Maybe<TradeCompetitionEntityResponse>;
  tradeCompetitionStatus: CompetitionResultDto;
  tradeCompetitions?: Maybe<TradeCompetitionEntityResponseCollection>;
  tradeListing?: Maybe<TradeListing>;
  tradeMaxSyncBlocks: Array<MaxSyncBlock>;
  tradeOffer_1?: Maybe<TradeOffer>;
  tradeOffer_56?: Maybe<TradeOffer>;
  tradeOffers_1: Array<TradeOffer>;
  tradeOffers_56: Array<TradeOffer>;
  tradeTransaction_1?: Maybe<TradeTransaction>;
  tradeTransaction_56?: Maybe<TradeTransaction>;
  tradeTransactions_1: Array<TradeTransaction>;
  tradeTransactions_56: Array<TradeTransaction>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  vaultOwner_1?: Maybe<VaultOwner>;
  vaultOwner_56?: Maybe<VaultOwner>;
  vaultOwners_1: Array<VaultOwner>;
  vaultOwners_56: Array<VaultOwner>;
  vault_1?: Maybe<Vault>;
  vault_56?: Maybe<Vault>;
  vaults_1: Array<Vault>;
  vaults_56: Array<Vault>;
};

export type Query_Meta_1Args = {
  block?: InputMaybe<Block_Height>;
};

export type Query_Meta_56Args = {
  block?: InputMaybe<Block_Height>;
};

export type QueryArticleArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryAuthorArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryAuthorsArgs = {
  filters?: InputMaybe<AuthorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryChainArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryChainsArgs = {
  filters?: InputMaybe<ChainFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryEcosystemPartnerArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryEcosystemPartnerCategoriesArgs = {
  filters?: InputMaybe<EcosystemPartnerCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryEcosystemPartnerCategoryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryEcosystemPartnersArgs = {
  filters?: InputMaybe<EcosystemPartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryErc721Contract_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Contract_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Contracts_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type QueryErc721Contracts_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type QueryErc721Operator_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Operator_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Operators_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type QueryErc721Operators_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type QueryErc721Token_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Token_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Tokens_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type QueryErc721Tokens_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type QueryErc721Transfer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Transfer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc721Transfers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type QueryErc721Transfers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type QueryErc1155Balance_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc1155Balances_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Balance_Filter>;
};

export type QueryErc1155Contract_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc1155Contracts_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Contract_Filter>;
};

export type QueryErc1155Operator_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc1155Operators_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Operator_Filter>;
};

export type QueryErc1155Token_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc1155Tokens_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Token_Filter>;
};

export type QueryErc1155Transfer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryErc1155Transfers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type QueryGameArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGameCategoriesArgs = {
  filters?: InputMaybe<GameCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGameCategoryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGameDeviceArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGameDevicesArgs = {
  filters?: InputMaybe<GameDeviceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGameGenreArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGameGenresArgs = {
  filters?: InputMaybe<GameGenreFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGameStatusArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGameStatusesArgs = {
  filters?: InputMaybe<GameStatusFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGamesArgs = {
  filters?: InputMaybe<GameFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryListingArgs = {
  id: Scalars["String"];
};

export type QueryMenuArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryMenusArgs = {
  filters?: InputMaybe<MenuFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryNftArgs = {
  id: Scalars["String"];
};

export type QueryNftByIdArgs = {
  chainId?: InputMaybe<ChainId>;
  id: Scalars["String"];
};

export type QueryNftByUniqueIdArgs = {
  id: Scalars["String"];
};

export type QueryNftFilteredArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  onlyRentListings?: InputMaybe<Scalars["Boolean"]>;
  onlyTradeListings?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  requestId?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Nft_Filter>;
};

export type QueryProcessStatusArgs = {
  processUuid: Scalars["String"];
};

export type QueryPropertiesArgs = {
  chainId?: InputMaybe<Scalars["Float"]>;
  contract?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Float"]>;
  provider: Scalars["String"];
};

export type QueryPropertyValuesArgs = {
  first?: InputMaybe<Scalars["Float"]>;
  propertyId: Scalars["String"];
  skip?: InputMaybe<Scalars["Float"]>;
};

export type QueryPropertyValuesByNameArgs = {
  first?: InputMaybe<Scalars["Float"]>;
  propertyName: Scalars["String"];
  provider: Scalars["String"];
  skip?: InputMaybe<Scalars["Float"]>;
};

export type QueryPropertyValuesFilteredArgs = {
  first?: InputMaybe<Scalars["Float"]>;
  input: Scalars["String"];
  propertyId: Scalars["String"];
  skip?: InputMaybe<Scalars["Float"]>;
};

export type QueryRentTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryRentTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryRentTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RentTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentTransaction_Filter>;
};

export type QueryRentTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RentTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentTransaction_Filter>;
};

export type QueryTaskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryTasksArgs = {
  filters?: InputMaybe<TaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryTradeBundleOffer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeBundleOffer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeBundleOffers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleOffer_Filter>;
};

export type QueryTradeBundleOffers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleOffer_Filter>;
};

export type QueryTradeBundleTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeBundleTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeBundleTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleTransaction_Filter>;
};

export type QueryTradeBundleTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleTransaction_Filter>;
};

export type QueryTradeCompetitionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryTradeCompetitionStatusArgs = {
  amountOfTopResults?: InputMaybe<Scalars["Int"]>;
  chain: Scalars["Int"];
  endTimestamp?: InputMaybe<Scalars["DateTime"]>;
  provider: NftProvider;
  startTimestamp: Scalars["DateTime"];
  userAddress: Scalars["String"];
};

export type QueryTradeCompetitionsArgs = {
  filters?: InputMaybe<TradeCompetitionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryTradeListingArgs = {
  id: Scalars["String"];
};

export type QueryTradeOffer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeOffer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeOffers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeOffer_Filter>;
};

export type QueryTradeOffers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeOffer_Filter>;
};

export type QueryTradeTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryTradeTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeTransaction_Filter>;
};

export type QueryTradeTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeTransaction_Filter>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryVaultOwner_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaultOwner_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaultOwners_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<VaultOwner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultOwner_Filter>;
};

export type QueryVaultOwners_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<VaultOwner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultOwner_Filter>;
};

export type QueryVault_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVault_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type QueryVaults_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

export type QueryVaults_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

export type RentListing = {
  __typename?: "RentListing";
  autoReturnNft: Scalars["Boolean"];
  borrower?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  contract: Scalars["String"];
  createdAt: Scalars["Float"];
  finalDuration: Scalars["Float"];
  finalPaymentValue: Scalars["String"];
  id: Scalars["String"];
  lastChangedDateTime: Scalars["DateTime"];
  maxDuration: Scalars["Float"];
  metadata?: Maybe<NftDto>;
  minDuration: Scalars["Float"];
  nftAddress: Scalars["String"];
  nonce: Scalars["String"];
  owner: Scalars["String"];
  paymentToken: Scalars["String"];
  paymentTokenName: Scalars["String"];
  paymentValueUsd: Scalars["Float"];
  pricePerSecond: Scalars["String"];
  provider: Scalars["String"];
  published: Scalars["Boolean"];
  purchaser?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  syncBlock: Scalars["Float"];
  tokenId: Scalars["String"];
  tokenType: Scalars["String"];
  transactions: Array<RentTransaction>;
  updatedAt: Scalars["Float"];
};

export type RentListingTransactionsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RentTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RentTransaction_Filter>;
};

export type RentListingDto = {
  __typename?: "RentListingDto";
  autoReturnNft?: Maybe<Scalars["Boolean"]>;
  batchCount?: Maybe<Scalars["Float"]>;
  batchId?: Maybe<Scalars["String"]>;
  borrowedAt?: Maybe<Scalars["Float"]>;
  borrower?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  contract: Scalars["String"];
  createDateTime?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["Float"]>;
  finalDuration?: Maybe<Scalars["Float"]>;
  finalPaymentValue?: Maybe<Scalars["String"]>;
  finalPaymentValueUsd?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastChangedDateTime?: Maybe<Scalars["DateTime"]>;
  maxDuration?: Maybe<Scalars["Float"]>;
  minDuration?: Maybe<Scalars["Float"]>;
  nftAddress: Scalars["String"];
  nonce: Scalars["String"];
  owner: Scalars["String"];
  paymentToken: Scalars["String"];
  paymentTokenName?: Maybe<Scalars["String"]>;
  pricePerSecond?: Maybe<Scalars["String"]>;
  pricePerSecondUsd?: Maybe<Scalars["String"]>;
  provider: Scalars["String"];
  published?: Maybe<Scalars["Boolean"]>;
  purchaser?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  syncBlock: Scalars["Float"];
  tokenId: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export type RentListing_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RentListing_Filter>>>;
  borrower?: InputMaybe<Scalars["Bytes"]>;
  borrower_contains?: InputMaybe<Scalars["Bytes"]>;
  borrower_gt?: InputMaybe<Scalars["Bytes"]>;
  borrower_gte?: InputMaybe<Scalars["Bytes"]>;
  borrower_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  borrower_lt?: InputMaybe<Scalars["Bytes"]>;
  borrower_lte?: InputMaybe<Scalars["Bytes"]>;
  borrower_not?: InputMaybe<Scalars["Bytes"]>;
  borrower_not_contains?: InputMaybe<Scalars["Bytes"]>;
  borrower_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  contract?: InputMaybe<Scalars["Bytes"]>;
  contract_contains?: InputMaybe<Scalars["Bytes"]>;
  contract_gt?: InputMaybe<Scalars["Bytes"]>;
  contract_gte?: InputMaybe<Scalars["Bytes"]>;
  contract_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  contract_lt?: InputMaybe<Scalars["Bytes"]>;
  contract_lte?: InputMaybe<Scalars["Bytes"]>;
  contract_not?: InputMaybe<Scalars["Bytes"]>;
  contract_not_contains?: InputMaybe<Scalars["Bytes"]>;
  contract_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  createdAt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  createdAt_lt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_lte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalDuration?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_gt?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_gte?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalDuration_lt?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_lte?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_not?: InputMaybe<Scalars["BigInt"]>;
  finalDuration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalPaymentValue?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_gt?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_gte?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalPaymentValue_lt?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_lte?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_not?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  maxDuration?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_gt?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_gte?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  maxDuration_lt?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_lte?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_not?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  minDuration?: InputMaybe<Scalars["BigInt"]>;
  minDuration_gt?: InputMaybe<Scalars["BigInt"]>;
  minDuration_gte?: InputMaybe<Scalars["BigInt"]>;
  minDuration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  minDuration_lt?: InputMaybe<Scalars["BigInt"]>;
  minDuration_lte?: InputMaybe<Scalars["BigInt"]>;
  minDuration_not?: InputMaybe<Scalars["BigInt"]>;
  minDuration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  nftAddress?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  nftAddress_lt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_lte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  nonce?: InputMaybe<Scalars["BigInt"]>;
  nonce_gt?: InputMaybe<Scalars["BigInt"]>;
  nonce_gte?: InputMaybe<Scalars["BigInt"]>;
  nonce_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  nonce_lt?: InputMaybe<Scalars["BigInt"]>;
  nonce_lte?: InputMaybe<Scalars["BigInt"]>;
  nonce_not?: InputMaybe<Scalars["BigInt"]>;
  nonce_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  or?: InputMaybe<Array<InputMaybe<RentListing_Filter>>>;
  owner?: InputMaybe<Scalars["Bytes"]>;
  owner_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_gt?: InputMaybe<Scalars["Bytes"]>;
  owner_gte?: InputMaybe<Scalars["Bytes"]>;
  owner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  owner_lt?: InputMaybe<Scalars["Bytes"]>;
  owner_lte?: InputMaybe<Scalars["Bytes"]>;
  owner_not?: InputMaybe<Scalars["Bytes"]>;
  owner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  pricePerSecond?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_gt?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_gte?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  pricePerSecond_lt?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_lte?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_not?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  published?: InputMaybe<Scalars["Boolean"]>;
  published_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  published_not?: InputMaybe<Scalars["Boolean"]>;
  published_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  purchaser?: InputMaybe<Scalars["Bytes"]>;
  purchaser_contains?: InputMaybe<Scalars["Bytes"]>;
  purchaser_gt?: InputMaybe<Scalars["Bytes"]>;
  purchaser_gte?: InputMaybe<Scalars["Bytes"]>;
  purchaser_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  purchaser_lt?: InputMaybe<Scalars["Bytes"]>;
  purchaser_lte?: InputMaybe<Scalars["Bytes"]>;
  purchaser_not?: InputMaybe<Scalars["Bytes"]>;
  purchaser_not_contains?: InputMaybe<Scalars["Bytes"]>;
  purchaser_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  status?: InputMaybe<ListingStatus>;
  status_in?: InputMaybe<Array<ListingStatus>>;
  status_not?: InputMaybe<ListingStatus>;
  status_not_in?: InputMaybe<Array<ListingStatus>>;
  tokenId?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenId_lt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_lte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenType?: InputMaybe<TokenType>;
  tokenType_in?: InputMaybe<Array<TokenType>>;
  tokenType_not?: InputMaybe<TokenType>;
  tokenType_not_in?: InputMaybe<Array<TokenType>>;
  transactions_?: InputMaybe<RentTransaction_Filter>;
  updatedAt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_gt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_gte?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  updatedAt_lt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_lte?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_not?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type RentTransaction = {
  __typename?: "RentTransaction";
  finalDurationInSec?: Maybe<Scalars["BigInt"]>;
  finalPaymentValue?: Maybe<Scalars["BigInt"]>;
  from: Scalars["Bytes"];
  hash: Scalars["Bytes"];
  id: Scalars["ID"];
  listing: RentListing;
  maxDuration?: Maybe<Scalars["BigInt"]>;
  minDuration?: Maybe<Scalars["BigInt"]>;
  paymentToken?: Maybe<Scalars["Bytes"]>;
  pricePerSecond?: Maybe<Scalars["BigInt"]>;
  timestamp: Scalars["BigInt"];
  to?: Maybe<Scalars["Bytes"]>;
  type: RentTransactionType;
};

export enum RentTransactionType {
  Borrow = "Borrow",
  Cancel = "Cancel",
  List = "List",
  Return = "Return",
  Update = "Update",
}

export type RentTransaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RentTransaction_Filter>>>;
  finalDurationInSec?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_gt?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_gte?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalDurationInSec_lt?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_lte?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_not?: InputMaybe<Scalars["BigInt"]>;
  finalDurationInSec_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalPaymentValue?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_gt?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_gte?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  finalPaymentValue_lt?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_lte?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_not?: InputMaybe<Scalars["BigInt"]>;
  finalPaymentValue_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  from?: InputMaybe<Scalars["Bytes"]>;
  from_contains?: InputMaybe<Scalars["Bytes"]>;
  from_gt?: InputMaybe<Scalars["Bytes"]>;
  from_gte?: InputMaybe<Scalars["Bytes"]>;
  from_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from_lt?: InputMaybe<Scalars["Bytes"]>;
  from_lte?: InputMaybe<Scalars["Bytes"]>;
  from_not?: InputMaybe<Scalars["Bytes"]>;
  from_not_contains?: InputMaybe<Scalars["Bytes"]>;
  from_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash?: InputMaybe<Scalars["Bytes"]>;
  hash_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_gt?: InputMaybe<Scalars["Bytes"]>;
  hash_gte?: InputMaybe<Scalars["Bytes"]>;
  hash_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash_lt?: InputMaybe<Scalars["Bytes"]>;
  hash_lte?: InputMaybe<Scalars["Bytes"]>;
  hash_not?: InputMaybe<Scalars["Bytes"]>;
  hash_not_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  listing?: InputMaybe<Scalars["String"]>;
  listing_?: InputMaybe<RentListing_Filter>;
  listing_contains?: InputMaybe<Scalars["String"]>;
  listing_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_ends_with?: InputMaybe<Scalars["String"]>;
  listing_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_gt?: InputMaybe<Scalars["String"]>;
  listing_gte?: InputMaybe<Scalars["String"]>;
  listing_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_lt?: InputMaybe<Scalars["String"]>;
  listing_lte?: InputMaybe<Scalars["String"]>;
  listing_not?: InputMaybe<Scalars["String"]>;
  listing_not_contains?: InputMaybe<Scalars["String"]>;
  listing_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_not_starts_with?: InputMaybe<Scalars["String"]>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_starts_with?: InputMaybe<Scalars["String"]>;
  listing_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  maxDuration?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_gt?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_gte?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  maxDuration_lt?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_lte?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_not?: InputMaybe<Scalars["BigInt"]>;
  maxDuration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  minDuration?: InputMaybe<Scalars["BigInt"]>;
  minDuration_gt?: InputMaybe<Scalars["BigInt"]>;
  minDuration_gte?: InputMaybe<Scalars["BigInt"]>;
  minDuration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  minDuration_lt?: InputMaybe<Scalars["BigInt"]>;
  minDuration_lte?: InputMaybe<Scalars["BigInt"]>;
  minDuration_not?: InputMaybe<Scalars["BigInt"]>;
  minDuration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  or?: InputMaybe<Array<InputMaybe<RentTransaction_Filter>>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  pricePerSecond?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_gt?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_gte?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  pricePerSecond_lt?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_lte?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_not?: InputMaybe<Scalars["BigInt"]>;
  pricePerSecond_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["Bytes"]>;
  to_contains?: InputMaybe<Scalars["Bytes"]>;
  to_gt?: InputMaybe<Scalars["Bytes"]>;
  to_gte?: InputMaybe<Scalars["Bytes"]>;
  to_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  to_lt?: InputMaybe<Scalars["Bytes"]>;
  to_lte?: InputMaybe<Scalars["Bytes"]>;
  to_not?: InputMaybe<Scalars["Bytes"]>;
  to_not_contains?: InputMaybe<Scalars["Bytes"]>;
  to_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  type?: InputMaybe<RentTransactionType>;
  type_in?: InputMaybe<Array<RentTransactionType>>;
  type_not?: InputMaybe<RentTransactionType>;
  type_not_in?: InputMaybe<Array<RentTransactionType>>;
};

export enum RentTransaction_OrderBy {
  FinalDurationInSec = "finalDurationInSec",
  FinalPaymentValue = "finalPaymentValue",
  From = "from",
  Hash = "hash",
  Id = "id",
  Listing = "listing",
  ListingBorrower = "listing__borrower",
  ListingContract = "listing__contract",
  ListingCreatedAt = "listing__createdAt",
  ListingFinalDuration = "listing__finalDuration",
  ListingFinalPaymentValue = "listing__finalPaymentValue",
  ListingId = "listing__id",
  ListingMaxDuration = "listing__maxDuration",
  ListingMinDuration = "listing__minDuration",
  ListingNftAddress = "listing__nftAddress",
  ListingNonce = "listing__nonce",
  ListingOwner = "listing__owner",
  ListingPaymentToken = "listing__paymentToken",
  ListingPricePerSecond = "listing__pricePerSecond",
  ListingPublished = "listing__published",
  ListingPurchaser = "listing__purchaser",
  ListingStatus = "listing__status",
  ListingTokenId = "listing__tokenId",
  ListingTokenType = "listing__tokenType",
  ListingUpdatedAt = "listing__updatedAt",
  MaxDuration = "maxDuration",
  MinDuration = "minDuration",
  PaymentToken = "paymentToken",
  PricePerSecond = "pricePerSecond",
  Timestamp = "timestamp",
  To = "to",
  Type = "type",
}

export type ResponseCollectionMeta = {
  __typename?: "ResponseCollectionMeta";
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contains?: InputMaybe<Scalars["String"]>;
  containsi?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  eqi?: InputMaybe<Scalars["String"]>;
  gt?: InputMaybe<Scalars["String"]>;
  gte?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lt?: InputMaybe<Scalars["String"]>;
  lte?: InputMaybe<Scalars["String"]>;
  ne?: InputMaybe<Scalars["String"]>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars["String"]>;
  notContainsi?: InputMaybe<Scalars["String"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  notNull?: InputMaybe<Scalars["Boolean"]>;
  null?: InputMaybe<Scalars["Boolean"]>;
  or?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  /** Access to subgraph metadata */
  _meta_1?: Maybe<_Meta_>;
  /** Access to subgraph metadata */
  _meta_56?: Maybe<_Meta_>;
  erc721Contract_1?: Maybe<Erc721Contract>;
  erc721Contract_56?: Maybe<Erc721Contract>;
  erc721Contracts_1: Array<Erc721Contract>;
  erc721Contracts_56: Array<Erc721Contract>;
  erc721Operator_1?: Maybe<Erc721Operator>;
  erc721Operator_56?: Maybe<Erc721Operator>;
  erc721Operators_1: Array<Erc721Operator>;
  erc721Operators_56: Array<Erc721Operator>;
  erc721Token_1?: Maybe<Erc721Token>;
  erc721Token_56?: Maybe<Erc721Token>;
  erc721Tokens_1: Array<Erc721Token>;
  erc721Tokens_56: Array<Erc721Token>;
  erc721Transfer_1?: Maybe<Erc721Transfer>;
  erc721Transfer_56?: Maybe<Erc721Transfer>;
  erc721Transfers_1: Array<Erc721Transfer>;
  erc721Transfers_56: Array<Erc721Transfer>;
  erc1155Balance_56?: Maybe<Erc1155Balance>;
  erc1155Balances_56: Array<Erc1155Balance>;
  erc1155Contract_56?: Maybe<Erc1155Contract>;
  erc1155Contracts_56: Array<Erc1155Contract>;
  erc1155Operator_56?: Maybe<Erc1155Operator>;
  erc1155Operators_56: Array<Erc1155Operator>;
  erc1155Token_56?: Maybe<Erc1155Token>;
  erc1155Tokens_56: Array<Erc1155Token>;
  erc1155Transfer_56?: Maybe<Erc1155Transfer>;
  erc1155Transfers_56: Array<Erc1155Transfer>;
  rentTransaction_1?: Maybe<RentTransaction>;
  rentTransaction_56?: Maybe<RentTransaction>;
  rentTransactions_1: Array<RentTransaction>;
  rentTransactions_56: Array<RentTransaction>;
  tradeBundleOffer_1?: Maybe<TradeBundleOffer>;
  tradeBundleOffer_56?: Maybe<TradeBundleOffer>;
  tradeBundleOffers_1: Array<TradeBundleOffer>;
  tradeBundleOffers_56: Array<TradeBundleOffer>;
  tradeBundleTransaction_1?: Maybe<TradeBundleTransaction>;
  tradeBundleTransaction_56?: Maybe<TradeBundleTransaction>;
  tradeBundleTransactions_1: Array<TradeBundleTransaction>;
  tradeBundleTransactions_56: Array<TradeBundleTransaction>;
  tradeOffer_1?: Maybe<TradeOffer>;
  tradeOffer_56?: Maybe<TradeOffer>;
  tradeOffers_1: Array<TradeOffer>;
  tradeOffers_56: Array<TradeOffer>;
  tradeTransaction_1?: Maybe<TradeTransaction>;
  tradeTransaction_56?: Maybe<TradeTransaction>;
  tradeTransactions_1: Array<TradeTransaction>;
  tradeTransactions_56: Array<TradeTransaction>;
  vaultOwner_1?: Maybe<VaultOwner>;
  vaultOwner_56?: Maybe<VaultOwner>;
  vaultOwners_1: Array<VaultOwner>;
  vaultOwners_56: Array<VaultOwner>;
  vault_1?: Maybe<Vault>;
  vault_56?: Maybe<Vault>;
  vaults_1: Array<Vault>;
  vaults_56: Array<Vault>;
};

export type Subscription_Meta_1Args = {
  block?: InputMaybe<Block_Height>;
};

export type Subscription_Meta_56Args = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionErc721Contract_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Contract_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Contracts_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type SubscriptionErc721Contracts_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type SubscriptionErc721Operator_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Operator_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Operators_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type SubscriptionErc721Operators_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type SubscriptionErc721Token_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Token_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Tokens_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type SubscriptionErc721Tokens_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type SubscriptionErc721Transfer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Transfer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc721Transfers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type SubscriptionErc721Transfers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type SubscriptionErc1155Balance_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc1155Balances_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Balance_Filter>;
};

export type SubscriptionErc1155Contract_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc1155Contracts_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Contract_Filter>;
};

export type SubscriptionErc1155Operator_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc1155Operators_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Operator_Filter>;
};

export type SubscriptionErc1155Token_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc1155Tokens_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Token_Filter>;
};

export type SubscriptionErc1155Transfer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionErc1155Transfers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc1155Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Erc1155Transfer_Filter>;
};

export type SubscriptionRentTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionRentTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionRentTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RentTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentTransaction_Filter>;
};

export type SubscriptionRentTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<RentTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<RentTransaction_Filter>;
};

export type SubscriptionTradeBundleOffer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeBundleOffer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeBundleOffers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleOffer_Filter>;
};

export type SubscriptionTradeBundleOffers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleOffer_Filter>;
};

export type SubscriptionTradeBundleTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeBundleTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeBundleTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleTransaction_Filter>;
};

export type SubscriptionTradeBundleTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeBundleTransaction_Filter>;
};

export type SubscriptionTradeOffer_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeOffer_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeOffers_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeOffer_Filter>;
};

export type SubscriptionTradeOffers_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeOffer_Filter>;
};

export type SubscriptionTradeTransaction_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeTransaction_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionTradeTransactions_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeTransaction_Filter>;
};

export type SubscriptionTradeTransactions_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<TradeTransaction_Filter>;
};

export type SubscriptionVaultOwner_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaultOwner_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaultOwners_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<VaultOwner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultOwner_Filter>;
};

export type SubscriptionVaultOwners_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<VaultOwner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<VaultOwner_Filter>;
};

export type SubscriptionVault_1Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVault_56Args = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
  subgraphError?: _SubgraphErrorPolicy_;
};

export type SubscriptionVaults_1Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

export type SubscriptionVaults_56Args = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Vault_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  subgraphError?: _SubgraphErrorPolicy_;
  where?: InputMaybe<Vault_Filter>;
};

export type Task = {
  __typename?: "Task";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  endTimestamp?: Maybe<Scalars["DateTime"]>;
  maxValue?: Maybe<Scalars["Float"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  rewardContract?: Maybe<Scalars["String"]>;
  rewardToken?: Maybe<Scalars["String"]>;
  rewardValue?: Maybe<Scalars["String"]>;
  startTimestamp: Scalars["DateTime"];
  taskId: Scalars["String"];
  title: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TaskEntity = {
  __typename?: "TaskEntity";
  attributes?: Maybe<Task>;
  id?: Maybe<Scalars["ID"]>;
};

export type TaskEntityResponse = {
  __typename?: "TaskEntityResponse";
  data?: Maybe<TaskEntity>;
};

export type TaskEntityResponseCollection = {
  __typename?: "TaskEntityResponseCollection";
  data: Array<TaskEntity>;
  meta: ResponseCollectionMeta;
};

export type TaskFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TaskFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  endTimestamp?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  maxValue?: InputMaybe<FloatFilterInput>;
  not?: InputMaybe<TaskFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TaskFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rewardContract?: InputMaybe<StringFilterInput>;
  rewardToken?: InputMaybe<StringFilterInput>;
  rewardValue?: InputMaybe<StringFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  startTimestamp?: InputMaybe<DateTimeFilterInput>;
  taskId?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TaskInput = {
  description?: InputMaybe<Scalars["String"]>;
  endTimestamp?: InputMaybe<Scalars["DateTime"]>;
  maxValue?: InputMaybe<Scalars["Float"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  rewardContract?: InputMaybe<Scalars["String"]>;
  rewardToken?: InputMaybe<Scalars["String"]>;
  rewardValue?: InputMaybe<Scalars["String"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  startTimestamp?: InputMaybe<Scalars["DateTime"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export enum TokenType {
  Erc721 = "ERC721",
  Erc1155 = "ERC1155",
}

export type TradeBundleItemDto = {
  __typename?: "TradeBundleItemDto";
  id: Scalars["String"];
  nftAddress: Scalars["String"];
  quantity: Scalars["Float"];
  tokenId: Scalars["String"];
};

export type TradeBundleListing = {
  __typename?: "TradeBundleListing";
  createdAt: Scalars["BigInt"];
  endTime: Scalars["BigInt"];
  id: Scalars["ID"];
  items: Array<NftItem>;
  offers?: Maybe<Array<TradeBundleOffer>>;
  owner: Scalars["Bytes"];
  paymentToken: Scalars["Bytes"];
  price: Scalars["BigInt"];
  published: Scalars["Boolean"];
  startingTime: Scalars["BigInt"];
  status: ListingStatus;
  transactions: Array<TradeBundleTransaction>;
  updatedAt: Scalars["BigInt"];
};

export type TradeBundleListingItemsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<NftItem_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<NftItem_Filter>;
};

export type TradeBundleListingOffersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleOffer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TradeBundleOffer_Filter>;
};

export type TradeBundleListingTransactionsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TradeBundleTransaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TradeBundleTransaction_Filter>;
};

export type TradeBundleListing_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TradeBundleListing_Filter>>>;
  createdAt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  createdAt_lt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_lte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  endTime?: InputMaybe<Scalars["BigInt"]>;
  endTime_gt?: InputMaybe<Scalars["BigInt"]>;
  endTime_gte?: InputMaybe<Scalars["BigInt"]>;
  endTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  endTime_lt?: InputMaybe<Scalars["BigInt"]>;
  endTime_lte?: InputMaybe<Scalars["BigInt"]>;
  endTime_not?: InputMaybe<Scalars["BigInt"]>;
  endTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  items_?: InputMaybe<NftItem_Filter>;
  offers_?: InputMaybe<TradeBundleOffer_Filter>;
  or?: InputMaybe<Array<InputMaybe<TradeBundleListing_Filter>>>;
  owner?: InputMaybe<Scalars["Bytes"]>;
  owner_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_gt?: InputMaybe<Scalars["Bytes"]>;
  owner_gte?: InputMaybe<Scalars["Bytes"]>;
  owner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  owner_lt?: InputMaybe<Scalars["Bytes"]>;
  owner_lte?: InputMaybe<Scalars["Bytes"]>;
  owner_not?: InputMaybe<Scalars["Bytes"]>;
  owner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  price?: InputMaybe<Scalars["BigInt"]>;
  price_gt?: InputMaybe<Scalars["BigInt"]>;
  price_gte?: InputMaybe<Scalars["BigInt"]>;
  price_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  price_lt?: InputMaybe<Scalars["BigInt"]>;
  price_lte?: InputMaybe<Scalars["BigInt"]>;
  price_not?: InputMaybe<Scalars["BigInt"]>;
  price_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  published?: InputMaybe<Scalars["Boolean"]>;
  published_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  published_not?: InputMaybe<Scalars["Boolean"]>;
  published_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  startingTime?: InputMaybe<Scalars["BigInt"]>;
  startingTime_gt?: InputMaybe<Scalars["BigInt"]>;
  startingTime_gte?: InputMaybe<Scalars["BigInt"]>;
  startingTime_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  startingTime_lt?: InputMaybe<Scalars["BigInt"]>;
  startingTime_lte?: InputMaybe<Scalars["BigInt"]>;
  startingTime_not?: InputMaybe<Scalars["BigInt"]>;
  startingTime_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  status?: InputMaybe<ListingStatus>;
  status_in?: InputMaybe<Array<ListingStatus>>;
  status_not?: InputMaybe<ListingStatus>;
  status_not_in?: InputMaybe<Array<ListingStatus>>;
  transactions_?: InputMaybe<TradeBundleTransaction_Filter>;
  updatedAt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_gt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_gte?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  updatedAt_lt?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_lte?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_not?: InputMaybe<Scalars["BigInt"]>;
  updatedAt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type TradeBundleOffer = {
  __typename?: "TradeBundleOffer";
  deadline: Scalars["BigInt"];
  id: Scalars["ID"];
  listing: TradeBundleListing;
  owner: Scalars["Bytes"];
  paymentToken: Scalars["Bytes"];
  price: Scalars["BigInt"];
  status: TradeOfferStatus;
};

export type TradeBundleOfferDto = {
  __typename?: "TradeBundleOfferDto";
  deadline: Scalars["String"];
  id: Scalars["String"];
  owner: Scalars["String"];
  paymentToken: Scalars["String"];
  price: Scalars["String"];
  status: Scalars["String"];
};

export type TradeBundleOffer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TradeBundleOffer_Filter>>>;
  deadline?: InputMaybe<Scalars["BigInt"]>;
  deadline_gt?: InputMaybe<Scalars["BigInt"]>;
  deadline_gte?: InputMaybe<Scalars["BigInt"]>;
  deadline_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  deadline_lt?: InputMaybe<Scalars["BigInt"]>;
  deadline_lte?: InputMaybe<Scalars["BigInt"]>;
  deadline_not?: InputMaybe<Scalars["BigInt"]>;
  deadline_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  listing?: InputMaybe<Scalars["String"]>;
  listing_?: InputMaybe<TradeBundleListing_Filter>;
  listing_contains?: InputMaybe<Scalars["String"]>;
  listing_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_ends_with?: InputMaybe<Scalars["String"]>;
  listing_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_gt?: InputMaybe<Scalars["String"]>;
  listing_gte?: InputMaybe<Scalars["String"]>;
  listing_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_lt?: InputMaybe<Scalars["String"]>;
  listing_lte?: InputMaybe<Scalars["String"]>;
  listing_not?: InputMaybe<Scalars["String"]>;
  listing_not_contains?: InputMaybe<Scalars["String"]>;
  listing_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_not_starts_with?: InputMaybe<Scalars["String"]>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_starts_with?: InputMaybe<Scalars["String"]>;
  listing_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<InputMaybe<TradeBundleOffer_Filter>>>;
  owner?: InputMaybe<Scalars["Bytes"]>;
  owner_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_gt?: InputMaybe<Scalars["Bytes"]>;
  owner_gte?: InputMaybe<Scalars["Bytes"]>;
  owner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  owner_lt?: InputMaybe<Scalars["Bytes"]>;
  owner_lte?: InputMaybe<Scalars["Bytes"]>;
  owner_not?: InputMaybe<Scalars["Bytes"]>;
  owner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  price?: InputMaybe<Scalars["BigInt"]>;
  price_gt?: InputMaybe<Scalars["BigInt"]>;
  price_gte?: InputMaybe<Scalars["BigInt"]>;
  price_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  price_lt?: InputMaybe<Scalars["BigInt"]>;
  price_lte?: InputMaybe<Scalars["BigInt"]>;
  price_not?: InputMaybe<Scalars["BigInt"]>;
  price_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  status?: InputMaybe<TradeOfferStatus>;
  status_in?: InputMaybe<Array<TradeOfferStatus>>;
  status_not?: InputMaybe<TradeOfferStatus>;
  status_not_in?: InputMaybe<Array<TradeOfferStatus>>;
};

export enum TradeBundleOffer_OrderBy {
  Deadline = "deadline",
  Id = "id",
  Listing = "listing",
  ListingCreatedAt = "listing__createdAt",
  ListingEndTime = "listing__endTime",
  ListingId = "listing__id",
  ListingOwner = "listing__owner",
  ListingPaymentToken = "listing__paymentToken",
  ListingPrice = "listing__price",
  ListingPublished = "listing__published",
  ListingStartingTime = "listing__startingTime",
  ListingStatus = "listing__status",
  ListingUpdatedAt = "listing__updatedAt",
  Owner = "owner",
  PaymentToken = "paymentToken",
  Price = "price",
  Status = "status",
}

export type TradeBundleTransaction = {
  __typename?: "TradeBundleTransaction";
  buyer?: Maybe<Scalars["Bytes"]>;
  from: Scalars["Bytes"];
  hash: Scalars["Bytes"];
  id: Scalars["ID"];
  listing: TradeBundleListing;
  paymentToken?: Maybe<Scalars["Bytes"]>;
  price?: Maybe<Scalars["BigInt"]>;
  seller?: Maybe<Scalars["Bytes"]>;
  timestamp: Scalars["BigInt"];
  to?: Maybe<Scalars["Bytes"]>;
  type: TradeTransactionType;
};

export type TradeBundleTransaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TradeBundleTransaction_Filter>>>;
  buyer?: InputMaybe<Scalars["Bytes"]>;
  buyer_contains?: InputMaybe<Scalars["Bytes"]>;
  buyer_gt?: InputMaybe<Scalars["Bytes"]>;
  buyer_gte?: InputMaybe<Scalars["Bytes"]>;
  buyer_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  buyer_lt?: InputMaybe<Scalars["Bytes"]>;
  buyer_lte?: InputMaybe<Scalars["Bytes"]>;
  buyer_not?: InputMaybe<Scalars["Bytes"]>;
  buyer_not_contains?: InputMaybe<Scalars["Bytes"]>;
  buyer_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from?: InputMaybe<Scalars["Bytes"]>;
  from_contains?: InputMaybe<Scalars["Bytes"]>;
  from_gt?: InputMaybe<Scalars["Bytes"]>;
  from_gte?: InputMaybe<Scalars["Bytes"]>;
  from_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from_lt?: InputMaybe<Scalars["Bytes"]>;
  from_lte?: InputMaybe<Scalars["Bytes"]>;
  from_not?: InputMaybe<Scalars["Bytes"]>;
  from_not_contains?: InputMaybe<Scalars["Bytes"]>;
  from_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash?: InputMaybe<Scalars["Bytes"]>;
  hash_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_gt?: InputMaybe<Scalars["Bytes"]>;
  hash_gte?: InputMaybe<Scalars["Bytes"]>;
  hash_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash_lt?: InputMaybe<Scalars["Bytes"]>;
  hash_lte?: InputMaybe<Scalars["Bytes"]>;
  hash_not?: InputMaybe<Scalars["Bytes"]>;
  hash_not_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  listing?: InputMaybe<Scalars["String"]>;
  listing_?: InputMaybe<TradeBundleListing_Filter>;
  listing_contains?: InputMaybe<Scalars["String"]>;
  listing_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_ends_with?: InputMaybe<Scalars["String"]>;
  listing_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_gt?: InputMaybe<Scalars["String"]>;
  listing_gte?: InputMaybe<Scalars["String"]>;
  listing_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_lt?: InputMaybe<Scalars["String"]>;
  listing_lte?: InputMaybe<Scalars["String"]>;
  listing_not?: InputMaybe<Scalars["String"]>;
  listing_not_contains?: InputMaybe<Scalars["String"]>;
  listing_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with?: InputMaybe<Scalars["String"]>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_not_in?: InputMaybe<Array<Scalars["String"]>>;
  listing_not_starts_with?: InputMaybe<Scalars["String"]>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  listing_starts_with?: InputMaybe<Scalars["String"]>;
  listing_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<InputMaybe<TradeBundleTransaction_Filter>>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  price?: InputMaybe<Scalars["BigInt"]>;
  price_gt?: InputMaybe<Scalars["BigInt"]>;
  price_gte?: InputMaybe<Scalars["BigInt"]>;
  price_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  price_lt?: InputMaybe<Scalars["BigInt"]>;
  price_lte?: InputMaybe<Scalars["BigInt"]>;
  price_not?: InputMaybe<Scalars["BigInt"]>;
  price_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  seller?: InputMaybe<Scalars["Bytes"]>;
  seller_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_gt?: InputMaybe<Scalars["Bytes"]>;
  seller_gte?: InputMaybe<Scalars["Bytes"]>;
  seller_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  seller_lt?: InputMaybe<Scalars["Bytes"]>;
  seller_lte?: InputMaybe<Scalars["Bytes"]>;
  seller_not?: InputMaybe<Scalars["Bytes"]>;
  seller_not_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["Bytes"]>;
  to_contains?: InputMaybe<Scalars["Bytes"]>;
  to_gt?: InputMaybe<Scalars["Bytes"]>;
  to_gte?: InputMaybe<Scalars["Bytes"]>;
  to_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  to_lt?: InputMaybe<Scalars["Bytes"]>;
  to_lte?: InputMaybe<Scalars["Bytes"]>;
  to_not?: InputMaybe<Scalars["Bytes"]>;
  to_not_contains?: InputMaybe<Scalars["Bytes"]>;
  to_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  type?: InputMaybe<TradeTransactionType>;
  type_in?: InputMaybe<Array<TradeTransactionType>>;
  type_not?: InputMaybe<TradeTransactionType>;
  type_not_in?: InputMaybe<Array<TradeTransactionType>>;
};

export enum TradeBundleTransaction_OrderBy {
  Buyer = "buyer",
  From = "from",
  Hash = "hash",
  Id = "id",
  Listing = "listing",
  ListingCreatedAt = "listing__createdAt",
  ListingEndTime = "listing__endTime",
  ListingId = "listing__id",
  ListingOwner = "listing__owner",
  ListingPaymentToken = "listing__paymentToken",
  ListingPrice = "listing__price",
  ListingPublished = "listing__published",
  ListingStartingTime = "listing__startingTime",
  ListingStatus = "listing__status",
  ListingUpdatedAt = "listing__updatedAt",
  PaymentToken = "paymentToken",
  Price = "price",
  Seller = "seller",
  Timestamp = "timestamp",
  To = "to",
  Type = "type",
}

export type TradeCompetition = {
  __typename?: "TradeCompetition";
  chainId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  endTimestamp?: Maybe<Scalars["DateTime"]>;
  game?: Maybe<GameEntityResponse>;
  name?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["DateTime"]>;
  rewardContractAddress?: Maybe<Scalars["String"]>;
  rewardGroups?: Maybe<Array<Maybe<ComponentSharedRewardGroups>>>;
  startTimestamp?: Maybe<Scalars["DateTime"]>;
  taskId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TradeCompetitionRewardGroupsArgs = {
  filters?: InputMaybe<ComponentSharedRewardGroupsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type TradeCompetitionEntity = {
  __typename?: "TradeCompetitionEntity";
  attributes?: Maybe<TradeCompetition>;
  id?: Maybe<Scalars["ID"]>;
};

export type TradeCompetitionEntityResponse = {
  __typename?: "TradeCompetitionEntityResponse";
  data?: Maybe<TradeCompetitionEntity>;
};

export type TradeCompetitionEntityResponseCollection = {
  __typename?: "TradeCompetitionEntityResponseCollection";
  data: Array<TradeCompetitionEntity>;
  meta: ResponseCollectionMeta;
};

export type TradeCompetitionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TradeCompetitionFiltersInput>>>;
  chainId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  endTimestamp?: InputMaybe<DateTimeFilterInput>;
  game?: InputMaybe<GameFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TradeCompetitionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TradeCompetitionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rewardContractAddress?: InputMaybe<StringFilterInput>;
  rewardGroups?: InputMaybe<ComponentSharedRewardGroupsFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  startTimestamp?: InputMaybe<DateTimeFilterInput>;
  taskId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TradeCompetitionInput = {
  chainId?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  endTimestamp?: InputMaybe<Scalars["DateTime"]>;
  game?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  rewardContractAddress?: InputMaybe<Scalars["String"]>;
  rewardGroups?: InputMaybe<
    Array<InputMaybe<ComponentSharedRewardGroupsInput>>
  >;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  startTimestamp?: InputMaybe<Scalars["DateTime"]>;
  taskId?: InputMaybe<Scalars["String"]>;
};

export type TradeListing = {
  __typename?: "TradeListing";
  chainId: Scalars["Float"];
  contract: Scalars["String"];
  createdAt: Scalars["Float"];
  endTime: Scalars["String"];
  id: Scalars["String"];
  metadata?: Maybe<NftDto>;
  nftAddress: Scalars["String"];
  nonce: Scalars["String"];
  owner: Scalars["String"];
  paymentToken: Scalars["String"];
  paymentTokenName: Scalars["String"];
  pricePerItem: Scalars["String"];
  priceValueUsd: Scalars["Float"];
  provider: Scalars["String"];
  published: Scalars["Boolean"];
  quantity: Scalars["Float"];
  startingTime: Scalars["String"];
  status: Scalars["String"];
  syncBlock: Scalars["Float"];
  tokenId: Scalars["String"];
  updatedAt: Scalars["Float"];
};

export type TradeListingDto = {
  __typename?: "TradeListingDto";
  batchCount?: Maybe<Scalars["Float"]>;
  batchId?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  contract: Scalars["String"];
  createDateTime?: Maybe<Scalars["DateTime"]>;
  createdAt?: Maybe<Scalars["Float"]>;
  endTime?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastChangedDateTime?: Maybe<Scalars["DateTime"]>;
  nftAddress: Scalars["String"];
  nonce?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  paymentToken?: Maybe<Scalars["String"]>;
  paymentTokenName?: Maybe<Scalars["String"]>;
  pricePerItem?: Maybe<Scalars["String"]>;
  pricePerItemUsd?: Maybe<Scalars["String"]>;
  provider: Scalars["String"];
  published?: Maybe<Scalars["Boolean"]>;
  quantity?: Maybe<Scalars["Float"]>;
  startingTime?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  syncBlock?: Maybe<Scalars["Float"]>;
  tokenId: Scalars["String"];
  updatedAt?: Maybe<Scalars["Float"]>;
};

export type TradeOffer = {
  __typename?: "TradeOffer";
  deadline: Scalars["BigInt"];
  id: Scalars["ID"];
  nftAddress: Scalars["Bytes"];
  owner: Scalars["Bytes"];
  paymentToken: Scalars["Bytes"];
  pricePerItem: Scalars["BigInt"];
  quantity: Scalars["BigInt"];
  status: TradeOfferStatus;
  tokenId: Scalars["BigInt"];
};

export enum TradeOfferStatus {
  Active = "Active",
  Cancelled = "Cancelled",
}

export type TradeOffer_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TradeOffer_Filter>>>;
  deadline?: InputMaybe<Scalars["BigInt"]>;
  deadline_gt?: InputMaybe<Scalars["BigInt"]>;
  deadline_gte?: InputMaybe<Scalars["BigInt"]>;
  deadline_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  deadline_lt?: InputMaybe<Scalars["BigInt"]>;
  deadline_lte?: InputMaybe<Scalars["BigInt"]>;
  deadline_not?: InputMaybe<Scalars["BigInt"]>;
  deadline_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  nftAddress?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  nftAddress_lt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_lte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  or?: InputMaybe<Array<InputMaybe<TradeOffer_Filter>>>;
  owner?: InputMaybe<Scalars["Bytes"]>;
  owner_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_gt?: InputMaybe<Scalars["Bytes"]>;
  owner_gte?: InputMaybe<Scalars["Bytes"]>;
  owner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  owner_lt?: InputMaybe<Scalars["Bytes"]>;
  owner_lte?: InputMaybe<Scalars["Bytes"]>;
  owner_not?: InputMaybe<Scalars["Bytes"]>;
  owner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  pricePerItem?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_gt?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_gte?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  pricePerItem_lt?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_lte?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_not?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  quantity?: InputMaybe<Scalars["BigInt"]>;
  quantity_gt?: InputMaybe<Scalars["BigInt"]>;
  quantity_gte?: InputMaybe<Scalars["BigInt"]>;
  quantity_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  quantity_lt?: InputMaybe<Scalars["BigInt"]>;
  quantity_lte?: InputMaybe<Scalars["BigInt"]>;
  quantity_not?: InputMaybe<Scalars["BigInt"]>;
  quantity_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  status?: InputMaybe<TradeOfferStatus>;
  status_in?: InputMaybe<Array<TradeOfferStatus>>;
  status_not?: InputMaybe<TradeOfferStatus>;
  status_not_in?: InputMaybe<Array<TradeOfferStatus>>;
  tokenId?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenId_lt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_lte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export enum TradeOffer_OrderBy {
  Deadline = "deadline",
  Id = "id",
  NftAddress = "nftAddress",
  Owner = "owner",
  PaymentToken = "paymentToken",
  PricePerItem = "pricePerItem",
  Quantity = "quantity",
  Status = "status",
  TokenId = "tokenId",
}

export type TradeTransaction = {
  __typename?: "TradeTransaction";
  buyer?: Maybe<Scalars["Bytes"]>;
  chainId: Scalars["Int"];
  from: Scalars["Bytes"];
  hash: Scalars["Bytes"];
  id: Scalars["ID"];
  nftAddress: Scalars["Bytes"];
  paymentToken?: Maybe<Scalars["Bytes"]>;
  pricePerItem?: Maybe<Scalars["BigInt"]>;
  quantity?: Maybe<Scalars["BigInt"]>;
  seller?: Maybe<Scalars["Bytes"]>;
  timestamp: Scalars["BigInt"];
  to?: Maybe<Scalars["Bytes"]>;
  tokenId: Scalars["BigInt"];
  type: TradeTransactionType;
};

export enum TradeTransactionType {
  Buy = "Buy",
  Cancel = "Cancel",
  List = "List",
  OfferCancel = "OfferCancel",
  OfferCreate = "OfferCreate",
  Update = "Update",
}

export type TradeTransaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TradeTransaction_Filter>>>;
  buyer?: InputMaybe<Scalars["Bytes"]>;
  buyer_contains?: InputMaybe<Scalars["Bytes"]>;
  buyer_gt?: InputMaybe<Scalars["Bytes"]>;
  buyer_gte?: InputMaybe<Scalars["Bytes"]>;
  buyer_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  buyer_lt?: InputMaybe<Scalars["Bytes"]>;
  buyer_lte?: InputMaybe<Scalars["Bytes"]>;
  buyer_not?: InputMaybe<Scalars["Bytes"]>;
  buyer_not_contains?: InputMaybe<Scalars["Bytes"]>;
  buyer_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from?: InputMaybe<Scalars["Bytes"]>;
  from_contains?: InputMaybe<Scalars["Bytes"]>;
  from_gt?: InputMaybe<Scalars["Bytes"]>;
  from_gte?: InputMaybe<Scalars["Bytes"]>;
  from_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from_lt?: InputMaybe<Scalars["Bytes"]>;
  from_lte?: InputMaybe<Scalars["Bytes"]>;
  from_not?: InputMaybe<Scalars["Bytes"]>;
  from_not_contains?: InputMaybe<Scalars["Bytes"]>;
  from_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash?: InputMaybe<Scalars["Bytes"]>;
  hash_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_gt?: InputMaybe<Scalars["Bytes"]>;
  hash_gte?: InputMaybe<Scalars["Bytes"]>;
  hash_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash_lt?: InputMaybe<Scalars["Bytes"]>;
  hash_lte?: InputMaybe<Scalars["Bytes"]>;
  hash_not?: InputMaybe<Scalars["Bytes"]>;
  hash_not_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  nftAddress?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_gte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  nftAddress_lt?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_lte?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_contains?: InputMaybe<Scalars["Bytes"]>;
  nftAddress_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  or?: InputMaybe<Array<InputMaybe<TradeTransaction_Filter>>>;
  paymentToken?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_gte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  paymentToken_lt?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_lte?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_contains?: InputMaybe<Scalars["Bytes"]>;
  paymentToken_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  pricePerItem?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_gt?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_gte?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  pricePerItem_lt?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_lte?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_not?: InputMaybe<Scalars["BigInt"]>;
  pricePerItem_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  quantity?: InputMaybe<Scalars["BigInt"]>;
  quantity_gt?: InputMaybe<Scalars["BigInt"]>;
  quantity_gte?: InputMaybe<Scalars["BigInt"]>;
  quantity_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  quantity_lt?: InputMaybe<Scalars["BigInt"]>;
  quantity_lte?: InputMaybe<Scalars["BigInt"]>;
  quantity_not?: InputMaybe<Scalars["BigInt"]>;
  quantity_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  seller?: InputMaybe<Scalars["Bytes"]>;
  seller_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_gt?: InputMaybe<Scalars["Bytes"]>;
  seller_gte?: InputMaybe<Scalars["Bytes"]>;
  seller_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  seller_lt?: InputMaybe<Scalars["Bytes"]>;
  seller_lte?: InputMaybe<Scalars["Bytes"]>;
  seller_not?: InputMaybe<Scalars["Bytes"]>;
  seller_not_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["Bytes"]>;
  to_contains?: InputMaybe<Scalars["Bytes"]>;
  to_gt?: InputMaybe<Scalars["Bytes"]>;
  to_gte?: InputMaybe<Scalars["Bytes"]>;
  to_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  to_lt?: InputMaybe<Scalars["Bytes"]>;
  to_lte?: InputMaybe<Scalars["Bytes"]>;
  to_not?: InputMaybe<Scalars["Bytes"]>;
  to_not_contains?: InputMaybe<Scalars["Bytes"]>;
  to_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  tokenId?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_gte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  tokenId_lt?: InputMaybe<Scalars["BigInt"]>;
  tokenId_lte?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not?: InputMaybe<Scalars["BigInt"]>;
  tokenId_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  type?: InputMaybe<TradeTransactionType>;
  type_in?: InputMaybe<Array<TradeTransactionType>>;
  type_not?: InputMaybe<TradeTransactionType>;
  type_not_in?: InputMaybe<Array<TradeTransactionType>>;
};

export enum TradeTransaction_OrderBy {
  Buyer = "buyer",
  From = "from",
  Hash = "hash",
  Id = "id",
  NftAddress = "nftAddress",
  PaymentToken = "paymentToken",
  PricePerItem = "pricePerItem",
  Quantity = "quantity",
  Seller = "seller",
  Timestamp = "timestamp",
  To = "to",
  TokenId = "tokenId",
  Type = "type",
}

export type Transaction = {
  __typename?: "Transaction";
  blockNumber: Scalars["BigInt"];
  events: Array<Event>;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
};

export type TransactionEventsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type Transaction_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>;
  blockNumber?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_not?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  events_?: InputMaybe<Event_Filter>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Transaction_Filter>>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type UploadFile = {
  __typename?: "UploadFile";
  alternativeText?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  ext?: Maybe<Scalars["String"]>;
  formats?: Maybe<Scalars["JSON"]>;
  hash: Scalars["String"];
  height?: Maybe<Scalars["Int"]>;
  mime: Scalars["String"];
  name: Scalars["String"];
  previewUrl?: Maybe<Scalars["String"]>;
  provider: Scalars["String"];
  provider_metadata?: Maybe<Scalars["JSON"]>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars["Float"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
  url: Scalars["String"];
  width?: Maybe<Scalars["Int"]>;
};

export type UploadFileEntity = {
  __typename?: "UploadFileEntity";
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars["ID"]>;
};

export type UploadFileEntityResponse = {
  __typename?: "UploadFileEntityResponse";
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: "UploadFileEntityResponseCollection";
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars["String"]>;
  caption?: InputMaybe<Scalars["String"]>;
  ext?: InputMaybe<Scalars["String"]>;
  folder?: InputMaybe<Scalars["ID"]>;
  folderPath?: InputMaybe<Scalars["String"]>;
  formats?: InputMaybe<Scalars["JSON"]>;
  hash?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  mime?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  previewUrl?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  provider_metadata?: InputMaybe<Scalars["JSON"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  size?: InputMaybe<Scalars["Float"]>;
  url?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: "UploadFileRelationResponseCollection";
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: "UploadFolder";
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars["String"];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars["String"];
  pathId: Scalars["Int"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UploadFolderEntity = {
  __typename?: "UploadFolderEntity";
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars["ID"]>;
};

export type UploadFolderEntityResponse = {
  __typename?: "UploadFolderEntityResponse";
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: "UploadFolderEntityResponseCollection";
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Scalars["ID"]>;
  path?: InputMaybe<Scalars["String"]>;
  pathId?: InputMaybe<Scalars["Int"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: "UploadFolderRelationResponseCollection";
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: "UsersPermissionsCreateRolePayload";
  ok: Scalars["Boolean"];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: "UsersPermissionsDeleteRolePayload";
  ok: Scalars["Boolean"];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars["String"];
  password: Scalars["String"];
  provider?: Scalars["String"];
};

export type UsersPermissionsLoginPayload = {
  __typename?: "UsersPermissionsLoginPayload";
  jwt?: Maybe<Scalars["String"]>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: "UsersPermissionsMe";
  blocked?: Maybe<Scalars["Boolean"]>;
  confirmed?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars["String"];
};

export type UsersPermissionsMeRole = {
  __typename?: "UsersPermissionsMeRole";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: "UsersPermissionsPasswordPayload";
  ok: Scalars["Boolean"];
};

export type UsersPermissionsPermission = {
  __typename?: "UsersPermissionsPermission";
  action: Scalars["String"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: "UsersPermissionsPermissionEntity";
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: "UsersPermissionsPermissionRelationResponseCollection";
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  username: Scalars["String"];
};

export type UsersPermissionsRole = {
  __typename?: "UsersPermissionsRole";
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: "UsersPermissionsRoleEntity";
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: "UsersPermissionsRoleEntityResponse";
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: "UsersPermissionsRoleEntityResponseCollection";
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type?: InputMaybe<Scalars["String"]>;
  users?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: "UsersPermissionsUpdateRolePayload";
  ok: Scalars["Boolean"];
};

export type UsersPermissionsUser = {
  __typename?: "UsersPermissionsUser";
  blocked?: Maybe<Scalars["Boolean"]>;
  confirmed?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  provider?: Maybe<Scalars["String"]>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  username: Scalars["String"];
};

export type UsersPermissionsUserEntity = {
  __typename?: "UsersPermissionsUserEntity";
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars["ID"]>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: "UsersPermissionsUserEntityResponse";
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: "UsersPermissionsUserEntityResponseCollection";
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sitemap_exclude?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars["Boolean"]>;
  confirmationToken?: InputMaybe<Scalars["String"]>;
  confirmed?: InputMaybe<Scalars["Boolean"]>;
  email?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["ID"]>;
  sitemap_exclude?: InputMaybe<Scalars["Boolean"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: "UsersPermissionsUserRelationResponseCollection";
  data: Array<UsersPermissionsUserEntity>;
};

export type Vault = {
  __typename?: "Vault";
  address: Scalars["Bytes"];
  chainId: Scalars["Int"];
  createdAt: Scalars["BigInt"];
  id: Scalars["ID"];
  owners: Array<VaultOwner>;
  singleton: Scalars["Bytes"];
};

export type VaultOwnersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<VaultOwner_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<VaultOwner_Filter>;
};

export type VaultOwner = {
  __typename?: "VaultOwner";
  address: Scalars["Bytes"];
  id: Scalars["ID"];
  vault: Vault;
};

export type VaultOwner_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  address?: InputMaybe<Scalars["Bytes"]>;
  address_contains?: InputMaybe<Scalars["Bytes"]>;
  address_gt?: InputMaybe<Scalars["Bytes"]>;
  address_gte?: InputMaybe<Scalars["Bytes"]>;
  address_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  address_lt?: InputMaybe<Scalars["Bytes"]>;
  address_lte?: InputMaybe<Scalars["Bytes"]>;
  address_not?: InputMaybe<Scalars["Bytes"]>;
  address_not_contains?: InputMaybe<Scalars["Bytes"]>;
  address_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  and?: InputMaybe<Array<InputMaybe<VaultOwner_Filter>>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<VaultOwner_Filter>>>;
  vault?: InputMaybe<Scalars["String"]>;
  vault_?: InputMaybe<Vault_Filter>;
  vault_contains?: InputMaybe<Scalars["String"]>;
  vault_contains_nocase?: InputMaybe<Scalars["String"]>;
  vault_ends_with?: InputMaybe<Scalars["String"]>;
  vault_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vault_gt?: InputMaybe<Scalars["String"]>;
  vault_gte?: InputMaybe<Scalars["String"]>;
  vault_in?: InputMaybe<Array<Scalars["String"]>>;
  vault_lt?: InputMaybe<Scalars["String"]>;
  vault_lte?: InputMaybe<Scalars["String"]>;
  vault_not?: InputMaybe<Scalars["String"]>;
  vault_not_contains?: InputMaybe<Scalars["String"]>;
  vault_not_contains_nocase?: InputMaybe<Scalars["String"]>;
  vault_not_ends_with?: InputMaybe<Scalars["String"]>;
  vault_not_ends_with_nocase?: InputMaybe<Scalars["String"]>;
  vault_not_in?: InputMaybe<Array<Scalars["String"]>>;
  vault_not_starts_with?: InputMaybe<Scalars["String"]>;
  vault_not_starts_with_nocase?: InputMaybe<Scalars["String"]>;
  vault_starts_with?: InputMaybe<Scalars["String"]>;
  vault_starts_with_nocase?: InputMaybe<Scalars["String"]>;
};

export enum VaultOwner_OrderBy {
  Address = "address",
  Id = "id",
  Vault = "vault",
  VaultAddress = "vault__address",
  VaultCreatedAt = "vault__createdAt",
  VaultId = "vault__id",
  VaultSingleton = "vault__singleton",
}

export type Vault_Filter = {
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  address?: InputMaybe<Scalars["Bytes"]>;
  address_contains?: InputMaybe<Scalars["Bytes"]>;
  address_gt?: InputMaybe<Scalars["Bytes"]>;
  address_gte?: InputMaybe<Scalars["Bytes"]>;
  address_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  address_lt?: InputMaybe<Scalars["Bytes"]>;
  address_lte?: InputMaybe<Scalars["Bytes"]>;
  address_not?: InputMaybe<Scalars["Bytes"]>;
  address_not_contains?: InputMaybe<Scalars["Bytes"]>;
  address_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  and?: InputMaybe<Array<InputMaybe<Vault_Filter>>>;
  createdAt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_gte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  createdAt_lt?: InputMaybe<Scalars["BigInt"]>;
  createdAt_lte?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not?: InputMaybe<Scalars["BigInt"]>;
  createdAt_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  or?: InputMaybe<Array<InputMaybe<Vault_Filter>>>;
  owners_?: InputMaybe<VaultOwner_Filter>;
  singleton?: InputMaybe<Scalars["Bytes"]>;
  singleton_contains?: InputMaybe<Scalars["Bytes"]>;
  singleton_gt?: InputMaybe<Scalars["Bytes"]>;
  singleton_gte?: InputMaybe<Scalars["Bytes"]>;
  singleton_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  singleton_lt?: InputMaybe<Scalars["Bytes"]>;
  singleton_lte?: InputMaybe<Scalars["Bytes"]>;
  singleton_not?: InputMaybe<Scalars["Bytes"]>;
  singleton_not_contains?: InputMaybe<Scalars["Bytes"]>;
  singleton_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
};

export enum Vault_OrderBy {
  Address = "address",
  CreatedAt = "createdAt",
  Id = "id",
  Owners = "owners",
  Singleton = "singleton",
}

export type _Block_ = {
  __typename?: "_Block_";
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]>;
  /** The block number */
  number: Scalars["Int"];
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars["Bytes"]>;
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars["Int"]>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_";
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  chainId: Scalars["Int"];
  /** The deployment ID */
  deployment: Scalars["String"];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}

export const NftDtoFieldsFragmentDoc = gql`
  fragment NftDtoFields on NftDto {
    id
    name
    image
    chainId
    contractAddress
    tokenId
    protocol
    tradeListing {
      id
      published
      quantity
      pricePerItem
      pricePerItemUsd
      paymentToken
      contract
      nonce
    }
    rentListing {
      id
      finalDuration
      minDuration
      maxDuration
      owner
      borrower
      paymentToken
      finalPaymentValue
      pricePerSecond
      paymentTokenName
      status
      updatedAt
      nonce
      borrowedAt
    }
    owner
  }
`;
export const Erc721TokenFragmentFragmentDoc = gql`
  fragment ERC721TokenFragment on ERC721Token {
    id
    identifier
    uri
    contract {
      id
    }
    owner {
      id
    }
    nftById {
      ...NftDtoFields
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;
export const UploadFileFragmentFragmentDoc = gql`
  fragment UploadFileFragment on UploadFileEntity {
    id
    attributes {
      name
      width
      height
      url
      previewUrl
    }
  }
`;
export const ArticleAttributesFragmentFragmentDoc = gql`
  fragment ArticleAttributesFragment on Article {
    title
    description
    slug
    cover {
      data {
        ...UploadFileFragment
      }
    }
    blocks {
      ... on ComponentSharedMedia {
        id
        file {
          data {
            ...UploadFileFragment
          }
        }
      }
      ... on ComponentSharedRichText {
        id
        body
      }
      ... on ComponentSharedSlider {
        id
        files {
          data {
            ...UploadFileFragment
          }
        }
      }
      ... on ComponentSharedQuote {
        id
        title
        body
      }
      ... on ComponentSharedTileGrid {
        id
        direction
        title
        description
        media {
          data {
            ...UploadFileFragment
          }
        }
      }
    }
    seo {
      id
      metaTitle
      metaDescription
      metaImage {
        data {
          ...UploadFileFragment
        }
      }
      keywords
      metaRobots
      metaSocial {
        description
        image {
          data {
            ...UploadFileFragment
          }
        }
        socialNetwork
        title
      }
      metaViewport
      structuredData
    }
    videoCover {
      data {
        ...UploadFileFragment
      }
    }
    heroTiles {
      id
      direction
      title
      description
      media {
        data {
          ...UploadFileFragment
        }
      }
    }
    relatedArticles {
      id
      title
      description
      url
      articles {
        id
        title
        article {
          data {
            id
            attributes {
              slug
              thumbnail {
                title
                description
                media {
                  data {
                    ...UploadFileFragment
                  }
                }
              }
            }
          }
        }
      }
    }
    learnMore {
      id
      buttonTitle
      description
      href
    }
    startButton {
      id
      buttonTitle
      description
      href
    }
    publishedAt
  }
  ${UploadFileFragmentFragmentDoc}
`;
export const TradeTransactionFragmentFragmentDoc = gql`
  fragment TradeTransactionFragment on TradeTransaction {
    id
    hash
    nftAddress
    tokenId
    type
    paymentToken
    pricePerItem
    timestamp
    quantity
    from
    to
    buyer
    seller
  }
`;
export const GetMoreNftsDocument = gql`
  query GetMoreNfts(
    $where: Nft_filter
    $orderBy: OrderBy
    $orderDirection: OrderDirection
    $requestId: String
  ) {
    nftFiltered(
      first: 5
      where: $where
      orderBy: $orderBy
      orderDirection: $orderDirection
      requestId: $requestId
    ) {
      nft {
        ...NftDtoFields
      }
      aggregations {
        providers {
          provider
          count
        }
      }
      total
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetMoreNftsQuery__
 *
 * To run a query within a React component, call `useGetMoreNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoreNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoreNftsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetMoreNftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMoreNftsQuery,
    GetMoreNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMoreNftsQuery, GetMoreNftsQueryVariables>(
    GetMoreNftsDocument,
    options
  );
}
export function useGetMoreNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMoreNftsQuery,
    GetMoreNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMoreNftsQuery, GetMoreNftsQueryVariables>(
    GetMoreNftsDocument,
    options
  );
}
export type GetMoreNftsQueryHookResult = ReturnType<typeof useGetMoreNftsQuery>;
export type GetMoreNftsLazyQueryHookResult = ReturnType<
  typeof useGetMoreNftsLazyQuery
>;
export type GetMoreNftsQueryResult = Apollo.QueryResult<
  GetMoreNftsQuery,
  GetMoreNftsQueryVariables
>;
export const GetOurPicksNftsDocument = gql`
  query GetOurPicksNfts {
    first: nftFiltered(
      first: 2
      where: {
        andFilters: [
          { field: provider, operator: EQ, value: ["META_SOCCER"] }
          { field: tradePublished, operator: EQ, value: ["true"] }
        ]
      }
    ) {
      nft {
        ...NftDtoFields
      }
    }
    second: nftFiltered(
      first: 1
      where: {
        andFilters: [
          { field: provider, operator: EQ, value: ["ELPIS_BATTLE"] }
          { field: tradePublished, operator: EQ, value: ["true"] }
        ]
      }
    ) {
      nft {
        ...NftDtoFields
      }
    }
    third: nftFiltered(
      first: 1
      where: {
        andFilters: [
          { field: provider, operator: EQ, value: ["TINY_WORLD"] }
          { field: tradePublished, operator: EQ, value: ["true"] }
        ]
      }
    ) {
      nft {
        ...NftDtoFields
      }
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetOurPicksNftsQuery__
 *
 * To run a query within a React component, call `useGetOurPicksNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOurPicksNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOurPicksNftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOurPicksNftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOurPicksNftsQuery,
    GetOurPicksNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOurPicksNftsQuery, GetOurPicksNftsQueryVariables>(
    GetOurPicksNftsDocument,
    options
  );
}
export function useGetOurPicksNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOurPicksNftsQuery,
    GetOurPicksNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOurPicksNftsQuery,
    GetOurPicksNftsQueryVariables
  >(GetOurPicksNftsDocument, options);
}
export type GetOurPicksNftsQueryHookResult = ReturnType<
  typeof useGetOurPicksNftsQuery
>;
export type GetOurPicksNftsLazyQueryHookResult = ReturnType<
  typeof useGetOurPicksNftsLazyQuery
>;
export type GetOurPicksNftsQueryResult = Apollo.QueryResult<
  GetOurPicksNftsQuery,
  GetOurPicksNftsQueryVariables
>;
export const GetMarketplaceFiltersDocument = gql`
  query GetMarketplaceFilters {
    games(filters: { provider: { notNull: true } }) {
      data {
        id
        attributes {
          title
          provider
        }
      }
    }
    chains {
      data {
        attributes {
          name
          chainId
        }
      }
    }
  }
`;

/**
 * __useGetMarketplaceFiltersQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketplaceFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketplaceFiltersQuery,
    GetMarketplaceFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketplaceFiltersQuery,
    GetMarketplaceFiltersQueryVariables
  >(GetMarketplaceFiltersDocument, options);
}
export function useGetMarketplaceFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketplaceFiltersQuery,
    GetMarketplaceFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketplaceFiltersQuery,
    GetMarketplaceFiltersQueryVariables
  >(GetMarketplaceFiltersDocument, options);
}
export type GetMarketplaceFiltersQueryHookResult = ReturnType<
  typeof useGetMarketplaceFiltersQuery
>;
export type GetMarketplaceFiltersLazyQueryHookResult = ReturnType<
  typeof useGetMarketplaceFiltersLazyQuery
>;
export type GetMarketplaceFiltersQueryResult = Apollo.QueryResult<
  GetMarketplaceFiltersQuery,
  GetMarketplaceFiltersQueryVariables
>;
export const PropertiesDocument = gql`
  query Properties($provider: String!, $chainId: Float!, $contract: String!) {
    properties(provider: $provider, chainId: $chainId, contract: $contract) {
      id
      name
      dataType
      minValue
      maxValue
    }
  }
`;

/**
 * __usePropertiesQuery__
 *
 * To run a query within a React component, call `usePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      chainId: // value for 'chainId'
 *      contract: // value for 'contract'
 *   },
 * });
 */
export function usePropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertiesQuery,
    PropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(
    PropertiesDocument,
    options
  );
}
export function usePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertiesQuery,
    PropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(
    PropertiesDocument,
    options
  );
}
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<
  typeof usePropertiesLazyQuery
>;
export type PropertiesQueryResult = Apollo.QueryResult<
  PropertiesQuery,
  PropertiesQueryVariables
>;
export const PropertyValuesFilteredDocument = gql`
  query PropertyValuesFiltered(
    $propertyId: String!
    $input: String!
    $first: Float
    $skip: Float
  ) {
    propertyValuesFiltered(
      propertyId: $propertyId
      input: $input
      first: $first
      skip: $skip
    ) {
      id
      valueInt
      valueFloat
      valueString
    }
  }
`;

/**
 * __usePropertyValuesFilteredQuery__
 *
 * To run a query within a React component, call `usePropertyValuesFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyValuesFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyValuesFilteredQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      input: // value for 'input'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePropertyValuesFilteredQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyValuesFilteredQuery,
    PropertyValuesFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PropertyValuesFilteredQuery,
    PropertyValuesFilteredQueryVariables
  >(PropertyValuesFilteredDocument, options);
}
export function usePropertyValuesFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyValuesFilteredQuery,
    PropertyValuesFilteredQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PropertyValuesFilteredQuery,
    PropertyValuesFilteredQueryVariables
  >(PropertyValuesFilteredDocument, options);
}
export type PropertyValuesFilteredQueryHookResult = ReturnType<
  typeof usePropertyValuesFilteredQuery
>;
export type PropertyValuesFilteredLazyQueryHookResult = ReturnType<
  typeof usePropertyValuesFilteredLazyQuery
>;
export type PropertyValuesFilteredQueryResult = Apollo.QueryResult<
  PropertyValuesFilteredQuery,
  PropertyValuesFilteredQueryVariables
>;
export const GetTradeCompetitionStatusDocument = gql`
  query GetTradeCompetitionStatus(
    $provider: NftProvider!
    $chain: Int!
    $userAddress: String!
    $startTimestamp: DateTime!
    $endTimestamp: DateTime
  ) {
    tradeCompetitionStatus(
      provider: $provider
      chain: $chain
      userAddress: $userAddress
      startTimestamp: $startTimestamp
      endTimestamp: $endTimestamp
    ) {
      competitionId
      provider
      startTimestamp
      endTimestamp
      requestedUser {
        place
        userAddress
        volumeUsd
      }
      topUsers {
        place
        userAddress
        volumeUsd
      }
    }
  }
`;

/**
 * __useGetTradeCompetitionStatusQuery__
 *
 * To run a query within a React component, call `useGetTradeCompetitionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeCompetitionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeCompetitionStatusQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      chain: // value for 'chain'
 *      userAddress: // value for 'userAddress'
 *      startTimestamp: // value for 'startTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *   },
 * });
 */
export function useGetTradeCompetitionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTradeCompetitionStatusQuery,
    GetTradeCompetitionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTradeCompetitionStatusQuery,
    GetTradeCompetitionStatusQueryVariables
  >(GetTradeCompetitionStatusDocument, options);
}
export function useGetTradeCompetitionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTradeCompetitionStatusQuery,
    GetTradeCompetitionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTradeCompetitionStatusQuery,
    GetTradeCompetitionStatusQueryVariables
  >(GetTradeCompetitionStatusDocument, options);
}
export type GetTradeCompetitionStatusQueryHookResult = ReturnType<
  typeof useGetTradeCompetitionStatusQuery
>;
export type GetTradeCompetitionStatusLazyQueryHookResult = ReturnType<
  typeof useGetTradeCompetitionStatusLazyQuery
>;
export type GetTradeCompetitionStatusQueryResult = Apollo.QueryResult<
  GetTradeCompetitionStatusQuery,
  GetTradeCompetitionStatusQueryVariables
>;
export const GetAssetDocument = gql`
  query GetAsset($id: String!) {
    nftById(id: $id) {
      ...NftDtoFields
      metadata {
        trait_type
        display_type
        value
      }
      properties {
        name
        maxValue
      }
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetAssetQuery__
 *
 * To run a query within a React component, call `useGetAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetQuery({
 *   variables: {
 *      [id]: // value for '[id]'
 *   },
 * });
 */
export function useGetAssetQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetQuery, GetAssetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetQuery, GetAssetQueryVariables>(
    GetAssetDocument,
    options
  );
}
export function useGetAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetQuery,
    GetAssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetQuery, GetAssetQueryVariables>(
    GetAssetDocument,
    options
  );
}
export type GetAssetQueryHookResult = ReturnType<typeof useGetAssetQuery>;
export type GetAssetLazyQueryHookResult = ReturnType<
  typeof useGetAssetLazyQuery
>;
export type GetAssetQueryResult = Apollo.QueryResult<
  GetAssetQuery,
  GetAssetQueryVariables
>;
export const GetAssetMetadataDocument = gql`
  query GetAssetMetadata($id: String!) {
    nftById(id: $id) {
      name
      tokenId
      image
      provider
      contractAddress
    }
  }
`;

/**
 * __useGetAssetMetadataQuery__
 *
 * To run a query within a React component, call `useGetAssetMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetMetadataQuery({
 *   variables: {
 *      [id]: // value for '[id]'
 *   },
 * });
 */
export function useGetAssetMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetMetadataQuery,
    GetAssetMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetMetadataQuery, GetAssetMetadataQueryVariables>(
    GetAssetMetadataDocument,
    options
  );
}
export function useGetAssetMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetMetadataQuery,
    GetAssetMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetMetadataQuery,
    GetAssetMetadataQueryVariables
  >(GetAssetMetadataDocument, options);
}
export type GetAssetMetadataQueryHookResult = ReturnType<
  typeof useGetAssetMetadataQuery
>;
export type GetAssetMetadataLazyQueryHookResult = ReturnType<
  typeof useGetAssetMetadataLazyQuery
>;
export type GetAssetMetadataQueryResult = Apollo.QueryResult<
  GetAssetMetadataQuery,
  GetAssetMetadataQueryVariables
>;
export const GetAssetsDocument = gql`
  query GetAssets(
    $first: Int
    $skip: Int
    $where: Nft_filter
    $orderBy: OrderBy
    $orderDirection: OrderDirection
    $onlyTradeListings: Boolean
    $onlyRentListings: Boolean
    $requestId: String
  ) {
    nftFiltered(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      orderDirection: $orderDirection
      onlyTradeListings: $onlyTradeListings
      onlyRentListings: $onlyRentListings
      requestId: $requestId
    ) {
      nft {
        ...NftDtoFields
      }
      aggregations {
        providers {
          provider
          count
        }
      }
      total
      outdatedRequestId
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      onlyTradeListings: // value for 'onlyTradeListings'
 *      onlyRentListings: // value for 'onlyRentListings'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export function useGetAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsQuery,
    GetAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAssetsLazyQuery
>;
export type GetAssetsQueryResult = Apollo.QueryResult<
  GetAssetsQuery,
  GetAssetsQueryVariables
>;
export const GetCoinRatesDocument = gql`
  query GetCoinRates {
    coinRates {
      usdRate
      paymentTokenName
    }
  }
`;

/**
 * __useGetCoinRatesQuery__
 *
 * To run a query within a React component, call `useGetCoinRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoinRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoinRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoinRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoinRatesQuery,
    GetCoinRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoinRatesQuery, GetCoinRatesQueryVariables>(
    GetCoinRatesDocument,
    options
  );
}
export function useGetCoinRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoinRatesQuery,
    GetCoinRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoinRatesQuery, GetCoinRatesQueryVariables>(
    GetCoinRatesDocument,
    options
  );
}
export type GetCoinRatesQueryHookResult = ReturnType<
  typeof useGetCoinRatesQuery
>;
export type GetCoinRatesLazyQueryHookResult = ReturnType<
  typeof useGetCoinRatesLazyQuery
>;
export type GetCoinRatesQueryResult = Apollo.QueryResult<
  GetCoinRatesQuery,
  GetCoinRatesQueryVariables
>;
export const GetMaxSyncBlocksDocument = gql`
  query GetMaxSyncBlocks {
    maxSyncBlocks {
      chainId
      maxSyncBlock
    }
    tradeMaxSyncBlocks {
      chainId
      maxSyncBlock
    }
    tradeBundleMaxSyncBlocks {
      chainId
      maxSyncBlock
    }
  }
`;

/**
 * __useGetMaxSyncBlocksQuery__
 *
 * To run a query within a React component, call `useGetMaxSyncBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxSyncBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxSyncBlocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxSyncBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxSyncBlocksQuery,
    GetMaxSyncBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMaxSyncBlocksQuery, GetMaxSyncBlocksQueryVariables>(
    GetMaxSyncBlocksDocument,
    options
  );
}
export function useGetMaxSyncBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxSyncBlocksQuery,
    GetMaxSyncBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMaxSyncBlocksQuery,
    GetMaxSyncBlocksQueryVariables
  >(GetMaxSyncBlocksDocument, options);
}
export type GetMaxSyncBlocksQueryHookResult = ReturnType<
  typeof useGetMaxSyncBlocksQuery
>;
export type GetMaxSyncBlocksLazyQueryHookResult = ReturnType<
  typeof useGetMaxSyncBlocksLazyQuery
>;
export type GetMaxSyncBlocksQueryResult = Apollo.QueryResult<
  GetMaxSyncBlocksQuery,
  GetMaxSyncBlocksQueryVariables
>;
export const GetMetaBlockDocument = gql`
  query GetMetaBlock {
    _meta_56 {
      chainId @client @staticIntValue(value: 56)
      block {
        number
      }
    }
  }
`;

/**
 * __useGetMetaBlockQuery__
 *
 * To run a query within a React component, call `useGetMetaBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMetaBlockQuery,
    GetMetaBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetaBlockQuery, GetMetaBlockQueryVariables>(
    GetMetaBlockDocument,
    options
  );
}
export function useGetMetaBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetaBlockQuery,
    GetMetaBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetaBlockQuery, GetMetaBlockQueryVariables>(
    GetMetaBlockDocument,
    options
  );
}
export type GetMetaBlockQueryHookResult = ReturnType<
  typeof useGetMetaBlockQuery
>;
export type GetMetaBlockLazyQueryHookResult = ReturnType<
  typeof useGetMetaBlockLazyQuery
>;
export type GetMetaBlockQueryResult = Apollo.QueryResult<
  GetMetaBlockQuery,
  GetMetaBlockQueryVariables
>;
export const GetTokenDocument = gql`
  query GetToken($id: ID!) {
    erc721Token_56(id: $id) {
      ...ERC721TokenFragment
    }
    erc1155Token_56(id: $id) {
      id
      identifier
      contract {
        id
      }
      balances(where: { valueExact_gte: "1", account_not: null }) {
        account {
          id
        }
      }
      nftById {
        ...NftDtoFields
      }
    }
  }
  ${Erc721TokenFragmentFragmentDoc}
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetTokenQuery__
 *
 * To run a query within a React component, call `useGetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenQuery({
 *   variables: {
 *      [id]: // value for '[id]'
 *   },
 * });
 */
export function useGetTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetTokenQuery, GetTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokenQuery, GetTokenQueryVariables>(
    GetTokenDocument,
    options
  );
}
export function useGetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokenQuery,
    GetTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokenQuery, GetTokenQueryVariables>(
    GetTokenDocument,
    options
  );
}
export type GetTokenQueryHookResult = ReturnType<typeof useGetTokenQuery>;
export type GetTokenLazyQueryHookResult = ReturnType<
  typeof useGetTokenLazyQuery
>;
export type GetTokenQueryResult = Apollo.QueryResult<
  GetTokenQuery,
  GetTokenQueryVariables
>;
export const GetTokensByOwnersDocument = gql`
  query GetTokensByOwners($owners: [String!]!) {
    erc721Tokens_56(where: { owner_in: $owners }) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        name
        image
        protocol
        tradeListing {
          id
          published
          quantity
        }
      }
    }
    erc721Tokens_1(where: { owner_in: $owners }) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        name
        image
        protocol
        tradeListing {
          id
          published
          quantity
        }
      }
    }
    erc1155Balances_56(where: { valueExact_gte: "1", account_in: $owners }) {
      account {
        id
      }
      token {
        id
        identifier
        uri
        nftById {
          name
          image
          protocol
          tradeListing {
            id
            published
            quantity
          }
        }
      }
      contract {
        id
      }
    }
  }
`;

/**
 * __useGetTokensByOwnersQuery__
 *
 * To run a query within a React component, call `useGetTokensByOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensByOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensByOwnersQuery({
 *   variables: {
 *      owners: // value for 'owners'
 *   },
 * });
 */
export function useGetTokensByOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTokensByOwnersQuery,
    GetTokensByOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTokensByOwnersQuery,
    GetTokensByOwnersQueryVariables
  >(GetTokensByOwnersDocument, options);
}
export function useGetTokensByOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokensByOwnersQuery,
    GetTokensByOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTokensByOwnersQuery,
    GetTokensByOwnersQueryVariables
  >(GetTokensByOwnersDocument, options);
}
export type GetTokensByOwnersQueryHookResult = ReturnType<
  typeof useGetTokensByOwnersQuery
>;
export type GetTokensByOwnersLazyQueryHookResult = ReturnType<
  typeof useGetTokensByOwnersLazyQuery
>;
export type GetTokensByOwnersQueryResult = Apollo.QueryResult<
  GetTokensByOwnersQuery,
  GetTokensByOwnersQueryVariables
>;
export const GetTokensDocument = gql`
  query GetTokens($owner: String!) {
    erc721Tokens_56(where: { owner: $owner }) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        ...NftDtoFields
      }
    }
    erc721Tokens_1(where: { owner: $owner }) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        ...NftDtoFields
      }
    }
    erc1155Balances_56(where: { valueExact_gte: "1", account: $owner }) {
      account {
        id
      }
      token {
        id
        identifier
        uri
        nftById {
          ...NftDtoFields
        }
      }
      contract {
        id
      }
    }
  }
  ${NftDtoFieldsFragmentDoc}
`;

/**
 * __useGetTokensQuery__
 *
 * To run a query within a React component, call `useGetTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensQuery({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useGetTokensQuery(
  baseOptions: Apollo.QueryHookOptions<GetTokensQuery, GetTokensQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokensQuery, GetTokensQueryVariables>(
    GetTokensDocument,
    options
  );
}
export function useGetTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokensQuery,
    GetTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokensQuery, GetTokensQueryVariables>(
    GetTokensDocument,
    options
  );
}
export type GetTokensQueryHookResult = ReturnType<typeof useGetTokensQuery>;
export type GetTokensLazyQueryHookResult = ReturnType<
  typeof useGetTokensLazyQuery
>;
export type GetTokensQueryResult = Apollo.QueryResult<
  GetTokensQuery,
  GetTokensQueryVariables
>;
export const GetTokensRawDocument = gql`
  query GetTokensRaw(
    $where721: ERC721Token_filter
    $where1155: ERC1155Balance_filter
  ) {
    erc721Tokens_56(where: $where721) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        name
        image
        protocol
        tradeListing {
          id
          published
          quantity
        }
      }
    }
    erc721Tokens_1(where: $where721) {
      id
      identifier
      uri
      contract {
        id
      }
      owner {
        id
      }
      nftById {
        name
        image
        protocol
        tradeListing {
          id
          published
          quantity
        }
      }
    }
    erc1155Balances_56(where: $where1155) {
      account {
        id
      }
      token {
        id
        identifier
        uri
        nftById {
          name
          image
          protocol
          tradeListing {
            id
            published
            quantity
          }
        }
      }
      contract {
        id
      }
    }
  }
`;

/**
 * __useGetTokensRawQuery__
 *
 * To run a query within a React component, call `useGetTokensRawQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokensRawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokensRawQuery({
 *   variables: {
 *      where721: // value for 'where721'
 *      where1155: // value for 'where1155'
 *   },
 * });
 */
export function useGetTokensRawQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTokensRawQuery,
    GetTokensRawQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokensRawQuery, GetTokensRawQueryVariables>(
    GetTokensRawDocument,
    options
  );
}
export function useGetTokensRawLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokensRawQuery,
    GetTokensRawQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokensRawQuery, GetTokensRawQueryVariables>(
    GetTokensRawDocument,
    options
  );
}
export type GetTokensRawQueryHookResult = ReturnType<
  typeof useGetTokensRawQuery
>;
export type GetTokensRawLazyQueryHookResult = ReturnType<
  typeof useGetTokensRawLazyQuery
>;
export type GetTokensRawQueryResult = Apollo.QueryResult<
  GetTokensRawQuery,
  GetTokensRawQueryVariables
>;
export const GetVaultDocument = gql`
  query GetVault($address: Bytes!) {
    vaults_56(where: { address: $address }) {
      id
      address
      chainId @client @staticIntValue(value: 56)
      owners {
        address
      }
    }
  }
`;

/**
 * __useGetVaultQuery__
 *
 * To run a query within a React component, call `useGetVaultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaultQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGetVaultQuery(
  baseOptions: Apollo.QueryHookOptions<GetVaultQuery, GetVaultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVaultQuery, GetVaultQueryVariables>(
    GetVaultDocument,
    options
  );
}
export function useGetVaultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVaultQuery,
    GetVaultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVaultQuery, GetVaultQueryVariables>(
    GetVaultDocument,
    options
  );
}
export type GetVaultQueryHookResult = ReturnType<typeof useGetVaultQuery>;
export type GetVaultLazyQueryHookResult = ReturnType<
  typeof useGetVaultLazyQuery
>;
export type GetVaultQueryResult = Apollo.QueryResult<
  GetVaultQuery,
  GetVaultQueryVariables
>;
export const GetVaultOwnersDocument = gql`
  query GetVaultOwners($address: Bytes!) {
    vaultOwners_56(where: { address: $address }) {
      address
      vault {
        chainId @client @staticIntValue(value: 56)
        address
        singleton
      }
    }
  }
`;

/**
 * __useGetVaultOwnersQuery__
 *
 * To run a query within a React component, call `useGetVaultOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaultOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaultOwnersQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useGetVaultOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVaultOwnersQuery,
    GetVaultOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVaultOwnersQuery, GetVaultOwnersQueryVariables>(
    GetVaultOwnersDocument,
    options
  );
}
export function useGetVaultOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVaultOwnersQuery,
    GetVaultOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVaultOwnersQuery, GetVaultOwnersQueryVariables>(
    GetVaultOwnersDocument,
    options
  );
}
export type GetVaultOwnersQueryHookResult = ReturnType<
  typeof useGetVaultOwnersQuery
>;
export type GetVaultOwnersLazyQueryHookResult = ReturnType<
  typeof useGetVaultOwnersLazyQuery
>;
export type GetVaultOwnersQueryResult = Apollo.QueryResult<
  GetVaultOwnersQuery,
  GetVaultOwnersQueryVariables
>;
export const GetArticleThumbnailsDocument = gql`
  query GetArticleThumbnails(
    $filters: ArticleFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    articles(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          slug
          thumbnail {
            title
            description
            media {
              data {
                ...UploadFileFragment
              }
            }
          }
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetArticleThumbnailsQuery__
 *
 * To run a query within a React component, call `useGetArticleThumbnailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleThumbnailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleThumbnailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetArticleThumbnailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArticleThumbnailsQuery,
    GetArticleThumbnailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArticleThumbnailsQuery,
    GetArticleThumbnailsQueryVariables
  >(GetArticleThumbnailsDocument, options);
}
export function useGetArticleThumbnailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticleThumbnailsQuery,
    GetArticleThumbnailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArticleThumbnailsQuery,
    GetArticleThumbnailsQueryVariables
  >(GetArticleThumbnailsDocument, options);
}
export type GetArticleThumbnailsQueryHookResult = ReturnType<
  typeof useGetArticleThumbnailsQuery
>;
export type GetArticleThumbnailsLazyQueryHookResult = ReturnType<
  typeof useGetArticleThumbnailsLazyQuery
>;
export type GetArticleThumbnailsQueryResult = Apollo.QueryResult<
  GetArticleThumbnailsQuery,
  GetArticleThumbnailsQueryVariables
>;
export const GetGlobalMetadataDocument = gql`
  query GetGlobalMetadata {
    global {
      data {
        attributes {
          seo {
            id
            metaTitle
            metaDescription
            metaImage {
              data {
                ...UploadFileFragment
              }
            }
            metaSocial {
              id
              socialNetwork
              title
              description
              image {
                data {
                  ...UploadFileFragment
                }
              }
            }
            keywords
            metaRobots
            structuredData
            metaViewport
            canonicalURL
          }
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetGlobalMetadataQuery__
 *
 * To run a query within a React component, call `useGetGlobalMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalMetadataQuery,
    GetGlobalMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGlobalMetadataQuery,
    GetGlobalMetadataQueryVariables
  >(GetGlobalMetadataDocument, options);
}
export function useGetGlobalMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalMetadataQuery,
    GetGlobalMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGlobalMetadataQuery,
    GetGlobalMetadataQueryVariables
  >(GetGlobalMetadataDocument, options);
}
export type GetGlobalMetadataQueryHookResult = ReturnType<
  typeof useGetGlobalMetadataQuery
>;
export type GetGlobalMetadataLazyQueryHookResult = ReturnType<
  typeof useGetGlobalMetadataLazyQuery
>;
export type GetGlobalMetadataQueryResult = Apollo.QueryResult<
  GetGlobalMetadataQuery,
  GetGlobalMetadataQueryVariables
>;
export const GetMarketplaceGamesMetadataDocument = gql`
  query GetMarketplaceGamesMetadata {
    global {
      data {
        attributes {
          games {
            id
            metaTitle
            metaDescription
            metaImage {
              data {
                ...UploadFileFragment
              }
            }
            metaSocial {
              id
              socialNetwork
              title
              description
              image {
                data {
                  ...UploadFileFragment
                }
              }
            }
            keywords
            metaRobots
            structuredData
            metaViewport
            canonicalURL
          }
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetMarketplaceGamesMetadataQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceGamesMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceGamesMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceGamesMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketplaceGamesMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketplaceGamesMetadataQuery,
    GetMarketplaceGamesMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketplaceGamesMetadataQuery,
    GetMarketplaceGamesMetadataQueryVariables
  >(GetMarketplaceGamesMetadataDocument, options);
}
export function useGetMarketplaceGamesMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketplaceGamesMetadataQuery,
    GetMarketplaceGamesMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketplaceGamesMetadataQuery,
    GetMarketplaceGamesMetadataQueryVariables
  >(GetMarketplaceGamesMetadataDocument, options);
}
export type GetMarketplaceGamesMetadataQueryHookResult = ReturnType<
  typeof useGetMarketplaceGamesMetadataQuery
>;
export type GetMarketplaceGamesMetadataLazyQueryHookResult = ReturnType<
  typeof useGetMarketplaceGamesMetadataLazyQuery
>;
export type GetMarketplaceGamesMetadataQueryResult = Apollo.QueryResult<
  GetMarketplaceGamesMetadataQuery,
  GetMarketplaceGamesMetadataQueryVariables
>;
export const GetMarketplaceNftMetadataDocument = gql`
  query GetMarketplaceNFTMetadata {
    global {
      data {
        attributes {
          nfts {
            id
            metaTitle
            metaDescription
            metaImage {
              data {
                ...UploadFileFragment
              }
            }
            metaSocial {
              id
              socialNetwork
              title
              description
              image {
                data {
                  ...UploadFileFragment
                }
              }
            }
            keywords
            metaRobots
            structuredData
            metaViewport
            canonicalURL
          }
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetMarketplaceNftMetadataQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceNftMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceNftMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceNftMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketplaceNftMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketplaceNftMetadataQuery,
    GetMarketplaceNftMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketplaceNftMetadataQuery,
    GetMarketplaceNftMetadataQueryVariables
  >(GetMarketplaceNftMetadataDocument, options);
}
export function useGetMarketplaceNftMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketplaceNftMetadataQuery,
    GetMarketplaceNftMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketplaceNftMetadataQuery,
    GetMarketplaceNftMetadataQueryVariables
  >(GetMarketplaceNftMetadataDocument, options);
}
export type GetMarketplaceNftMetadataQueryHookResult = ReturnType<
  typeof useGetMarketplaceNftMetadataQuery
>;
export type GetMarketplaceNftMetadataLazyQueryHookResult = ReturnType<
  typeof useGetMarketplaceNftMetadataLazyQuery
>;
export type GetMarketplaceNftMetadataQueryResult = Apollo.QueryResult<
  GetMarketplaceNftMetadataQuery,
  GetMarketplaceNftMetadataQueryVariables
>;
export const GetGameDocument = gql`
  query GetGame($filters: GameFiltersInput) {
    games(filters: $filters) {
      data {
        id
        attributes {
          title
          thumbnail {
            data {
              ...UploadFileFragment
            }
          }
          chains {
            data {
              attributes {
                name
                chainId
              }
            }
          }
          genres {
            data {
              attributes {
                name
              }
            }
          }
          categories {
            data {
              attributes {
                name
              }
            }
          }
          devices {
            data {
              id
              attributes {
                name
              }
            }
          }
          slug
          background {
            data {
              ...UploadFileFragment
            }
          }
          requirements
          description
          investment
          provider
          faq {
            id
            title
            answer
          }
          links {
            id
            type
            url
          }
          releaseDate
          comingSoon
          seo {
            metaTitle
            metaDescription
            metaImage {
              data {
                ...UploadFileFragment
              }
            }
            keywords
            metaRobots
            metaSocial {
              description
              image {
                data {
                  ...UploadFileFragment
                }
              }
              socialNetwork
              title
            }
            metaViewport
            structuredData
          }
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetGameQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameQuery, GetGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameQuery, GetGameQueryVariables>(
    GetGameDocument,
    options
  );
}
export function useGetGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameQuery, GetGameQueryVariables>(
    GetGameDocument,
    options
  );
}
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = Apollo.QueryResult<
  GetGameQuery,
  GetGameQueryVariables
>;
export const GetGamesDocument = gql`
  query GetGames(
    $filters: GameFiltersInput
    $sort: [String!]
    $pagination: PaginationArg
  ) {
    games(filters: $filters, sort: $sort, pagination: $pagination) {
      data {
        id
        attributes {
          title
          slug
          thumbnail {
            data {
              ...UploadFileFragment
            }
          }
          chains {
            data {
              attributes {
                chainId
              }
            }
          }
          genres {
            data {
              attributes {
                name
              }
            }
          }
          categories {
            data {
              attributes {
                name
              }
            }
          }
          devices {
            data {
              id
              attributes {
                name
              }
            }
          }
          comingSoon
          priority
          provider
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetGamesQuery__
 *
 * To run a query within a React component, call `useGetGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetGamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGamesQuery, GetGamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGamesQuery, GetGamesQueryVariables>(
    GetGamesDocument,
    options
  );
}
export function useGetGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesQuery,
    GetGamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGamesQuery, GetGamesQueryVariables>(
    GetGamesDocument,
    options
  );
}
export type GetGamesQueryHookResult = ReturnType<typeof useGetGamesQuery>;
export type GetGamesLazyQueryHookResult = ReturnType<
  typeof useGetGamesLazyQuery
>;
export type GetGamesQueryResult = Apollo.QueryResult<
  GetGamesQuery,
  GetGamesQueryVariables
>;
export const GetGamesFiltersDocument = gql`
  query GetGamesFilters($pagination: PaginationArg) {
    chains {
      data {
        attributes {
          chainId
          name
        }
      }
    }
    gameDevices(pagination: $pagination) {
      data {
        attributes {
          name
        }
      }
    }
    gameGenres(pagination: $pagination) {
      data {
        attributes {
          name
        }
      }
    }
    gameCategories(pagination: $pagination) {
      data {
        attributes {
          name
        }
      }
    }
    gameStatuses {
      data {
        attributes {
          name
        }
      }
    }
  }
`;

/**
 * __useGetGamesFiltersQuery__
 *
 * To run a query within a React component, call `useGetGamesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesFiltersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetGamesFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGamesFiltersQuery,
    GetGamesFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGamesFiltersQuery, GetGamesFiltersQueryVariables>(
    GetGamesFiltersDocument,
    options
  );
}
export function useGetGamesFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesFiltersQuery,
    GetGamesFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGamesFiltersQuery,
    GetGamesFiltersQueryVariables
  >(GetGamesFiltersDocument, options);
}
export type GetGamesFiltersQueryHookResult = ReturnType<
  typeof useGetGamesFiltersQuery
>;
export type GetGamesFiltersLazyQueryHookResult = ReturnType<
  typeof useGetGamesFiltersLazyQuery
>;
export type GetGamesFiltersQueryResult = Apollo.QueryResult<
  GetGamesFiltersQuery,
  GetGamesFiltersQueryVariables
>;
export const GetArticlesDocument = gql`
  query GetArticles(
    $filters: ArticleFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    articles(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          ...ArticleAttributesFragment
        }
      }
    }
  }
  ${ArticleAttributesFragmentFragmentDoc}
`;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetArticlesQuery,
    GetArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    options
  );
}
export function useGetArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesQuery,
    GetArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    options
  );
}
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<
  typeof useGetArticlesLazyQuery
>;
export type GetArticlesQueryResult = Apollo.QueryResult<
  GetArticlesQuery,
  GetArticlesQueryVariables
>;
export const GetGuidesMenuDocument = gql`
  query GetGuidesMenu {
    menus(filters: { name: { eq: "guides" } }) {
      data {
        attributes {
          name
          items {
            title
            article {
              data {
                attributes {
                  title
                  slug
                }
              }
            }
            items {
              title
              article {
                data {
                  attributes {
                    title
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetGuidesMenuQuery__
 *
 * To run a query within a React component, call `useGetGuidesMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuidesMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuidesMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGuidesMenuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGuidesMenuQuery,
    GetGuidesMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGuidesMenuQuery, GetGuidesMenuQueryVariables>(
    GetGuidesMenuDocument,
    options
  );
}
export function useGetGuidesMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGuidesMenuQuery,
    GetGuidesMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGuidesMenuQuery, GetGuidesMenuQueryVariables>(
    GetGuidesMenuDocument,
    options
  );
}
export type GetGuidesMenuQueryHookResult = ReturnType<
  typeof useGetGuidesMenuQuery
>;
export type GetGuidesMenuLazyQueryHookResult = ReturnType<
  typeof useGetGuidesMenuLazyQuery
>;
export type GetGuidesMenuQueryResult = Apollo.QueryResult<
  GetGuidesMenuQuery,
  GetGuidesMenuQueryVariables
>;
export const EcosystemPartnersDocument = gql`
  query EcosystemPartners(
    $sort: [String]
    $pagination: PaginationArg
    $filters: EcosystemPartnerFiltersInput
  ) {
    ecosystemPartners(sort: $sort, pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          ecosystem_partner_categories {
            data {
              id
              attributes {
                Name
                createdAt
                updatedAt
                publishedAt
              }
            }
          }
          description
          name
          url
          logo {
            data {
              id
              attributes {
                name
                alternativeText
                caption
                width
                height
                formats
                hash
                ext
                mime
                size
                url
                previewUrl
                provider
                provider_metadata
                createdAt
                updatedAt
              }
            }
          }
          landing_logo {
            data {
              id
              attributes {
                name
                alternativeText
                caption
                width
                height
                formats
                hash
                ext
                mime
                size
                url
                previewUrl
                provider
                provider_metadata
                createdAt
                updatedAt
              }
            }
          }
          priority
          createdAt
          updatedAt
          publishedAt
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

/**
 * __useEcosystemPartnersQuery__
 *
 * To run a query within a React component, call `useEcosystemPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcosystemPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcosystemPartnersQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEcosystemPartnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EcosystemPartnersQuery,
    EcosystemPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EcosystemPartnersQuery,
    EcosystemPartnersQueryVariables
  >(EcosystemPartnersDocument, options);
}
export function useEcosystemPartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EcosystemPartnersQuery,
    EcosystemPartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EcosystemPartnersQuery,
    EcosystemPartnersQueryVariables
  >(EcosystemPartnersDocument, options);
}
export type EcosystemPartnersQueryHookResult = ReturnType<
  typeof useEcosystemPartnersQuery
>;
export type EcosystemPartnersLazyQueryHookResult = ReturnType<
  typeof useEcosystemPartnersLazyQuery
>;
export type EcosystemPartnersQueryResult = Apollo.QueryResult<
  EcosystemPartnersQuery,
  EcosystemPartnersQueryVariables
>;
export const GetTasksDocument = gql`
  query GetTasks(
    $filters: TaskFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    tasks(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          description
          endTimestamp
          maxValue
          rewardContract
          rewardToken
          rewardValue
          startTimestamp
          taskId
          title
        }
      }
    }
  }
`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options
  );
}
export function useGetTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksQuery,
    GetTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(
    GetTasksDocument,
    options
  );
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<
  typeof useGetTasksLazyQuery
>;
export type GetTasksQueryResult = Apollo.QueryResult<
  GetTasksQuery,
  GetTasksQueryVariables
>;
export const GetTradingCompetitionsDocument = gql`
  query GetTradingCompetitions(
    $filters: TradeCompetitionFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    tradeCompetitions(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        attributes {
          name
          description
          endTimestamp
          startTimestamp
          rewardContractAddress
          taskId
          rewardGroups {
            id
            minPlace
            maxPlace
            rewardToken
            rewardValue
          }
          chainId
          game {
            data {
              attributes {
                title
                provider
                slug
                icon {
                  data {
                    ...UploadFileFragment
                  }
                }
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
        }
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
  ${UploadFileFragmentFragmentDoc}
`;

/**
 * __useGetTradingCompetitionsQuery__
 *
 * To run a query within a React component, call `useGetTradingCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradingCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradingCompetitionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTradingCompetitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTradingCompetitionsQuery,
    GetTradingCompetitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTradingCompetitionsQuery,
    GetTradingCompetitionsQueryVariables
  >(GetTradingCompetitionsDocument, options);
}
export function useGetTradingCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTradingCompetitionsQuery,
    GetTradingCompetitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTradingCompetitionsQuery,
    GetTradingCompetitionsQueryVariables
  >(GetTradingCompetitionsDocument, options);
}
export type GetTradingCompetitionsQueryHookResult = ReturnType<
  typeof useGetTradingCompetitionsQuery
>;
export type GetTradingCompetitionsLazyQueryHookResult = ReturnType<
  typeof useGetTradingCompetitionsLazyQuery
>;
export type GetTradingCompetitionsQueryResult = Apollo.QueryResult<
  GetTradingCompetitionsQuery,
  GetTradingCompetitionsQueryVariables
>;
export const TradeTransactionsDocument = gql`
  query TradeTransactions(
    $where: TradeTransaction_filter
    $orderBy: TradeTransaction_orderBy
    $first: Int
    $skip: Int
    $orderDirection: OrderDirection
  ) {
    tradeTransactions_56(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...TradeTransactionFragment
      chainId @client @staticIntValue(value: 56)
    }
    tradeTransactions_1(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
      orderDirection: $orderDirection
    ) {
      ...TradeTransactionFragment
      chainId @client @staticIntValue(value: 1)
    }
  }
  ${TradeTransactionFragmentFragmentDoc}
`;

/**
 * __useTradeTransactionsQuery__
 *
 * To run a query within a React component, call `useTradeTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useTradeTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TradeTransactionsQuery,
    TradeTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TradeTransactionsQuery,
    TradeTransactionsQueryVariables
  >(TradeTransactionsDocument, options);
}
export function useTradeTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeTransactionsQuery,
    TradeTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeTransactionsQuery,
    TradeTransactionsQueryVariables
  >(TradeTransactionsDocument, options);
}
export type TradeTransactionsQueryHookResult = ReturnType<
  typeof useTradeTransactionsQuery
>;
export type TradeTransactionsLazyQueryHookResult = ReturnType<
  typeof useTradeTransactionsLazyQuery
>;
export type TradeTransactionsQueryResult = Apollo.QueryResult<
  TradeTransactionsQuery,
  TradeTransactionsQueryVariables
>;
export type GetMoreNftsQueryVariables = Exact<{
  where?: InputMaybe<Nft_Filter>;
  orderBy?: InputMaybe<OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  requestId?: InputMaybe<Scalars["String"]>;
}>;

export type GetMoreNftsQuery = {
  __typename?: "Query";
  nftFiltered: {
    __typename?: "NftResponseDto";
    total?: number | null;
    nft?: Array<{
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    }> | null;
    aggregations?: {
      __typename?: "NftAggregationDto";
      providers?: Array<{
        __typename?: "ProviderAggregationDto";
        provider?: string | null;
        count?: number | null;
      }> | null;
    } | null;
  };
};

export type GetOurPicksNftsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOurPicksNftsQuery = {
  __typename?: "Query";
  first: {
    __typename?: "NftResponseDto";
    nft?: Array<{
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    }> | null;
  };
  second: {
    __typename?: "NftResponseDto";
    nft?: Array<{
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    }> | null;
  };
  third: {
    __typename?: "NftResponseDto";
    nft?: Array<{
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    }> | null;
  };
};

export type GetMarketplaceFiltersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMarketplaceFiltersQuery = {
  __typename?: "Query";
  games?: {
    __typename?: "GameEntityResponseCollection";
    data: Array<{
      __typename?: "GameEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Game";
        title: string;
        provider?: string | null;
      } | null;
    }>;
  } | null;
  chains?: {
    __typename?: "ChainEntityResponseCollection";
    data: Array<{
      __typename?: "ChainEntity";
      attributes?: {
        __typename?: "Chain";
        name?: string | null;
        chainId?: number | null;
      } | null;
    }>;
  } | null;
};

export type PropertiesQueryVariables = Exact<{
  provider: Scalars["String"];
  chainId: Scalars["Float"];
  contract: Scalars["String"];
}>;

export type PropertiesQuery = {
  __typename?: "Query";
  properties?: Array<{
    __typename?: "PropertyDto";
    id?: string | null;
    name: string;
    dataType?: DataType | null;
    minValue?: string | null;
    maxValue?: string | null;
  }> | null;
};

export type PropertyValuesFilteredQueryVariables = Exact<{
  propertyId: Scalars["String"];
  input: Scalars["String"];
  first?: InputMaybe<Scalars["Float"]>;
  skip?: InputMaybe<Scalars["Float"]>;
}>;

export type PropertyValuesFilteredQuery = {
  __typename?: "Query";
  propertyValuesFiltered?: Array<{
    __typename?: "PropertyValueDto";
    id: string;
    valueInt?: number | null;
    valueFloat?: number | null;
    valueString: string;
  }> | null;
};

export type GetTradeCompetitionStatusQueryVariables = Exact<{
  provider: NftProvider;
  chain: Scalars["Int"];
  userAddress: Scalars["String"];
  startTimestamp: Scalars["DateTime"];
  endTimestamp?: InputMaybe<Scalars["DateTime"]>;
}>;

export type GetTradeCompetitionStatusQuery = {
  __typename?: "Query";
  tradeCompetitionStatus: {
    __typename?: "CompetitionResultDto";
    competitionId?: string | null;
    provider: NftProvider;
    startTimestamp: any;
    endTimestamp: any;
    requestedUser?: {
      __typename?: "CompetitionUserResultDto";
      place: number;
      userAddress: string;
      volumeUsd?: number | null;
    } | null;
    topUsers: Array<{
      __typename?: "CompetitionUserResultDto";
      place: number;
      userAddress: string;
      volumeUsd?: number | null;
    }>;
  };
};

export type GetAssetQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetAssetQuery = {
  __typename?: "Query";
  nftById?: {
    __typename?: "NftDto";
    id: string;
    name?: string | null;
    image?: string | null;
    chainId: number;
    contractAddress: string;
    tokenId: string;
    protocol?: string | null;
    owner?: string | null;
    metadata?: Array<{
      __typename?: "MetadataPropertyDto";
      trait_type: string;
      display_type: string;
      value?: string | null;
    }> | null;
    properties?: Array<{
      __typename?: "PropertyDto";
      name: string;
      maxValue?: string | null;
    } | null> | null;
    tradeListing?: Array<{
      __typename?: "TradeListingDto";
      id: string;
      published?: boolean | null;
      quantity?: number | null;
      pricePerItem?: string | null;
      pricePerItemUsd?: string | null;
      paymentToken?: string | null;
      contract: string;
      nonce?: string | null;
    }> | null;
    rentListing?: {
      __typename?: "RentListingDto";
      id: string;
      finalDuration?: number | null;
      minDuration?: number | null;
      maxDuration?: number | null;
      owner: string;
      borrower?: string | null;
      paymentToken: string;
      finalPaymentValue?: string | null;
      pricePerSecond?: string | null;
      paymentTokenName?: string | null;
      status?: string | null;
      updatedAt: number;
      nonce: string;
      borrowedAt?: number | null;
    } | null;
  } | null;
};

export type GetAssetMetadataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetAssetMetadataQuery = {
  __typename?: "Query";
  nftById?: {
    __typename?: "NftDto";
    name?: string | null;
    tokenId: string;
    image?: string | null;
    provider: string;
    contractAddress: string;
  } | null;
};

export type GetAssetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Nft_Filter>;
  orderBy?: InputMaybe<OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  onlyTradeListings?: InputMaybe<Scalars["Boolean"]>;
  onlyRentListings?: InputMaybe<Scalars["Boolean"]>;
  requestId?: InputMaybe<Scalars["String"]>;
}>;

export type GetAssetsQuery = {
  __typename?: "Query";
  nftFiltered: {
    __typename?: "NftResponseDto";
    total?: number | null;
    outdatedRequestId?: boolean | null;
    nft?: Array<{
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    }> | null;
    aggregations?: {
      __typename?: "NftAggregationDto";
      providers?: Array<{
        __typename?: "ProviderAggregationDto";
        provider?: string | null;
        count?: number | null;
      }> | null;
    } | null;
  };
};

export type GetCoinRatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoinRatesQuery = {
  __typename?: "Query";
  coinRates: Array<{
    __typename?: "CoinRateDto";
    usdRate: string;
    paymentTokenName: string;
  }>;
};

export type GetMaxSyncBlocksQueryVariables = Exact<{ [key: string]: never }>;

export type GetMaxSyncBlocksQuery = {
  __typename?: "Query";
  maxSyncBlocks: Array<{
    __typename?: "MaxSyncBlock";
    chainId: number;
    maxSyncBlock: number;
  }>;
  tradeMaxSyncBlocks: Array<{
    __typename?: "MaxSyncBlock";
    chainId: number;
    maxSyncBlock: number;
  }>;
  tradeBundleMaxSyncBlocks: Array<{
    __typename?: "MaxSyncBlock";
    chainId: number;
    maxSyncBlock: number;
  }>;
};

export type GetMetaBlockQueryVariables = Exact<{ [key: string]: never }>;

export type GetMetaBlockQuery = {
  __typename?: "Query";
  _meta_56?: {
    __typename?: "_Meta_";
    chainId: number;
    block: { __typename?: "_Block_"; number: number };
  } | null;
};

export type Erc721TokenFragmentFragment = {
  __typename?: "ERC721Token";
  id: string;
  identifier: any;
  uri?: string | null;
  contract: { __typename?: "ERC721Contract"; id: string };
  owner: { __typename?: "Account"; id: string };
  nftById?: {
    __typename?: "NftDto";
    id: string;
    name?: string | null;
    image?: string | null;
    chainId: number;
    contractAddress: string;
    tokenId: string;
    protocol?: string | null;
    owner?: string | null;
    tradeListing?: Array<{
      __typename?: "TradeListingDto";
      id: string;
      published?: boolean | null;
      quantity?: number | null;
      pricePerItem?: string | null;
      pricePerItemUsd?: string | null;
      paymentToken?: string | null;
      contract: string;
      nonce?: string | null;
    }> | null;
    rentListing?: {
      __typename?: "RentListingDto";
      id: string;
      finalDuration?: number | null;
      minDuration?: number | null;
      maxDuration?: number | null;
      owner: string;
      borrower?: string | null;
      paymentToken: string;
      finalPaymentValue?: string | null;
      pricePerSecond?: string | null;
      paymentTokenName?: string | null;
      status?: string | null;
      updatedAt: number;
      nonce: string;
      borrowedAt?: number | null;
    } | null;
  } | null;
};

export type GetTokenQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTokenQuery = {
  __typename?: "Query";
  erc721Token_56?: {
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    } | null;
  } | null;
  erc1155Token_56?: {
    __typename?: "ERC1155Token";
    id: string;
    identifier: any;
    contract: { __typename?: "ERC1155Contract"; id: string };
    balances: Array<{
      __typename?: "ERC1155Balance";
      account?: { __typename?: "Account"; id: string } | null;
    }>;
    nftById?: {
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetTokensByOwnersQueryVariables = Exact<{
  owners: Array<Scalars["String"]>;
}>;

export type GetTokensByOwnersQuery = {
  __typename?: "Query";
  erc721Tokens_56: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      name?: string | null;
      image?: string | null;
      protocol?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
      }> | null;
    } | null;
  }>;
  erc721Tokens_1: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      name?: string | null;
      image?: string | null;
      protocol?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
      }> | null;
    } | null;
  }>;
  erc1155Balances_56: Array<{
    __typename?: "ERC1155Balance";
    account?: { __typename?: "Account"; id: string } | null;
    token: {
      __typename?: "ERC1155Token";
      id: string;
      identifier: any;
      uri?: string | null;
      nftById?: {
        __typename?: "NftDto";
        name?: string | null;
        image?: string | null;
        protocol?: string | null;
        tradeListing?: Array<{
          __typename?: "TradeListingDto";
          id: string;
          published?: boolean | null;
          quantity?: number | null;
        }> | null;
      } | null;
    };
    contract: { __typename?: "ERC1155Contract"; id: string };
  }>;
};

export type GetTokensQueryVariables = Exact<{
  owner: Scalars["String"];
}>;

export type GetTokensQuery = {
  __typename?: "Query";
  erc721Tokens_56: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    } | null;
  }>;
  erc721Tokens_1: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      id: string;
      name?: string | null;
      image?: string | null;
      chainId: number;
      contractAddress: string;
      tokenId: string;
      protocol?: string | null;
      owner?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
        pricePerItem?: string | null;
        pricePerItemUsd?: string | null;
        paymentToken?: string | null;
        contract: string;
        nonce?: string | null;
      }> | null;
      rentListing?: {
        __typename?: "RentListingDto";
        id: string;
        finalDuration?: number | null;
        minDuration?: number | null;
        maxDuration?: number | null;
        owner: string;
        borrower?: string | null;
        paymentToken: string;
        finalPaymentValue?: string | null;
        pricePerSecond?: string | null;
        paymentTokenName?: string | null;
        status?: string | null;
        updatedAt: number;
        nonce: string;
        borrowedAt?: number | null;
      } | null;
    } | null;
  }>;
  erc1155Balances_56: Array<{
    __typename?: "ERC1155Balance";
    account?: { __typename?: "Account"; id: string } | null;
    token: {
      __typename?: "ERC1155Token";
      id: string;
      identifier: any;
      uri?: string | null;
      nftById?: {
        __typename?: "NftDto";
        id: string;
        name?: string | null;
        image?: string | null;
        chainId: number;
        contractAddress: string;
        tokenId: string;
        protocol?: string | null;
        owner?: string | null;
        tradeListing?: Array<{
          __typename?: "TradeListingDto";
          id: string;
          published?: boolean | null;
          quantity?: number | null;
          pricePerItem?: string | null;
          pricePerItemUsd?: string | null;
          paymentToken?: string | null;
          contract: string;
          nonce?: string | null;
        }> | null;
        rentListing?: {
          __typename?: "RentListingDto";
          id: string;
          finalDuration?: number | null;
          minDuration?: number | null;
          maxDuration?: number | null;
          owner: string;
          borrower?: string | null;
          paymentToken: string;
          finalPaymentValue?: string | null;
          pricePerSecond?: string | null;
          paymentTokenName?: string | null;
          status?: string | null;
          updatedAt: number;
          nonce: string;
          borrowedAt?: number | null;
        } | null;
      } | null;
    };
    contract: { __typename?: "ERC1155Contract"; id: string };
  }>;
};

export type GetTokensRawQueryVariables = Exact<{
  where721?: InputMaybe<Erc721Token_Filter>;
  where1155?: InputMaybe<Erc1155Balance_Filter>;
}>;

export type GetTokensRawQuery = {
  __typename?: "Query";
  erc721Tokens_56: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      name?: string | null;
      image?: string | null;
      protocol?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
      }> | null;
    } | null;
  }>;
  erc721Tokens_1: Array<{
    __typename?: "ERC721Token";
    id: string;
    identifier: any;
    uri?: string | null;
    contract: { __typename?: "ERC721Contract"; id: string };
    owner: { __typename?: "Account"; id: string };
    nftById?: {
      __typename?: "NftDto";
      name?: string | null;
      image?: string | null;
      protocol?: string | null;
      tradeListing?: Array<{
        __typename?: "TradeListingDto";
        id: string;
        published?: boolean | null;
        quantity?: number | null;
      }> | null;
    } | null;
  }>;
  erc1155Balances_56: Array<{
    __typename?: "ERC1155Balance";
    account?: { __typename?: "Account"; id: string } | null;
    token: {
      __typename?: "ERC1155Token";
      id: string;
      identifier: any;
      uri?: string | null;
      nftById?: {
        __typename?: "NftDto";
        name?: string | null;
        image?: string | null;
        protocol?: string | null;
        tradeListing?: Array<{
          __typename?: "TradeListingDto";
          id: string;
          published?: boolean | null;
          quantity?: number | null;
        }> | null;
      } | null;
    };
    contract: { __typename?: "ERC1155Contract"; id: string };
  }>;
};

export type GetVaultQueryVariables = Exact<{
  address: Scalars["Bytes"];
}>;

export type GetVaultQuery = {
  __typename?: "Query";
  vaults_56: Array<{
    __typename?: "Vault";
    id: string;
    address: any;
    chainId: number;
    owners: Array<{ __typename?: "VaultOwner"; address: any }>;
  }>;
};

export type GetVaultOwnersQueryVariables = Exact<{
  address: Scalars["Bytes"];
}>;

export type GetVaultOwnersQuery = {
  __typename?: "Query";
  vaultOwners_56: Array<{
    __typename?: "VaultOwner";
    address: any;
    vault: {
      __typename?: "Vault";
      chainId: number;
      address: any;
      singleton: any;
    };
  }>;
};

export type GetArticleThumbnailsQueryVariables = Exact<{
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}>;

export type GetArticleThumbnailsQuery = {
  __typename?: "Query";
  articles?: {
    __typename?: "ArticleEntityResponseCollection";
    data: Array<{
      __typename?: "ArticleEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Article";
        slug?: string | null;
        thumbnail?: {
          __typename?: "ComponentSharedArticleThumbnail";
          title?: string | null;
          description?: string | null;
          media?: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetGlobalMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type GetGlobalMetadataQuery = {
  __typename?: "Query";
  global?: {
    __typename?: "GlobalEntityResponse";
    data?: {
      __typename?: "GlobalEntity";
      attributes?: {
        __typename?: "Global";
        seo?: {
          __typename?: "ComponentSharedSeo";
          id: string;
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          metaViewport?: string | null;
          canonicalURL?: string | null;
          metaImage: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          };
          metaSocial?: Array<{
            __typename?: "ComponentSharedMetaSocial";
            id: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  width?: number | null;
                  height?: number | null;
                  url: string;
                  previewUrl?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMarketplaceGamesMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMarketplaceGamesMetadataQuery = {
  __typename?: "Query";
  global?: {
    __typename?: "GlobalEntityResponse";
    data?: {
      __typename?: "GlobalEntity";
      attributes?: {
        __typename?: "Global";
        games?: {
          __typename?: "ComponentSharedSeo";
          id: string;
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          metaViewport?: string | null;
          canonicalURL?: string | null;
          metaImage: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          };
          metaSocial?: Array<{
            __typename?: "ComponentSharedMetaSocial";
            id: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  width?: number | null;
                  height?: number | null;
                  url: string;
                  previewUrl?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMarketplaceNftMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMarketplaceNftMetadataQuery = {
  __typename?: "Query";
  global?: {
    __typename?: "GlobalEntityResponse";
    data?: {
      __typename?: "GlobalEntity";
      attributes?: {
        __typename?: "Global";
        nfts?: {
          __typename?: "ComponentSharedSeo";
          id: string;
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          structuredData?: any | null;
          metaViewport?: string | null;
          canonicalURL?: string | null;
          metaImage: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          };
          metaSocial?: Array<{
            __typename?: "ComponentSharedMetaSocial";
            id: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            description: string;
            image?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  width?: number | null;
                  height?: number | null;
                  url: string;
                  previewUrl?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type ArticleAttributesFragmentFragment = {
  __typename?: "Article";
  title?: string | null;
  description?: string | null;
  slug?: string | null;
  publishedAt?: any | null;
  cover?: {
    __typename?: "UploadFileEntityResponse";
    data?: {
      __typename?: "UploadFileEntity";
      id?: string | null;
      attributes?: {
        __typename?: "UploadFile";
        name: string;
        width?: number | null;
        height?: number | null;
        url: string;
        previewUrl?: string | null;
      } | null;
    } | null;
  } | null;
  blocks?: Array<
    | {
        __typename?: "ComponentSharedMedia";
        id: string;
        file?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | {
        __typename?: "ComponentSharedQuote";
        id: string;
        title?: string | null;
        body?: string | null;
      }
    | {
        __typename?: "ComponentSharedRichText";
        id: string;
        body?: string | null;
      }
    | {
        __typename?: "ComponentSharedSlider";
        id: string;
        files?: {
          __typename?: "UploadFileRelationResponseCollection";
          data: Array<{
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          }>;
        } | null;
      }
    | {
        __typename?: "ComponentSharedTileGrid";
        id: string;
        direction?: Enum_Componentsharedtilegrid_Direction | null;
        title?: string | null;
        description?: string | null;
        media?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: "Error" }
    | null
  > | null;
  seo?: {
    __typename?: "ComponentSharedSeo";
    id: string;
    metaTitle: string;
    metaDescription: string;
    keywords?: string | null;
    metaRobots?: string | null;
    metaViewport?: string | null;
    structuredData?: any | null;
    metaImage: {
      __typename?: "UploadFileEntityResponse";
      data?: {
        __typename?: "UploadFileEntity";
        id?: string | null;
        attributes?: {
          __typename?: "UploadFile";
          name: string;
          width?: number | null;
          height?: number | null;
          url: string;
          previewUrl?: string | null;
        } | null;
      } | null;
    };
    metaSocial?: Array<{
      __typename?: "ComponentSharedMetaSocial";
      description: string;
      socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
      title: string;
      image?: {
        __typename?: "UploadFileEntityResponse";
        data?: {
          __typename?: "UploadFileEntity";
          id?: string | null;
          attributes?: {
            __typename?: "UploadFile";
            name: string;
            width?: number | null;
            height?: number | null;
            url: string;
            previewUrl?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  videoCover?: {
    __typename?: "UploadFileEntityResponse";
    data?: {
      __typename?: "UploadFileEntity";
      id?: string | null;
      attributes?: {
        __typename?: "UploadFile";
        name: string;
        width?: number | null;
        height?: number | null;
        url: string;
        previewUrl?: string | null;
      } | null;
    } | null;
  } | null;
  heroTiles?: Array<{
    __typename?: "ComponentSharedTileGrid";
    id: string;
    direction?: Enum_Componentsharedtilegrid_Direction | null;
    title?: string | null;
    description?: string | null;
    media?: {
      __typename?: "UploadFileEntityResponse";
      data?: {
        __typename?: "UploadFileEntity";
        id?: string | null;
        attributes?: {
          __typename?: "UploadFile";
          name: string;
          width?: number | null;
          height?: number | null;
          url: string;
          previewUrl?: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
  relatedArticles?: {
    __typename?: "ComponentSharedRelatedArticles";
    id: string;
    title?: string | null;
    description?: string | null;
    url?: string | null;
    articles?: Array<{
      __typename?: "ComponentSharedMenuItemPrimitive";
      id: string;
      title?: string | null;
      article?: {
        __typename?: "ArticleEntityResponse";
        data?: {
          __typename?: "ArticleEntity";
          id?: string | null;
          attributes?: {
            __typename?: "Article";
            slug?: string | null;
            thumbnail?: {
              __typename?: "ComponentSharedArticleThumbnail";
              title?: string | null;
              description?: string | null;
              media?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: {
                    __typename?: "UploadFile";
                    name: string;
                    width?: number | null;
                    height?: number | null;
                    url: string;
                    previewUrl?: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  learnMore?: {
    __typename?: "ComponentSharedLinkButtonWithDescription";
    id: string;
    buttonTitle?: string | null;
    description?: string | null;
    href?: string | null;
  } | null;
  startButton?: {
    __typename?: "ComponentSharedLinkButtonWithDescription";
    id: string;
    buttonTitle?: string | null;
    description?: string | null;
    href?: string | null;
  } | null;
};

export type UploadFileFragmentFragment = {
  __typename?: "UploadFileEntity";
  id?: string | null;
  attributes?: {
    __typename?: "UploadFile";
    name: string;
    width?: number | null;
    height?: number | null;
    url: string;
    previewUrl?: string | null;
  } | null;
};

export type GetGameQueryVariables = Exact<{
  filters?: InputMaybe<GameFiltersInput>;
}>;

export type GetGameQuery = {
  __typename?: "Query";
  games?: {
    __typename?: "GameEntityResponseCollection";
    data: Array<{
      __typename?: "GameEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Game";
        title: string;
        slug: string;
        requirements?: string | null;
        description?: string | null;
        investment?: number | null;
        provider?: string | null;
        releaseDate?: any | null;
        comingSoon?: boolean | null;
        thumbnail?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
        chains?: {
          __typename?: "ChainRelationResponseCollection";
          data: Array<{
            __typename?: "ChainEntity";
            attributes?: {
              __typename?: "Chain";
              name?: string | null;
              chainId?: number | null;
            } | null;
          }>;
        } | null;
        genres?: {
          __typename?: "GameGenreRelationResponseCollection";
          data: Array<{
            __typename?: "GameGenreEntity";
            attributes?: {
              __typename?: "GameGenre";
              name?: string | null;
            } | null;
          }>;
        } | null;
        categories?: {
          __typename?: "GameCategoryRelationResponseCollection";
          data: Array<{
            __typename?: "GameCategoryEntity";
            attributes?: {
              __typename?: "GameCategory";
              name?: string | null;
            } | null;
          }>;
        } | null;
        devices?: {
          __typename?: "GameDeviceRelationResponseCollection";
          data: Array<{
            __typename?: "GameDeviceEntity";
            id?: string | null;
            attributes?: {
              __typename?: "GameDevice";
              name?: string | null;
            } | null;
          }>;
        } | null;
        background?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
        faq?: Array<{
          __typename?: "ComponentSharedFaqItem";
          id: string;
          title?: string | null;
          answer?: string | null;
        } | null> | null;
        links?: Array<{
          __typename?: "ComponentSharedSocialNetwork";
          id: string;
          type?: Enum_Componentsharedsocialnetwork_Type | null;
          url: string;
        } | null> | null;
        seo?: {
          __typename?: "ComponentSharedSeo";
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          };
          metaSocial?: Array<{
            __typename?: "ComponentSharedMetaSocial";
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            image?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  width?: number | null;
                  height?: number | null;
                  url: string;
                  previewUrl?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetGamesQueryVariables = Exact<{
  filters?: InputMaybe<GameFiltersInput>;
  sort?: InputMaybe<Array<Scalars["String"]>>;
  pagination?: InputMaybe<PaginationArg>;
}>;

export type GetGamesQuery = {
  __typename?: "Query";
  games?: {
    __typename?: "GameEntityResponseCollection";
    data: Array<{
      __typename?: "GameEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Game";
        title: string;
        slug: string;
        comingSoon?: boolean | null;
        priority?: number | null;
        provider?: string | null;
        thumbnail?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
        chains?: {
          __typename?: "ChainRelationResponseCollection";
          data: Array<{
            __typename?: "ChainEntity";
            attributes?: {
              __typename?: "Chain";
              chainId?: number | null;
            } | null;
          }>;
        } | null;
        genres?: {
          __typename?: "GameGenreRelationResponseCollection";
          data: Array<{
            __typename?: "GameGenreEntity";
            attributes?: {
              __typename?: "GameGenre";
              name?: string | null;
            } | null;
          }>;
        } | null;
        categories?: {
          __typename?: "GameCategoryRelationResponseCollection";
          data: Array<{
            __typename?: "GameCategoryEntity";
            attributes?: {
              __typename?: "GameCategory";
              name?: string | null;
            } | null;
          }>;
        } | null;
        devices?: {
          __typename?: "GameDeviceRelationResponseCollection";
          data: Array<{
            __typename?: "GameDeviceEntity";
            id?: string | null;
            attributes?: {
              __typename?: "GameDevice";
              name?: string | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    meta: {
      __typename?: "ResponseCollectionMeta";
      pagination: { __typename?: "Pagination"; total: number };
    };
  } | null;
};

export type GetGamesFiltersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
}>;

export type GetGamesFiltersQuery = {
  __typename?: "Query";
  chains?: {
    __typename?: "ChainEntityResponseCollection";
    data: Array<{
      __typename?: "ChainEntity";
      attributes?: {
        __typename?: "Chain";
        chainId?: number | null;
        name?: string | null;
      } | null;
    }>;
  } | null;
  gameDevices?: {
    __typename?: "GameDeviceEntityResponseCollection";
    data: Array<{
      __typename?: "GameDeviceEntity";
      attributes?: { __typename?: "GameDevice"; name?: string | null } | null;
    }>;
  } | null;
  gameGenres?: {
    __typename?: "GameGenreEntityResponseCollection";
    data: Array<{
      __typename?: "GameGenreEntity";
      attributes?: { __typename?: "GameGenre"; name?: string | null } | null;
    }>;
  } | null;
  gameCategories?: {
    __typename?: "GameCategoryEntityResponseCollection";
    data: Array<{
      __typename?: "GameCategoryEntity";
      attributes?: { __typename?: "GameCategory"; name?: string | null } | null;
    }>;
  } | null;
  gameStatuses?: {
    __typename?: "GameStatusEntityResponseCollection";
    data: Array<{
      __typename?: "GameStatusEntity";
      attributes?: { __typename?: "GameStatus"; name: string } | null;
    }>;
  } | null;
};

export type GetArticlesQueryVariables = Exact<{
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}>;

export type GetArticlesQuery = {
  __typename?: "Query";
  articles?: {
    __typename?: "ArticleEntityResponseCollection";
    data: Array<{
      __typename?: "ArticleEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Article";
        title?: string | null;
        description?: string | null;
        slug?: string | null;
        publishedAt?: any | null;
        cover?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
        blocks?: Array<
          | {
              __typename?: "ComponentSharedMedia";
              id: string;
              file?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: {
                    __typename?: "UploadFile";
                    name: string;
                    width?: number | null;
                    height?: number | null;
                    url: string;
                    previewUrl?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: "ComponentSharedQuote";
              id: string;
              title?: string | null;
              body?: string | null;
            }
          | {
              __typename?: "ComponentSharedRichText";
              id: string;
              body?: string | null;
            }
          | {
              __typename?: "ComponentSharedSlider";
              id: string;
              files?: {
                __typename?: "UploadFileRelationResponseCollection";
                data: Array<{
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: {
                    __typename?: "UploadFile";
                    name: string;
                    width?: number | null;
                    height?: number | null;
                    url: string;
                    previewUrl?: string | null;
                  } | null;
                }>;
              } | null;
            }
          | {
              __typename?: "ComponentSharedTileGrid";
              id: string;
              direction?: Enum_Componentsharedtilegrid_Direction | null;
              title?: string | null;
              description?: string | null;
              media?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: {
                    __typename?: "UploadFile";
                    name: string;
                    width?: number | null;
                    height?: number | null;
                    url: string;
                    previewUrl?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | { __typename?: "Error" }
          | null
        > | null;
        seo?: {
          __typename?: "ComponentSharedSeo";
          id: string;
          metaTitle: string;
          metaDescription: string;
          keywords?: string | null;
          metaRobots?: string | null;
          metaViewport?: string | null;
          structuredData?: any | null;
          metaImage: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          };
          metaSocial?: Array<{
            __typename?: "ComponentSharedMetaSocial";
            description: string;
            socialNetwork: Enum_Componentsharedmetasocial_Socialnetwork;
            title: string;
            image?: {
              __typename?: "UploadFileEntityResponse";
              data?: {
                __typename?: "UploadFileEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "UploadFile";
                  name: string;
                  width?: number | null;
                  height?: number | null;
                  url: string;
                  previewUrl?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        videoCover?: {
          __typename?: "UploadFileEntityResponse";
          data?: {
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              width?: number | null;
              height?: number | null;
              url: string;
              previewUrl?: string | null;
            } | null;
          } | null;
        } | null;
        heroTiles?: Array<{
          __typename?: "ComponentSharedTileGrid";
          id: string;
          direction?: Enum_Componentsharedtilegrid_Direction | null;
          title?: string | null;
          description?: string | null;
          media?: {
            __typename?: "UploadFileEntityResponse";
            data?: {
              __typename?: "UploadFileEntity";
              id?: string | null;
              attributes?: {
                __typename?: "UploadFile";
                name: string;
                width?: number | null;
                height?: number | null;
                url: string;
                previewUrl?: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        relatedArticles?: {
          __typename?: "ComponentSharedRelatedArticles";
          id: string;
          title?: string | null;
          description?: string | null;
          url?: string | null;
          articles?: Array<{
            __typename?: "ComponentSharedMenuItemPrimitive";
            id: string;
            title?: string | null;
            article?: {
              __typename?: "ArticleEntityResponse";
              data?: {
                __typename?: "ArticleEntity";
                id?: string | null;
                attributes?: {
                  __typename?: "Article";
                  slug?: string | null;
                  thumbnail?: {
                    __typename?: "ComponentSharedArticleThumbnail";
                    title?: string | null;
                    description?: string | null;
                    media?: {
                      __typename?: "UploadFileEntityResponse";
                      data?: {
                        __typename?: "UploadFileEntity";
                        id?: string | null;
                        attributes?: {
                          __typename?: "UploadFile";
                          name: string;
                          width?: number | null;
                          height?: number | null;
                          url: string;
                          previewUrl?: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        learnMore?: {
          __typename?: "ComponentSharedLinkButtonWithDescription";
          id: string;
          buttonTitle?: string | null;
          description?: string | null;
          href?: string | null;
        } | null;
        startButton?: {
          __typename?: "ComponentSharedLinkButtonWithDescription";
          id: string;
          buttonTitle?: string | null;
          description?: string | null;
          href?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetGuidesMenuQueryVariables = Exact<{ [key: string]: never }>;

export type GetGuidesMenuQuery = {
  __typename?: "Query";
  menus?: {
    __typename?: "MenuEntityResponseCollection";
    data: Array<{
      __typename?: "MenuEntity";
      attributes?: {
        __typename?: "Menu";
        name?: string | null;
        items?: Array<{
          __typename?: "ComponentSharedMenuItem";
          title?: string | null;
          article?: {
            __typename?: "ArticleEntityResponse";
            data?: {
              __typename?: "ArticleEntity";
              attributes?: {
                __typename?: "Article";
                title?: string | null;
                slug?: string | null;
              } | null;
            } | null;
          } | null;
          items?: Array<{
            __typename?: "ComponentSharedMenuItemPrimitive";
            title?: string | null;
            article?: {
              __typename?: "ArticleEntityResponse";
              data?: {
                __typename?: "ArticleEntity";
                attributes?: {
                  __typename?: "Article";
                  title?: string | null;
                  slug?: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type EcosystemPartnersQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<EcosystemPartnerFiltersInput>;
}>;

export type EcosystemPartnersQuery = {
  __typename?: "Query";
  ecosystemPartners?: {
    __typename?: "EcosystemPartnerEntityResponseCollection";
    data: Array<{
      __typename?: "EcosystemPartnerEntity";
      id?: string | null;
      attributes?: {
        __typename?: "EcosystemPartner";
        description?: string | null;
        name?: string | null;
        url?: string | null;
        priority?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        ecosystem_partner_categories?: {
          __typename?: "EcosystemPartnerCategoryRelationResponseCollection";
          data: Array<{
            __typename?: "EcosystemPartnerCategoryEntity";
            id?: string | null;
            attributes?: {
              __typename?: "EcosystemPartnerCategory";
              Name?: string | null;
              createdAt?: any | null;
              updatedAt?: any | null;
              publishedAt?: any | null;
            } | null;
          }>;
        } | null;
        logo?: {
          __typename?: "UploadFileRelationResponseCollection";
          data: Array<{
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              alternativeText?: string | null;
              caption?: string | null;
              width?: number | null;
              height?: number | null;
              formats?: any | null;
              hash: string;
              ext?: string | null;
              mime: string;
              size: number;
              url: string;
              previewUrl?: string | null;
              provider: string;
              provider_metadata?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
            } | null;
          }>;
        } | null;
        landing_logo?: {
          __typename?: "UploadFileRelationResponseCollection";
          data: Array<{
            __typename?: "UploadFileEntity";
            id?: string | null;
            attributes?: {
              __typename?: "UploadFile";
              name: string;
              alternativeText?: string | null;
              caption?: string | null;
              width?: number | null;
              height?: number | null;
              formats?: any | null;
              hash: string;
              ext?: string | null;
              mime: string;
              size: number;
              url: string;
              previewUrl?: string | null;
              provider: string;
              provider_metadata?: any | null;
              createdAt?: any | null;
              updatedAt?: any | null;
            } | null;
          }>;
        } | null;
      } | null;
    }>;
    meta: {
      __typename?: "ResponseCollectionMeta";
      pagination: {
        __typename?: "Pagination";
        total: number;
        page: number;
        pageSize: number;
        pageCount: number;
      };
    };
  } | null;
};

export type GetTasksQueryVariables = Exact<{
  filters?: InputMaybe<TaskFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}>;

export type GetTasksQuery = {
  __typename?: "Query";
  tasks?: {
    __typename?: "TaskEntityResponseCollection";
    data: Array<{
      __typename?: "TaskEntity";
      id?: string | null;
      attributes?: {
        __typename?: "Task";
        description?: string | null;
        endTimestamp?: any | null;
        maxValue?: number | null;
        rewardContract?: string | null;
        rewardToken?: string | null;
        rewardValue?: string | null;
        startTimestamp: any;
        taskId: string;
        title: string;
      } | null;
    }>;
  } | null;
};

export type GetTradingCompetitionsQueryVariables = Exact<{
  filters?: InputMaybe<TradeCompetitionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}>;

export type GetTradingCompetitionsQuery = {
  __typename?: "Query";
  tradeCompetitions?: {
    __typename?: "TradeCompetitionEntityResponseCollection";
    data: Array<{
      __typename?: "TradeCompetitionEntity";
      id?: string | null;
      attributes?: {
        __typename?: "TradeCompetition";
        name?: string | null;
        description?: string | null;
        endTimestamp?: any | null;
        startTimestamp?: any | null;
        rewardContractAddress?: string | null;
        taskId?: string | null;
        chainId?: number | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        publishedAt?: any | null;
        rewardGroups?: Array<{
          __typename?: "ComponentSharedRewardGroups";
          id: string;
          minPlace?: number | null;
          maxPlace?: number | null;
          rewardToken?: string | null;
          rewardValue?: string | null;
        } | null> | null;
        game?: {
          __typename?: "GameEntityResponse";
          data?: {
            __typename?: "GameEntity";
            attributes?: {
              __typename?: "Game";
              title: string;
              provider?: string | null;
              slug: string;
              icon?: {
                __typename?: "UploadFileEntityResponse";
                data?: {
                  __typename?: "UploadFileEntity";
                  id?: string | null;
                  attributes?: {
                    __typename?: "UploadFile";
                    name: string;
                    width?: number | null;
                    height?: number | null;
                    url: string;
                    previewUrl?: string | null;
                  } | null;
                } | null;
              } | null;
            } | null;
          } | null;
        } | null;
      } | null;
    }>;
    meta: {
      __typename?: "ResponseCollectionMeta";
      pagination: { __typename?: "Pagination"; total: number };
    };
  } | null;
};

export type NftDtoFieldsFragment = {
  __typename?: "NftDto";
  id: string;
  name?: string | null;
  image?: string | null;
  chainId: number;
  contractAddress: string;
  tokenId: string;
  protocol?: string | null;
  owner?: string | null;
  tradeListing?: Array<{
    __typename?: "TradeListingDto";
    id: string;
    published?: boolean | null;
    quantity?: number | null;
    pricePerItem?: string | null;
    pricePerItemUsd?: string | null;
    paymentToken?: string | null;
    contract: string;
    nonce?: string | null;
  }> | null;
  rentListing?: {
    __typename?: "RentListingDto";
    id: string;
    finalDuration?: number | null;
    minDuration?: number | null;
    maxDuration?: number | null;
    owner: string;
    borrower?: string | null;
    paymentToken: string;
    finalPaymentValue?: string | null;
    pricePerSecond?: string | null;
    paymentTokenName?: string | null;
    status?: string | null;
    updatedAt: number;
    nonce: string;
    borrowedAt?: number | null;
  } | null;
};

export type TradeTransactionFragmentFragment = {
  __typename?: "TradeTransaction";
  id: string;
  hash: any;
  nftAddress: any;
  tokenId: any;
  type: TradeTransactionType;
  paymentToken?: any | null;
  pricePerItem?: any | null;
  timestamp: any;
  quantity?: any | null;
  from: any;
  to?: any | null;
  buyer?: any | null;
  seller?: any | null;
};

export type TradeTransactionsQueryVariables = Exact<{
  where?: InputMaybe<TradeTransaction_Filter>;
  orderBy?: InputMaybe<TradeTransaction_OrderBy>;
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;

export type TradeTransactionsQuery = {
  __typename?: "Query";
  tradeTransactions_56: Array<{
    __typename?: "TradeTransaction";
    chainId: number;
    id: string;
    hash: any;
    nftAddress: any;
    tokenId: any;
    type: TradeTransactionType;
    paymentToken?: any | null;
    pricePerItem?: any | null;
    timestamp: any;
    quantity?: any | null;
    from: any;
    to?: any | null;
    buyer?: any | null;
    seller?: any | null;
  }>;
  tradeTransactions_1: Array<{
    __typename?: "TradeTransaction";
    chainId: number;
    id: string;
    hash: any;
    nftAddress: any;
    tokenId: any;
    type: TradeTransactionType;
    paymentToken?: any | null;
    pricePerItem?: any | null;
    timestamp: any;
    quantity?: any | null;
    from: any;
    to?: any | null;
    buyer?: any | null;
    seller?: any | null;
  }>;
};
